import { Progress, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../features/context/AuthContext";
import { useData } from "../../features/context/DataContext";
import { IProfileCalculator, ProfileCalculator } from "../../services/ProfileCalculator";
import classes from "../../styles/global.module.css";


const calculateProfileCompletion = (
    profile: IProfileCalculator | null,
): number => {
    if (!profile?.user) return 0;

    const completionCalculator = new ProfileCalculator(profile);

    return completionCalculator.calculateCompletion();
};

export const ProfileProgressBar = () => {
    const { user } = useAuth();
    const { memberships, company } = useData();
    const progress = calculateProfileCompletion({user, memberships, company});

    return (
        <div className={classes.info}>
            <Text fz="xs" tt="uppercase" fw={700}>
                <FormattedMessage
                    id="app.progression.label"
                    defaultMessage="Progress"
                />
                &ensp;{progress}%
            </Text>
            <Progress value={progress} mt="md" size="lg" radius="xl" />
        </div>
    );
};
