import classes from "./ProductCard.module.css";
import globalClasses from "../../../styles/global.module.css";
import DOMPurify from "dompurify";
import { defaultConfig as purifyConfig } from "../../../config/dompurify";
import { IconShoppingCart } from "@tabler/icons-react";
import { Card, Image, Text, Group, Button, Indicator } from "@mantine/core";
import { Product } from "../../../models/Product";
import { FormattedMessage } from "react-intl";
import { useShoppingCart } from "../../../features/context/ShoppingCartContext";
import { useCallback, useEffect, useState } from "react";
import { ProductCartItem } from "../../../models/Order";
import { CurrencyCode } from "../../../lib/Constants";

interface ProductCardProps {
    product: Product;
    viewDetailsCB?: (product: Product) => void;
}

export function ProductCard({ product, viewDetailsCB }: ProductCardProps) {
    const { uuid, imageUrl, name, description, price, currency } = product;
    const { productData, setShoppingCart } = useShoppingCart();
    const productCartItem: ProductCartItem | undefined = productData.find(
        (p) => p.product.uuid === uuid
    );
    const [currentAmount, setCurrentAmount] = useState<number>(
        productCartItem?.amount ?? 0
    );

    useEffect(() => {
        setCurrentAmount(productCartItem?.amount ?? 0);
    }, [productCartItem?.amount]);

    const addToCart = useCallback(() => {
        setShoppingCart((prevData) => {
            const existingIndex = prevData.productData.findIndex(
                (item) => item.product.uuid === uuid
            );
            const updatedProductData = [...prevData.productData];

            if (existingIndex > -1) {
                const updatedItem = {
                    ...updatedProductData[existingIndex],
                    amount: updatedProductData[existingIndex].amount + 1,
                };
                updatedProductData[existingIndex] = updatedItem;
            } else {
                updatedProductData.push({
                    product,
                    amount: 1,
                } as ProductCartItem);
            }

            return {
                ...prevData,
                productData: updatedProductData,
            };
        });
        // Update local state to reflect this change
        setCurrentAmount((prevAmount) => prevAmount + 1);
    }, [product, setShoppingCart, uuid]);

    return (
        <Card withBorder className={classes.card}>
            <Card.Section>
                <Image src={imageUrl} alt={name} height={180} />
            </Card.Section>
            <Card.Section className={classes.section} mt="md">
                <Text className={classes.name}>
                    {name}
                </Text>
                <Text
                    className={`${classes.description} ${globalClasses.textSummary}`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            product.description,
                            purifyConfig
                        ),
                    }}
                />
                <Group mt="xs" justify="right">
                    <Text className={classes.price}>
                        {currency} {price}{" "}
                        {currency === CurrencyCode.CHF && ".-"}
                    </Text>
                </Group>
            </Card.Section>
            <Group mt="xs">
                <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                        viewDetailsCB && viewDetailsCB(product);
                    }}
                >
                    <FormattedMessage
                        id="app.button.viewDetails"
                        defaultMessage="View details"
                    />
                </Button>
                <Indicator
                    color="red"
                    inline
                    disabled={currentAmount === 0}
                    label={currentAmount}
                    processing
                    size={16}
                    position="bottom-end"
                >
                    <Button
                        rightSection={<IconShoppingCart size={16} />}
                        style={{ flex: 1 }}
                        onClick={addToCart}
                    >
                        <FormattedMessage
                            id="app.order.button.addToCart"
                            defaultMessage="Add to cart"
                        />
                    </Button>
                </Indicator>
            </Group>
        </Card>
    );
}
