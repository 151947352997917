import classes from "./cell.module.css";
import globalClasses from "../../styles/global.module.css";
import { Card, Stack, Title } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { StatsRing } from "../common/Stats/StatsRings";
import { IStatData } from "../common/Stats/Interfaces";
import { LabStat, TeamStat } from "../../models/Stats";
import { calculatePart } from "../../lib/Utilities";
import { useRest } from "../../features/context/RestContext";
import { BaseError } from "../../lib/Error";
import { BarLoader } from "../common/Loader";
import { useUserFeatureAccessHandler } from "../../features/hooks/useUserFeatureAccessHandler";
import { LicenceRoleEnum } from "../../models/Licence";

const TeamStatCell = () => {
    const intl = useIntl();
    const { restApiService } = useRest();
    const { isRole } = useUserFeatureAccessHandler();
    const [teamStats, setTeamStats] = useState<TeamStat | undefined>(undefined);
    const [labStats, setLabStats] = useState<LabStat | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const getStats = async () => {
        try {
            setLoading(true);
            const data: TeamStat | undefined =
                await restApiService?.getTeamStats();
            setTeamStats(data);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setLoading(false);
        }
    };

    const getLabStats = async () => {
        try {
            setLoading(true);
            const data: LabStat | undefined =
                await restApiService?.getLabStats();
            setLabStats(data);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setLoading(false);
        }
    };

    const processedLabStats: IStatData[] = [
        {
            label: intl.formatMessage({
                id: "app.virtualLab.members.totalTitle",
            }),
            count: labStats?.totalMembers ?? 0,
            color: "blue",
        },
        {
            label: intl.formatMessage({
                id: "app.virtualLab.memberships.active",
            }),
            count: labStats?.totalMembers ?? 0,
            part: calculatePart(
                labStats?.activeMembers ?? 0,
                labStats?.totalMembers ?? 1
            ),
            color: "blue",
        },
        {
            label: intl.formatMessage({
                id: "app.virtualLab.memberships.inactive",
            }),
            count: labStats?.totalMembers ?? 0,
            part: calculatePart(
                labStats?.inactiveMembers ?? 0,
                labStats?.totalMembers ?? 1
            ),
            color: "blue",
        },
    ];

    const processedTeamsStats: IStatData[] = [
        {
            label: intl.formatMessage({ id: "app.virtualLabs.created" }),
            count: teamStats?.created ?? 0,
            color: "blue",
        },
        {
            label: intl.formatMessage({ id: "app.virtualLabs.owned" }),
            count: teamStats?.owned ?? 0,
            color: "blue",
        },
    ];

    const processedTeamMembershipsStats: IStatData[] = [
        {
            label: intl.formatMessage({ id: "app.virtualLab.memberships" }),
            count: teamStats?.totalMemberships ?? 0,
            part: calculatePart(
                teamStats?.totalMemberships ?? 0,
                teamStats?.totalMemberships ?? 1
            ),
            color: "blue",
        },
        {
            label: intl.formatMessage({
                id: "app.virtualLab.memberships.active",
            }),
            count: teamStats?.activeMemberships ?? 0,
            part: calculatePart(
                teamStats?.activeMemberships ?? 0,
                teamStats?.totalMemberships ?? 1
            ),
            color: "teal",
        },
        {
            label: intl.formatMessage({
                id: "app.virtualLab.memberships.inactive",
            }),
            count: teamStats?.inactiveMemberships ?? 0,
            part: calculatePart(
                teamStats?.inactiveMemberships ?? 0,
                teamStats?.totalMemberships ?? 1
            ),
            color: "red",
        },
    ];

    useEffect(() => {
        isRole(LicenceRoleEnum.OWNER_ROLE) ? getStats() : getLabStats();
    }, []);

    return (
        <Card
            className={`${classes.container} ${globalClasses.card}`}
        >
            <Card.Section className={classes.section}>
                <Title className={classes.title}>
                    <FormattedMessage
                        id={
                            isRole(LicenceRoleEnum.OWNER_ROLE)
                                ? "app.virtualLabs"
                                : "app.virtualLabs.myVirtualLab"
                        }
                        defaultMessage="Labs"
                    />
                </Title>
            </Card.Section>
            <Card.Section className={classes.section}>
                <Stack>
                    <BarLoader visible={loading} />
                    {!loading && (
                        <>
                            {isRole(LicenceRoleEnum.OWNER_ROLE) ? (
                                <>
                                    <StatsRing data={processedTeamsStats} />
                                    <StatsRing
                                        data={processedTeamMembershipsStats}
                                    />
                                </>
                            ) : (
                                <>
                                    <Title>{labStats?.name}</Title>
                                    <StatsRing data={processedLabStats} />
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </Card.Section>
        </Card>
    );
};

export default TeamStatCell;
