import { Grid, Title, Text, Box } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "../../../features/context/AuthContext";
import { UserAvatar } from "../../common/User";
import classes from "../../../styles/global.module.css";

export default function UserCredentialsGrid() {
    const { user } = useAuth();
    const intl = useIntl();

    const userRoles = user?.rolesLabelized
        .map((role) =>
            intl.formatMessage({
                id: role,
            })
        )
        .join(", ");

    if (user) {
        return (
            <Grid className={classes.info}>
                <Grid.Col span={{ sm: 12, md: 3 }}>
                    <Box
                        style={{
                            textAlign: "center",
                            margin: "auto",
                            width: "fit-content",
                        }}
                    >
                        <UserAvatar
                            user={user}
                            avatarProps={{
                                size: 200,
                                color: "var(--mantine-color-dark-9)"
                            }}
                        />
                        <Text>{user.email}</Text>
                    </Box>
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 9 }}>
                    <Grid grow>
                        <Grid.Col span={12}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.nickname"
                                    defaultMessage="Nickname"
                                />
                            </Title>
                            <Text>
                                {user.nickname ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.email"
                                    defaultMessage="Email"
                                />
                            </Title>
                            <Text>
                                {user.email ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.role"
                                    defaultMessage="Role"
                                />
                            </Title>
                            <Text>
                                {userRoles ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            </Grid>
        );
    }
    return <></>;
}
