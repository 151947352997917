import { createContext, useContext, useMemo, useState } from "react";
import { Team, TeamMember } from "../../models/Team";

interface ITeam {
    currentTeam: Team | undefined;
    teamCtxRefreshTS?: number;
    currentMembersList: TeamMember[] | undefined;
    membersCtxRefreshTS?: number;
}

interface ITeamProps extends ITeam {
    setTeamData: React.Dispatch<React.SetStateAction<ITeam>>;
}

export const TeamContext = createContext<ITeamProps>({
    currentTeam: undefined,
    currentMembersList: [],
    teamCtxRefreshTS: 0,
    membersCtxRefreshTS: 0,
    setTeamData: () => {},
});

export const useTeamData = () => {
    const data = useContext(TeamContext);

    if (!data) {
        throw new Error("useTeamData must be used within a TeamProvider");
    }

    return data;
}

const defaultData: ITeam = { currentTeam: undefined, currentMembersList: [], teamCtxRefreshTS: 0, membersCtxRefreshTS: 0};

export const TeamDataProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {

    const [currentTeamData, setCurrentTeamData] = useState<ITeam>(defaultData);

    const contextValue: ITeamProps = useMemo<ITeamProps>(() => ({
        ...currentTeamData,
        setTeamData: setCurrentTeamData,
    }), [currentTeamData]);

    return (
        <TeamContext.Provider value={contextValue}>
            {children}
        </TeamContext.Provider>
    );
}
