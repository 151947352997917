import globalClasses from "../../styles/global.module.css";
import { Box, Space, Title } from "@mantine/core";
import LicenceList from "../../components/Licence/LicenceList";
import { FormattedMessage } from "react-intl";
import { PageContainer } from "../../components/common/Page";
import PricingSection from "../../components/Subscription/SubscriptionPlans";
import { useData } from "../../features/context/DataContext";
import {
    Feature,
    Action,
    useUserFeatureAccessHandler,
} from "../../features/hooks/useUserFeatureAccessHandler";

export default function SubscriptionPage() {
    const { assignedLicence } = useData();
    const { isAccessAllowed } = useUserFeatureAccessHandler();

    return (
        <PageContainer titleId="app.subscription" defaultTitle="Subscription">
            <Box>
                {isAccessAllowed(Feature.Subscription, Action.ViewAdmin) && (
                    <>
                        <Box>
                            <Title className={globalClasses.title} order={2}>
                                <FormattedMessage
                                    id="app.licence.ownedByMe"
                                    defaultMessage="My licences"
                                />
                            </Title>
                            <Space h="xs" />
                            <LicenceList isOwnerList={true} />
                        </Box>
                        <Space h="xl" />
                        <Box>
                            <Title className={globalClasses.title} order={2}>
                                <FormattedMessage
                                    id="app.licence.assignedToMe"
                                    defaultMessage="Licences assigned to me"
                                />
                            </Title>
                            <Space h="xs" />
                            <LicenceList isOwnerList={false} />
                        </Box>
                        <Space h="xl" />
                    </>
                )}
                {/*Allow access to owner for upgrade or no licence assigned user for subscription*/}
                {!assignedLicence ||
                isAccessAllowed(Feature.Subscription, Action.ViewOwner) ? (
                    <Box>
                        <Title className={globalClasses.title} order={2}>
                            <FormattedMessage
                                id="app.subscription.ourPlans"
                                defaultMessage="Our subscription plans"
                            />
                        </Title>
                        <Space h="xs" />
                        <PricingSection />
                    </Box>
                ) : (
                    <></>
                )}
                <Space h="xl" />
            </Box>
        </PageContainer>
    );
}
