import { Badge, Text, Table, Tooltip } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Order } from "../../../models/Order";
import { useNavigate } from "react-router-dom";
import classes from "./Order.module.css";

interface OrdersTableProps {
    orders: Order[];
}

const OrdersTable = ({ orders }: OrdersTableProps) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const rows = orders.map((order, index) => (
        <Tooltip.Floating
            label={intl.formatMessage(
                {
                    id: "app.order.view",
                    defaultMessage: "View order {reference}",
                },
                { reference: order.reference }
            )}
            key={index}
            offset={30}
        >
            <Table.Tr
                className={classes.tableRow}
                onClick={() => navigate(`/order/${order.uuid}`)}
            >
                <Table.Td>
                    <Text>{order.reference}</Text>
                </Table.Td>
                <Table.Td>{order.createdAt?.toLocaleString()}</Table.Td>
                <Table.Td>{order.updatedAt?.toLocaleString()}</Table.Td>
                <Table.Td>
                    <Badge fullWidth color={order.status.color}>
                        <FormattedMessage id={order.status.label} />
                    </Badge>
                </Table.Td>
            </Table.Tr>
        </Tooltip.Floating>
    ));

    return (
        <>
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.reference"
                                    defaultMessage="Reference"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.date.creation"
                                    defaultMessage="Creation date"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.date.update"
                                    defaultMessage="Update date"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.status"
                                    defaultMessage="Status"
                                />
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </>
    );
};

export default OrdersTable;
