import { Box, Container, Space } from "@mantine/core";
import PaginationContainer from "../common/Pagination";
import { useEffect, useRef, useState } from "react";
import { useRest } from "../../features/context/RestContext";
import { Paginated } from "../../models/Common";
import { Licence } from "../../models/Licence";
import LicenceTable from "./LicenceTable";
import { FormSubmitProvider } from "../../features/context/FormSubmitContext";
import { useIntl } from "react-intl";
import { BarLoader } from "../common/Loader";
import globalClasses from "../../styles/global.module.css";
import AddUsersForm from "./Forms/AddUsersForm";
import { useData } from "../../features/context/DataContext";

interface LicenceListProps {
    isOwnerList: boolean;
}

export default function LicenceList({ isOwnerList }: LicenceListProps) {
    const licencesPerPage: number = 6;
    const errorRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const { assignedLicence } = useData();

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [activePage, setPage] = useState<number>(1);
    const { restApiService } = useRest();
    const [responseData, setResponseData] = useState<Paginated<Licence>>();

    const getLicenceList = async () => {

        if (!assignedLicence?.subscription.uuid) return;

        try {
            setLoaderVisible(true);
            const data: Paginated<Licence> = isOwnerList
                ? await (restApiService?.getSubscriptionLicencesList(
                      assignedLicence.subscription.uuid,
                      activePage,
                      licencesPerPage
                  ) ?? new Paginated<Licence>())
                : await (restApiService?.getLicencesList(
                      activePage,
                      licencesPerPage
                  ) ?? new Paginated<Licence>());
            setResponseData(data);
        } catch (error: any) {
            errorRef.current!.innerHTML = intl.formatMessage({
                id: error.message,
                defaultMessage: error.message,
            });
        } finally {
            setLoaderVisible(false);
        }
    };

    useEffect(() => {
        errorRef.current!.innerHTML = "";
        getLicenceList();
    }, [activePage]);

    return (
        <Box className={globalClasses.container}>
            <PaginationContainer
                countLabel={{
                    id: "app.licence.total",
                    defaultMessage: "Total: {count} licence(s)",
                }}
                itemsCount={responseData?.total ?? 0}
                simpleGridProps={{
                    cols: 1,
                    spacing: "lg",
                    verticalSpacing: "lg",
                }}
                paginationProps={{
                    total: responseData?.pages ?? 0,
                    value: activePage,
                    onChange: (value) => setPage(value),
                }}
            >
                <FormSubmitProvider handleFormSubmitted={getLicenceList}>
                    <LicenceTable
                        licences={responseData?.items ?? []}
                        isManagerTable={isOwnerList}
                    />
                    <Space h="xs" />
                    {isOwnerList && <AddUsersForm />}
                    <Container ref={errorRef}></Container>
                    <BarLoader visible={loaderVisible} />
                </FormSubmitProvider>
            </PaginationContainer>
        </Box>
    );
}
