import { Outlet, useNavigate } from "react-router-dom";
import { NavBar } from "./components/Navigation/NavBar";
import { AppShell } from "@mantine/core";
import AppHead from "./components/Header/Header";
import { useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import classes from "./styles/navigation.module.css";
import useDataUpdateHandler, {
    CLEAR_DATA,
} from "./features/hooks/useDataUpdateHandler";
import IndexedDService from "./services/IndexedDB";
import { AppDataProvider } from "./features/context/AppDataContext";
import { ConfirmModal } from "./components/common/Modal";
import { useIntl } from "react-intl";
import { useRest } from "./features/context/RestContext";
import { useAuth } from "./features/context/AuthContext";
import { TimerBox } from "./components/common/TimerBox";

function App() {
    const [opened, { toggle }] = useDisclosure();
    const [confirmSessionModalOpened, confirmSessionHandlers] =
        useDisclosure(false);
    const { restApiService } = useRest();
    const { stopPing, setCurrentAuth } = useAuth();
    const navigate = useNavigate();
    const clearData = useDataUpdateHandler(CLEAR_DATA);
    const dbUtil: IndexedDService = new IndexedDService();
    const intl = useIntl();

    const clearAppData = async () => {
        stopPing();
        setCurrentAuth({ user: null });
        await clearData();
        await dbUtil.clear();
        navigate("/login");
    };

    const closeSessionModal = async () => {
        clearAppData();
        confirmSessionHandlers.close();
    };

    const continueSession = async () => {
        try {
            await restApiService?.refreshToken();
        } catch (error) {
            clearAppData();
        } finally {
            confirmSessionHandlers.close();
        }
    };

    useEffect(() => {
        const handleSessionExpired = async () => {
            // Redirect to login page or show a login modal
            confirmSessionHandlers.open();
        };

        window.addEventListener("sessionExpired", handleSessionExpired);
        return () => {
            window.removeEventListener("sessionExpired", handleSessionExpired);
        };
    }, [clearData, dbUtil, navigate]);

    return (
        <>
            <ConfirmModal
                title={intl.formatMessage({
                    id: "app.session.expired",
                    defaultMessage: "Session expired",
                })}
                message={intl.formatMessage({
                    id: "app.session.willQuitSoon",
                    defaultMessage:
                        "You will be automatically disconnected in a few seconds.",
                })}
                opened={confirmSessionModalOpened}
                close={closeSessionModal}
                launchAction={continueSession}
                redBtnLabel="app.session.leave"
                greenBtnLabel="app.session.stayConnected"
                customContent={
                    <TimerBox
                        time={20}
                        onTimeout={clearAppData}
                        message={intl.formatMessage({
                            id: "app.session.disconnecting",
                            defaultMessage: "Disconnecting...",
                        })}
                    />
                }
            />
            <AppShell
                className={classes.appShell}
                withBorder={false}
                navbar={{
                    width: 250,
                    breakpoint: "sm",
                    collapsed: { mobile: !opened },
                }}
                header={{ height: 60 }}
                padding="md"
            >
                <AppShell.Header>
                    <AppHead opened={opened} onClick={toggle} />
                </AppShell.Header>
                <AppShell.Navbar withBorder={true}>
                    <NavBar />
                </AppShell.Navbar>
                <AppShell.Main>
                    <AppDataProvider>
                        <Outlet />
                    </AppDataProvider>
                </AppShell.Main>
            </AppShell>
        </>
    );
}

export default App;
