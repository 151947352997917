import {
    Container,
    Grid,
    Card,
    Text,
    Badge,
    Image,
    Group,
    Table,
    Space,
    Title,
    Paper,
} from "@mantine/core";
import { Order } from "../../../models/Order";
import { FormattedMessage } from "react-intl";
import classes from "./Order.module.css";

interface OrderDetailsProps {
    order: Order;
}

const OrderDetails = ({ order }: OrderDetailsProps) => {

    return (
        <Container>
            <Paper shadow="md" radius="lg" withBorder p="xl">
                <Title order={3}>
                    <FormattedMessage
                        id="app.label.details"
                        defaultMessage="Details"
                    />
                </Title>
                <Table className={classes.table}>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.order.date"
                                    defaultMessage="Order date"
                                />
                            </Table.Td>
                            <Table.Td>
                                {order.createdAt?.toLocaleDateString()}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.label.reference"
                                    defaultMessage="Reference"
                                />
                            </Table.Td>
                            <Table.Td>{order.reference}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.label.status"
                                    defaultMessage="Status"
                                />
                            </Table.Td>
                            <Table.Td>
                                <Badge>
                                    <FormattedMessage id={order.status.label} />
                                </Badge>
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.order.formLabel.paymentMethod"
                                    defaultMessage="Payment method"
                                />
                            </Table.Td>
                            <Table.Td>
                                <Badge color="blue">
                                    <FormattedMessage
                                        id={order.getPaymentMethodLabelized()}
                                    />
                                </Badge>
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.order.formLabel.totalTaxExcluded"
                                    defaultMessage="Total (excl. VAT)"
                                />
                            </Table.Td>
                            <Table.Td>{order.totalPriceNet}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>
                                <FormattedMessage
                                    id="app.order.formLabel.totalTaxIncluded"
                                    defaultMessage="Total (incl. VAT)"
                                />
                            </Table.Td>
                            <Table.Td>{order.totalPriceTaxIncl}</Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
            </Paper>
            <Space h="lg" />
            <Paper shadow="md" radius="lg" withBorder p="xl">
                <Title order={3}>
                    <FormattedMessage
                        id="app.order.myCart"
                        defaultMessage="My cart"
                    />
                </Title>
                <Grid>
                    {order.productOrders.map((productOrder) => (
                        <Grid.Col span={4} key={productOrder.product.uuid}>
                            <Card shadow="sm" padding="lg">
                                <Card.Section>
                                    <Image
                                        src={productOrder.product.imageUrl}
                                        height={160}
                                        alt={productOrder.product.name}
                                    />
                                </Card.Section>
                                <Group
                                    style={{
                                        marginBottom: 5,
                                        marginTop: 5,
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text fw={500}>
                                        {productOrder.product.name}
                                    </Text>
                                    <Text>{productOrder.amount}x</Text>
                                </Group>
                                <Text>
                                    <FormattedMessage
                                        id="app.product.price"
                                        defaultMessage="Price"
                                    />
                                    : {productOrder.product.price}
                                </Text>
                            </Card>
                        </Grid.Col>
                    ))}
                </Grid>
            </Paper>
        </Container>
    );
};

export default OrderDetails;
