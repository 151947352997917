import { stringToDate } from "../lib/ConvertionUtils";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";
import {
    Expose,
    Transform,
    Type,
    instanceToPlain,
    plainToClass,
} from "class-transformer";
import { Product } from "./Product";
import { Licence, LicenceDto } from "./Licence";

interface IOrderStatus {
    label: OrderStatusLabelEnum;
    color: string;
}

enum OrderStatusLabelEnum {
    NEW = "app.order.status.new",
    NEED_SUPPORT = "app.order.status.needSupport",
    CANCELLED = "app.order.status.cancelled",
    REFUNDED = "app.order.status.refunded",
    SHIPPED = "app.order.status.shipped",
    DELIVERED = "app.order.status.delivered",
    RETURNED = "app.order.status.returned",
    COMPLETED = "app.order.status.completed",
}

const STATUS_MAP = new Map<string, IOrderStatus>([
    ["NEW", { label: OrderStatusLabelEnum.NEW, color: "blue" }],
    ["NEED_SUPPORT", { label: OrderStatusLabelEnum.NEED_SUPPORT, color: "orange" }],
    ["CANCELLED", { label: OrderStatusLabelEnum.CANCELLED, color: "red" }],
    ["REFUNDED", { label: OrderStatusLabelEnum.REFUNDED, color: "orange" }],
    ["SHIPPED", { label: OrderStatusLabelEnum.SHIPPED, color: "light-blue" }],
    ["DELIVERED", { label: OrderStatusLabelEnum.DELIVERED, color: "light-green" }],
    ["RETURNED", { label: OrderStatusLabelEnum.RETURNED, color: "orange" }],
    ["COMPLETED", { label: OrderStatusLabelEnum.COMPLETED, color: "green" }],
]);

// Available payment methods (comments are for future use)
export enum PaymentMethodLabelEnum {
    CRED_CARD = "app.order.paymentMethod.creditCard",
    INVOICE = "app.order.paymentMethod.invoice",
    // TRANSFER = "app.order.paymentMethod.bankTransfer",
    // PAYPAL = "app.order.paymentMethod.paypal",
    // BITCOIN = "app.order.paymentMethod.bitcoin",
    // OTHER = "app.label.other",
}

export enum PaymentMethodEnum {
    CRED_CARD = "CRED_CARD",
    INVOICE = "INVOICE",
}

export const PAYMENT_METHOD_MAP = new Map<string, string>([
    [PaymentMethodEnum.CRED_CARD, PaymentMethodLabelEnum.CRED_CARD],
    [PaymentMethodEnum.INVOICE, PaymentMethodLabelEnum.INVOICE],
    // ["TRANSFER", PaymentMethodLabelEnum.TRANSFER],
    // ["PAYPAL", PaymentMethodLabelEnum.PAYPAL],
    // ["BITCOIN", PaymentMethodLabelEnum.BITCOIN],
    // ["OTHER", PaymentMethodLabelEnum.OTHER],
]);

@staticImplements<DtoStatic>()
export class ProductOrderDto {
    @Expose()
    @Type(() => Product)
    product!: Product;
    @Expose()
    amount!: number;

    static toModel(productOrderDto: ProductOrderDto): ProductOrder {
        if (productOrderDto === undefined) {
            return new ProductOrder();
        }
        const data = instanceToPlain(productOrderDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(ProductOrder, data);
    }
}

export class ProductOrder implements Model {
    @Expose()
    @Type(() => Product)
    product!: Product;
    @Expose()
    amount!: number;
}   

@staticImplements<DtoStatic>()
export class OrderDto {
    @Expose()
    uuid!: string;
    @Expose()
    reference!: string;
    @Expose()
    status!: string;
    @Expose()
    comment!: string;
    @Expose()
    totalPriceNet!: string;
    @Expose()
    totalPriceTaxIncl!: string;
    @Expose()
    currency!: string;
    @Expose()
    paymentMethod!: string;
    @Expose()
    cartItems!: CartItemDto[];
    @Expose()
    @Type(() => ProductOrderDto)
    productOrders!: ProductOrderDto[];
    @Expose()
    @Type(() => LicenceDto)
    vtAppLicences!: LicenceDto[];
    @Expose()
    createdAt!: string;
    @Expose()
    updatedAt!: string;

    static toModel(orderDto: OrderDto): Order {
        if (orderDto === undefined) {
            return new Order();
        }
        const data = instanceToPlain(orderDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Order, data);
    }
}

export class Order implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    reference!: string;
    @Expose()
    @Transform((data) => STATUS_MAP.get(data.value) || data.value)
    status!: IOrderStatus;
    @Expose()
    comment!: string;
    @Expose()
    totalPriceNet!: string;
    @Expose()
    totalPriceTaxIncl!: string;
    @Expose()
    currency!: string;
    @Expose()
    paymentMethod!: string;
    @Expose({ name: "paymentMethodLabelized" })
    getPaymentMethodLabelized(): string {
        return PAYMENT_METHOD_MAP.get(this.paymentMethod) || this.paymentMethod;
    }
    @Expose()
    @Type(() => ProductOrder)
    productOrders!: ProductOrder[];
    @Expose()
    @Type(() => Licence)
    vtAppLicences!: Licence[];
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    createdAt!: Date | null;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    updatedAt!: Date | null;
}

class CartItemBase implements Model {
    @Expose()
    amount!: number;
}

/**
 * Object to be sent to the server
 */
export class CartItem extends CartItemBase {
    @Expose()
    reference!: number;
    @Expose()
    internalReference!: string;
}

@staticImplements<DtoStatic>()
export class CartItemDto {
    @Expose()
    amount!: number;
    @Expose()
    reference!: number;
    @Expose()
    internalReference!: string;

    static toModel(cartItemDto: CartItemDto): CartItem {
        const data = instanceToPlain(cartItemDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(CartItem, data);
    }
}

/**
 * Shopping cart input object
 */
export class ProductCartItem extends CartItemBase {
    @Expose()
    neededBoxAmount!: number;
    @Expose()
    @Type(() => Product)
    product!: Product;
}

export class OrderCreationDto {
    @Expose()
    @Type(() => OrderDto)
    order!: OrderDto;
    @Expose()
    data!: any;
}