import { FormattedMessage } from "react-intl";
import { Button, Group } from "@mantine/core";
import {
    IconCrown,
    IconCrownOff,
    IconPlayerPause,
    IconPlayerPauseFilled,
    IconUserX,
} from "@tabler/icons-react";
import {
    Licence,
    LicenceRoleEnum,
    LicenceStatusEnum,
} from "../../models/Licence";
import { User } from "../../models/User";
import { useAuth } from "../../features/context/AuthContext";
import { useUserFeatureAccessHandler } from "../../features/hooks/useUserFeatureAccessHandler";

interface LicenceActionCBProps {
    pauseActionCB: (licence: Licence) => void;
    updateRoleActionCB: (licence: Licence, role: LicenceRoleEnum) => void;
    resumeActionCB: (licence: Licence) => void;
    deleteActionCB: (licence: Licence) => void;
}

interface LicenceActionsProps extends LicenceActionCBProps {
    licence: Licence;
    disableButtons?: boolean;
}

const getLicenceActionButton = (
    licence: Licence,
    disableButtons: boolean | undefined,
    props: LicenceActionCBProps
): JSX.Element => {
    const licenceStatus: string | null = licence.status?.value ?? null;
    const assignee: User | null = licence.assignee ?? null;
    const { user } = useAuth();
    const { isRole } = useUserFeatureAccessHandler();
    if (user?.uuid === assignee?.uuid) return <></>;

    if (licenceStatus === LicenceStatusEnum.ACTIVE) {
        return (
            <>
                <Button
                    disabled={disableButtons}
                    size="compact-xs"
                    color="black"
                    onClick={() => props.pauseActionCB(licence)}
                    rightSection={<IconPlayerPauseFilled size={14} />}
                >
                    <FormattedMessage
                        id="app.label.pause"
                        defaultMessage="Pause"
                    />
                </Button>
                {isRole(LicenceRoleEnum.OWNER_ROLE) && (
                    <>
                        {licence.roles.some(
                            (role) => role.value === LicenceRoleEnum.ADMIN_ROLE
                        ) ? (
                            <Button
                                disabled={disableButtons}
                                size="compact-xs"
                                color="black"
                                onClick={() =>
                                    props.updateRoleActionCB(
                                        licence,
                                        LicenceRoleEnum.ASSIGNEE_ROLE
                                    )
                                }
                                rightSection={<IconCrownOff size={14} />}
                            >
                                <FormattedMessage
                                    id="app.licence.downgrade"
                                    defaultMessage="Downgrade"
                                />
                            </Button>
                        ) : (
                            <Button
                                disabled={disableButtons}
                                size="compact-xs"
                                color="blue"
                                onClick={() =>
                                    props.updateRoleActionCB(
                                        licence,
                                        LicenceRoleEnum.ADMIN_ROLE
                                    )
                                }
                                rightSection={<IconCrown size={14} />}
                            >
                                <FormattedMessage
                                    id="app.licence.upgrade"
                                    defaultMessage="Make admin"
                                />
                            </Button>
                        )}
                    </>
                )}
            </>
        );
    }

    if (licenceStatus === LicenceStatusEnum.PAUSED && assignee) {
        return (
            <Button
                disabled={disableButtons}
                size="compact-xs"
                color="green"
                onClick={() => props.resumeActionCB(licence)}
                rightSection={<IconPlayerPause size={14} />}
            >
                <FormattedMessage
                    id="app.label.resume"
                    defaultMessage="Resume"
                />
            </Button>
        );
    }

    return <></>;
};

const getUserActionButton = (
    licence: Licence,
    disableButtons: boolean | undefined,
    props: LicenceActionCBProps
): JSX.Element => {
    const licenceStatus: string | null = licence.status?.value ?? null;
    const assignee: User | null = licence.assignee ?? null;
    const { user } = useAuth();

    if (user?.uuid === assignee?.uuid) return <></>;

    if (
        licenceStatus === LicenceStatusEnum.ACTIVE ||
        licenceStatus === LicenceStatusEnum.PAUSED
    ) {
        return (
            <Button
                disabled={disableButtons}
                size="compact-xs"
                color="red"
                onClick={() => props.deleteActionCB(licence)}
                rightSection={<IconUserX size={14} />}
            >
                <FormattedMessage
                    id="app.action.delete"
                    defaultMessage="Delete"
                />
            </Button>
        );
    }

    return <></>;
};

export default function LicenceActions({
    licence,
    disableButtons,
    ...licenceActionCBProps
}: LicenceActionsProps) {
    let licenceActionButton = getLicenceActionButton(
        licence,
        disableButtons,
        licenceActionCBProps
    );
    let userActionButton = getUserActionButton(
        licence,
        disableButtons,
        licenceActionCBProps
    );

    return (
        <Group gap="xs">
            {licenceActionButton}
            {userActionButton}
        </Group>
    );
}
