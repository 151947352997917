import { FormattedMessage } from "react-intl";
import { companyGridprops } from "../Interfaces";
import { Grid, Title, Text } from "@mantine/core";
import classes from "../../../styles/global.module.css";

export default function CompanyReadOnlyGrid({ company }: companyGridprops) {

    return (
        <>
            <Grid grow className={classes.info}>
                <Grid.Col span={12}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title order={3}>
                                <FormattedMessage
                                    id="app.company"
                                    defaultMessage="Company"
                                />
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.formLabel.name"
                                    defaultMessage="Name"
                                />
                            </Title>
                            <Text>{company?.name}</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.company.identificationNumber"
                                    defaultMessage="Company identification number"
                                />
                            </Title>
                            <Text>{company?.identificationNumber}</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.formLabel.phone"
                                    defaultMessage="Phone"
                                />
                            </Title>
                            <Text>{company?.phone}</Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.country"
                                    defaultMessage="Country"
                                />
                            </Title>
                            <Text>{company?.countryName}</Text>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            </Grid>
        </>
    );
}
