import { createContext, useContext, useEffect, useState } from "react";
import { Order, ProductCartItem } from "../../models/Order";
import { CountryCode, DeliveryReference } from "../../lib/Constants";

interface ShoppingCartProviderProps {
    children: React.ReactNode;
    userCountryISO?: string; // Optional prop for custom delivery reference
}

interface IShoppingCart {
    stripeCliSecret: string;
    createdOrder: Order | null;
    userCountryISO: string;
    productData: ProductCartItem[];
    productFeeData?: ProductCartItem[];
    appliedFeeData?: ProductCartItem;
}

interface IShoppingCartProps extends IShoppingCart {
    setShoppingCart: React.Dispatch<React.SetStateAction<IShoppingCart>>;
}

export const ShoppingCartContext = createContext<IShoppingCartProps>({
    stripeCliSecret: "",
    createdOrder: null,
    userCountryISO: "",
    productData: [],
    productFeeData: [],
    appliedFeeData: undefined,
    setShoppingCart: () => {},
});

export const useShoppingCart = () => {
    const cart = useContext(ShoppingCartContext);

    if (!cart) {
        throw new Error(
            "useShoppingCart must be used within a ShoppingCartProvider"
        );
    }

    return cart;
};

const shoppingCartDefaultContextValue: IShoppingCart = {
    stripeCliSecret: "",
    createdOrder: null,
    userCountryISO: "",
    productData: [],
};

export const ShoppingCartProvider: React.FC<ShoppingCartProviderProps> = ({
    children,
    userCountryISO = CountryCode.CH,
}) => {
    const [shoppingCart, setShoppingCart] = useState<IShoppingCart>(
        shoppingCartDefaultContextValue
    );

    const contextValue: IShoppingCartProps = {
        ...shoppingCart,
        setShoppingCart: setShoppingCart,
    };

    // This effect updates appliedFeeData whenever productFeeData changes
    useEffect(() => {
        if (shoppingCart.productFeeData) {
            const newAppliedFeeData = shoppingCart.productFeeData.find(
                (item) => item.product.internalReference === _getDeliveryRef(userCountryISO)
            );
            if (newAppliedFeeData) {
                setShoppingCart((prev) => ({
                    ...prev,
                    userCountryISO: userCountryISO,
                    appliedFeeData: newAppliedFeeData,
                }));
            }
        }
    }, [shoppingCart.productFeeData]);

    return (
        <ShoppingCartContext.Provider value={contextValue}>
            {children}
        </ShoppingCartContext.Provider>
    );
};

// TODO: works only for CH, add EU delivery references and abroad
const _getDeliveryRef = (userCountryISO: string): string =>
    userCountryISO === CountryCode.CH
        ? DeliveryReference.DELIV_CH
        : DeliveryReference.DELIV_EU;
