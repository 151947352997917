import classes from "./Logout.module.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../features/context/AuthContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import { useEffect, useRef, useState } from "react";
import IndexedDService from "../../services/IndexedDB";
import { Card, Text, Overlay, Button, Group, Stack } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { OvalLoader } from "../../components/common/Loader";
import useDataUpdateHandler, {
    CLEAR_DATA,
} from "../../features/hooks/useDataUpdateHandler";

function Logout() {
    const handleError = useErrorAlertHandler();
    const { authService } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [closingDb, setClosingDb] = useState<boolean>(false);
    const [sendingLogoutRequest, setSendingLogoutRequest] =
        useState<boolean>(false);
    const clearData = useDataUpdateHandler(CLEAR_DATA);
    const loginButtonRef = useRef<HTMLAnchorElement>(null);
    const [countdown, setCountdown] = useState(5);

    const dbUtil: IndexedDService = new IndexedDService();

    const quit = async () => {
        try {
            setClosingDb(true);
            await clearData();
        } catch (error: any) {
            console.error(error);
        } finally {
            try {
                setClosingDb(false);
                logout();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const logout = async () => {
        setSendingLogoutRequest(true);
        try {
            await authService?.logout();
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            await dbUtil.clear();
            setSendingLogoutRequest(false);
        }
    };

    useEffect(() => {
        quit();
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    loginButtonRef.current?.click();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, []);

    return (
        <div className={classes.container}>
            <Card radius="md" className={classes.card}>
                <Overlay
                    className={classes.overlay}
                    opacity={0.55}
                    zIndex={0}
                />
                <div className={classes.content}>
                    <Group>
                        <Stack>
                            <Text size="lg" fw={700} className={classes.title}>
                                <FormattedMessage
                                    id="app.logout"
                                    defaultMessage="Log out"
                                />
                            </Text>
                            <Text size="sm" className={classes.description}>
                                {closingDb && (
                                    <FormattedMessage
                                        id="app.logout.closingSession"
                                        defaultMessage="Closing session..."
                                    />
                                )}
                                {sendingLogoutRequest && (
                                    <FormattedMessage
                                        id="app.loggingOut"
                                        defaultMessage="Logging out..."
                                    />
                                )}
                            </Text>
                            {!loading && (
                                <Button
                                    ref={loginButtonRef}
                                    component={Link}
                                    to="/login"
                                    className={classes.button}
                                    color="blue"
                                >
                                    <FormattedMessage
                                        id="app.login.toLoginPage"
                                        defaultMessage="Go to login page"
                                    />{" "}
                                    {countdown}
                                </Button>
                            )}
                        </Stack>
                        <OvalLoader visible={loading} />
                    </Group>
                </div>
            </Card>
        </div>
    );
}

export default Logout;
