import React, { createContext, ReactNode } from "react";

interface FormSubmitProviderProps {
    children: ReactNode;
    handleFormSubmitted: () => void;
}

export const FormSubmitContext = createContext({
    handleFormSubmitted: () => {},
});

export const FormSubmitProvider: React.FC<FormSubmitProviderProps> = ({
    children,
    handleFormSubmitted: handleFormSubmitted,
}) => {
    return (
        <FormSubmitContext.Provider value={{ handleFormSubmitted }}>
            {children}
        </FormSubmitContext.Provider>
    );
};
