import { useNavigate, useParams } from "react-router-dom";
import { SuccessModal } from "../../components/common/Modal";
import {
    Button,
    Container,
    LoadingOverlay,
    Paper,
    PasswordInput,
    Stack,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classes from "./Password.module.css";
import PasswordStrengthInput from "../../components/common/Inputs/PasswordStrengh";
import { useAuth } from "../../features/context/AuthContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";


function PasswordReset() {
    const { token } = useParams();
    const handleError = useErrorAlertHandler();

    if (!token) {
        return null;
    }

    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            password: "",
            passwordConfirm: "",
        },
        validate: {
            passwordConfirm: (value, values) =>
            value !== values.password
                ? intl.formatMessage({ id: "app.formLabel.password.error" })
                : null,
        }
    });

    const intl = useIntl();
    const { authService } = useAuth();
    const [successOpened, successHandler] = useDisclosure(false);
    const navigate = useNavigate();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const handleSubmit = async () => {
        const { password, passwordConfirm } = form.values;
        const payload = {
            password: password.trim(),
            passwordConfirm: passwordConfirm.trim(),
        };
        try {
            setLoaderVisible(true);
            await authService?.resetPassword(token, payload);
            successHandler.open();
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    return (
        <>
            <SuccessModal
                opened={successOpened}
                close={() => {
                    navigate("/login");
                }}
                boxProps={{
                    messageIntl: "app.password.recoverSuccess",
                }}
            />
            <div className={classes.wrapper}>
                <LoadingOverlay
                    visible={loaderVisible}
                    overlayProps={{ blur: 2 }}
                />
                <Container size={520}>
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Title
                            style={{
                                fontWeight: 900,
                                paddingTop: 50,
                                textAlign: "center",
                            }}
                        >
                            <FormattedMessage
                                id="app.password.forgot"
                                defaultMessage="Forgot password"
                            />
                        </Title>
                        <Paper
                            withBorder
                            shadow="md"
                            p={30}
                            mt={30}
                            radius="md"
                        >
                            <Stack>
                                <PasswordStrengthInput
                                    {...form.getInputProps("password")}
                                />
                                <PasswordInput
                                    label={intl.formatMessage({
                                        id: "app.formLabel.password.confirm",
                                    })}
                                    {...form.getInputProps("passwordConfirm")}
                                />
                            </Stack>
                            <Button type="submit" fullWidth mt="xl">
                                <FormattedMessage
                                    id="app.label.send"
                                    defaultMessage="Send"
                                />
                            </Button>
                        </Paper>
                    </form>
                </Container>
            </div>
        </>
    );
}

export default PasswordReset;
