import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Box,
    Button,
    LoadingOverlay,
    Paper,
    Space,
    Stack,
    Title,
} from "@mantine/core";
import { useRest } from "../../features/context/RestContext";
import { FormattedMessage, useIntl } from "react-intl";
import { Membership, Team } from "../../models/Team";
import { MembersList } from "../../components/VirtualLab/Member/List";
import { ActionsWrapper, formularRef } from "../../components/common/Wrapper";
import VirtualLabReadOnlyGrid from "../../components/VirtualLab/Details/VirtualLabGrid";
import VirtualLabEditGrid from "../../components/VirtualLab/Forms/VirtualLabForm";
import { useDisclosure } from "@mantine/hooks";
import { ConfirmModal } from "../../components/common/Modal";
import { useData } from "../../features/context/DataContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import classes from "./VirtualLab.module.css";
import globalClasses from "../../styles/global.module.css";
import {
    Action,
    Feature,
    useUserFeatureAccessHandler,
} from "../../features/hooks/useUserFeatureAccessHandler";
import { useLabCountHandler } from "../../features/hooks/useLabCountHandler";
import { AddMemberForm } from "../../components/VirtualLab/Forms/VirtualLabMember";
import { useTeamData } from "../../features/context/TeamContext";

function VirtualLabPage() {
    const { restApiService } = useRest();
    const formRef = useRef<formularRef>(null);
    const handleError = useErrorAlertHandler();
    const { assignedLicence, memberships } = useData();
    const { isAccessAllowed } = useUserFeatureAccessHandler();
    const handleLabCount = useLabCountHandler();
    const { currentTeam, teamCtxRefreshTS, setTeamData } = useTeamData();
    const [membership, setMembership] = useState<Membership | undefined>();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const intl = useIntl();
    const { teamUuid } = useParams();
    const navigate = useNavigate();
    const [opened, { toggle, close }] = useDisclosure(false);

    const handleSubmit = () => {
        formRef.current?.handleSubmit();
    };

    const getVirtualLab = async () => {
        if (teamUuid) {
            try {
                setLoaderVisible(true);
                const data =
                    (await restApiService?.getTeam(teamUuid)) ?? new Team();
                setTeamData((prev) => ({ ...prev, currentTeam: data }));
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    const deleteVirtualLab = async () => {
        if (teamUuid) {
            try {
                setLoaderVisible(true);
                await restApiService?.deleteTeam(teamUuid);
                if (assignedLicence) {
                    handleLabCount(-1);
                }
                navigate("/team");
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                close();
                setLoaderVisible(false);
            }
        }
    };

    useEffect(() => {
        if (!currentTeam) {
            getVirtualLab();
        }
        setMembership(memberships?.get(teamUuid ?? ""));
    }, [teamUuid, teamCtxRefreshTS]);

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <ConfirmModal
                title={intl.formatMessage({
                    id: "app.action.confirmDelete",
                    defaultMessage: "Confirm deletion",
                })}
                message={intl.formatMessage(
                    {
                        id: "app.virtualLab.delete.confirm",
                        defaultMessage:
                            "Are you sure you want to delete the lab {labName}?",
                    },
                    { labName: currentTeam?.name }
                )}
                opened={opened}
                close={close}
                launchAction={deleteVirtualLab}
            />
            <Paper
                className={classes.container}
                withBorder
                radius="md"
                p="xl"
                key={currentTeam?.uuid}
            >
                {isAccessAllowed(Feature.VirtualLab) ? (
                    <ActionsWrapper
                        formular={
                            <VirtualLabEditGrid
                                ref={formRef}
                                editMode={true}
                                setLoaderVisibleCB={setLoaderVisible}
                            />
                        }
                        container={<VirtualLabReadOnlyGrid />}
                        handleFormSubmit={
                            isAccessAllowed(Feature.VirtualLab, Action.Edit)
                                ? handleSubmit
                                : undefined
                        }
                        handleDelete={
                            isAccessAllowed(Feature.VirtualLab, Action.Delete)
                                ? toggle
                                : undefined
                        }
                    />
                ) : (
                    <VirtualLabReadOnlyGrid />
                )}
            </Paper>
            <Space h="xl" />
            <Stack>
                <Box>
                    <Title className={globalClasses.title} order={2}>
                        <FormattedMessage
                            id="app.virtualLab.members.title"
                            defaultMessage="Our lab members"
                            values={{ count: currentTeam?.membersCount }}
                        />
                    </Title>
                    <Space h="md" />

                    <Box className={globalClasses.container}>
                        <MembersList />
                    </Box>
                    <Space h="md" />
                    {isAccessAllowed(Feature.VirtualLab, Action.Edit) && (
                        <Box className={globalClasses.container}>
                            <AddMemberForm />
                        </Box>
                    )}
                </Box>
            </Stack>
            <Link to={`/team`} className={globalClasses.link}>
                <Button variant="default" mt="md">
                    <FormattedMessage
                        id="app.button.backToList"
                        defaultMessage="Back to list"
                    />
                </Button>
            </Link>
        </>
    );
}

export default VirtualLabPage;
