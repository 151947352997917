import { colorsTuple, createTheme, CSSVariablesResolver } from "@mantine/core";

export const theme = createTheme({
    primaryColor: "vigilitech",
    breakpoints: {
        xs: "30em",
        sm: "48em",
        md: "64em",
        lg: "74em",
        xl: "90em",
    },
    spacing: {
        xxs: "0.25rem",
        xs: "0.5rem",
        sm: "0.75rem",
        md: "1rem",
        lg: "1.5rem",
        xl: "2rem",
    },
    colors: {
        vigilitech: [
            "#e5f6ff",
            "#d3e8fd",
            "#a8cdf2",
            "#7bb2e8",
            "#559adf",
            "#3c8bdb",
            "#2c84d9",
            "#1c71c1",
            "#0c65af",
            "#00579b",
        ],
        vigisec: [
            "#f3f5f6",
            "#e7e7e7",
            "#cccccc",
            "#aeb1b3",
            "#95999e",
            "#848a91",
            "#7b838c",
            "#68707a",
            "#5b646d",
            "#4b5762",
        ],
        vigithird: [
            "#f4fbe8",
            "#e9f4d9",
            "#d3e6b4",
            "#bad88c",
            "#a6cc6a",
            "#98c454",
            "#92c048",
            "#7da939",
            "#6e962f",
            "#5c8222",
        ],
    },
    other: {
        headerBackgroundColorLight: "#FFF",
        headerFontColorLight: "#2f2e2e",
        headerBorderBackgroundColorLight: "#2f2e2e",
        headerBackgroundColorDark: "#2f2e2e",
        headerFontColorDark: "#ffffff",
        headerBorderBackgroundColorDark: "#2f2e2e",
    },
    fontFamily: "'Avenir Light', Arial, sans-serif",
    headings: { fontFamily: "'Avenir', Arial,  sans-serif" },
});

export const resolver: CSSVariablesResolver = (theme) => ({
    variables: {},
    light: {
        "--mantine-header-background-color":
            theme.other.headerBackgroundColorLight,
        "--mantine-header-font-color": theme.other.headerFontColorLight,
        "--mantine-header-border-bottom-color":
            theme.other.headerBorderBackgroundColorLight,
    },
    dark: {
        "--mantine-header-background-color":
            theme.other.headerBackgroundColorDark,
        "--mantine-header-font-color": theme.other.headerFontColorDark,
        "--mantine-header-border-bottom-color":
            theme.other.headerBorderBackgroundColorDark,
    },
});
