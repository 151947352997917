import {
    Paper,
    TextInput,
    Button,
    Title,
    Stack,
    Container,
    LoadingOverlay,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useForm, isEmail } from "@mantine/form";
import { useEffect, useState } from "react";
import { useAuth } from "../../features/context/AuthContext";
import { SuccessModal } from "../../components/common/Modal";
import { useDisclosure } from "@mantine/hooks";
import classes from "./Password.module.css";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";

function PasswordForgot() {
    const intl = useIntl();
    const handleError = useErrorAlertHandler();
    const { authService } = useAuth();
    const [successOpened, successHandler] = useDisclosure(false);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            email: "",
        },
        validate: {
            email: isEmail(
                intl.formatMessage({ id: "app.formLabel.email.error" })
            ),
        },
    });

    const handleSubmit = async () => {
        const { email } = form.values;

        try {
            setLoaderVisible(true);
            await authService?.forgotPassword(email.trim());
            successHandler.open();
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    return (
        <>
            <SuccessModal
                opened={successOpened}
                close={() => {
                    navigate("/login");
                }}
                boxProps={{
                    messageIntl: "app.password.recoverEmail.sent",
                    messageInltValues: { email: form.values.email },
                }}
            />
            <div className={classes.wrapper}>
                <LoadingOverlay
                    visible={loaderVisible}
                    overlayProps={{ blur: 2 }}
                />
                <Container size={520}>
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Title
                            style={{
                                fontWeight: 900,
                                paddingTop: 50,
                                textAlign: "center",
                            }}
                        >
                            <FormattedMessage
                                id="app.password.forgot"
                                defaultMessage="Forgot password"
                            />
                        </Title>
                        <Paper
                            withBorder
                            shadow="md"
                            p={30}
                            mt={30}
                            radius="md"
                        >
                            <Stack>
                                <TextInput
                                    label={intl.formatMessage({
                                        id: "app.formLabel.email",
                                    })}
                                    error={intl.formatMessage({
                                        id: "app.formLabel.email.error",
                                    })}
                                    {...form.getInputProps("email")}
                                    placeholder="you@mail.com"
                                />
                            </Stack>
                            <Button type="submit" fullWidth mt="xl">
                                <FormattedMessage
                                    id="app.label.send"
                                    defaultMessage="Send"
                                />
                            </Button>
                        </Paper>
                    </form>
                </Container>
            </div>
        </>
    );
}

export default PasswordForgot;
