import { openDB, deleteDB, IDBPDatabase } from "idb";

export const AUTH_STORE: string = "auth";
export const DATA_STORE: string = "data";
export const APP_DATA_STORE: string = "appData";

const NO_STORE: string = "";

export default class IndexedDService {
    private dbName: string;
    private dbVersion: number;
    private storeName: string;
    private stores: string[] = [AUTH_STORE, DATA_STORE, APP_DATA_STORE];
    private dbPromise: Promise<IDBPDatabase<any>> | null = null;

    constructor(storeName = NO_STORE) {
        if (storeName && !this.stores.includes(storeName)) {
            throw new Error("Invalid store name");
        }

        this.dbName = "MyMartaDB";
        this.dbVersion = 1;
        this.storeName = storeName;
    }

    private async getDB(): Promise<IDBPDatabase<any>> {

        const storeNames = this.stores;
        if (!this.dbPromise)
            this.dbPromise = openDB(this.dbName, this.dbVersion, {
                // triggered when the database is first created or the version is updated
                upgrade(db) {
                    storeNames.forEach((storeName) => {
                        if (!db.objectStoreNames.contains(storeName)) {
                            db.createObjectStore(storeName, {
                                autoIncrement: true,
                            });
                        }
                    });
                },
            });
        return this.dbPromise;
    }

    private async close(): Promise<void> {
        if (this.dbPromise) {
            const db = await this.dbPromise;
            db.close();
            this.dbPromise = null;
        }
    }

    private async getStoreAndTransaction(
        mode: IDBTransactionMode
    ): Promise<any> {
        const db = await this.getDB();
        const tx = db.transaction(this.storeName, mode);
        return { tx: tx, store: tx.objectStore(this.storeName) };
    }

    public async save<T>(key: string, value: T): Promise<void> {
        if (!this.storeName) return;
        const { tx, store } = await this.getStoreAndTransaction("readwrite");
        await store.put({ ...value }, key);
        await tx.done;
        this.close();
    }

    public async get<T>(key: string, cls: new () => T): Promise<T | undefined> {
        if (!this.storeName) return undefined;
        const { store } = await this.getStoreAndTransaction("readonly");
        const result = await store.get(key);
        this.close();
        return result ?? undefined;
    }

    public async saveMulti(obj: any): Promise<void> {
        if (!this.storeName) return;
        const { tx, store } = await this.getStoreAndTransaction("readwrite");
        await Promise.all(
            Object.entries(obj).map(([key, value]) => store.put(value, key))
        );
        await tx.done;
        this.close();
    }

    public async getMulti(keys: string[]): Promise<any> {
        if (!this.storeName) return {};
        const { store } = await this.getStoreAndTransaction("readonly");
        const results = await Promise.all(keys.map((key) => store.get(key)));
        this.close();
        return keys.reduce((obj: any, key: string, index: number) => {
            obj[key] = results[index];
            return obj;
        }, {});
    }

    public async clear(): Promise<void> {
        await deleteDB(this.dbName, {
            blocked() {
                // This function is called if the database is open elsewhere
                // and can't be deleted until other instances are closed.
                console.log(
                    "Unable to delete database because it is open elsewhere"
                );
            },
        });
        console.log("Database deleted successfully");
    }
}
