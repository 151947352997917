import { useEffect, useState } from "react";
import {Text } from "@mantine/core";

interface TimerBoxProps {
    // Time in seconds
    time: number;
    onTimeout: () => void;
    message?: string;
}

export const TimerBox = ({ time, onTimeout, message }: TimerBoxProps) => {

    const [countdown, setCountdown] = useState(time);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    onTimeout();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }
    , []);

    return (
        <Text size="md">
            {message} {countdown}
        </Text>
    );
}
