import classes from "./cell.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, Group, Text, Title } from "@mantine/core";
import { User } from "../../models/User";
import { UserAvatar } from "../../components/common/User";
import { Licence } from "../../models/Licence";

interface UserInfoCellProps {
    user: User;
    licence?: Licence;
}

const UserInfoCell = ({ user, licence }: UserInfoCellProps) => {
    const intl = useIntl();
    const planName =
        licence?.subscription?.plan.name ??
        intl.formatMessage({ id: "app.label.none" });

    return (
        <Card className={`${classes.container} ${classes.userCard}`}>
            <Card.Section className={classes.section}>
                <Group justify="left">
                    <UserAvatar
                        user={user}
                        avatarProps={{
                            color: "var(--mantine-color-dark-9)",
                        }}
                    />
                    <Text>
                        <FormattedMessage id="app.user.account.hello" />
                    </Text>
                </Group>
            </Card.Section>
            <Card.Section className={classes.section}>
                <Group justify="space-between">
                    <Title
                        order={4}
                    >
                        {user.profile?.firstName} {user.profile?.lastName}
                    </Title>
                </Group>
            </Card.Section>
            <Card.Section className={`${classes.section} ${classes.userInfo}`}>
                <Text>{user.email}</Text>
                <Text>
                    <FormattedMessage
                        id="app.subscription.plan.name"
                        defaultMessage="Subscription plan"
                    />
                    : {planName}
                </Text>
            </Card.Section>
        </Card>
    );
};

export default UserInfoCell;
