import { useContext } from "react";
import { AlertContext } from "../../features/context/AlertContext";
import { useIntl } from "react-intl";
import { BaseError, EmailError, ForbiddenError } from "../../lib/Error";

export const useErrorAlertHandler = () => {
    const { setAlert } = useContext(AlertContext);
    const intl = useIntl();

    const handleError = (error: Error) => {
        if (error instanceof EmailError || error instanceof ForbiddenError) {
            const detailedErrors = error.errors ?? [];
            setAlert({
                color: "orange",
                title: intl.formatMessage({ id: "app.warning" }),
                message: intl.formatMessage(
                    { id: error.message },
                    { details: Object.values(detailedErrors).join(", ") }
                ),
                overlay: true,
                variant: "white"
            });
        } else {
            setAlert({
                color: "red",
                title: intl.formatMessage({ id: "app.error" }),
                message:
                    error instanceof BaseError
                        ? intl.formatMessage(
                              {
                                  id: error.message,
                                  defaultMessage: error.message,
                              },
                              { details: error.serverMessage }
                          )
                        : intl.formatMessage({
                              id: error.message,
                              defaultMessage: error.message,
                          }),
                overlay: true,
                variant: "white"
            });
        }
    };

    return handleError;
};
