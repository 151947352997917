import {
    Box,
    Progress,
    PasswordInput,
    Group,
    Text,
    Center,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useIntl } from "react-intl";

function PasswordRequirement({
    meets,
    label,
}: {
    meets: boolean;
    label: string;
}) {
    return (
        <Text c={meets ? "teal" : "red"} mt={5} size="sm">
            <Center inline>
                {meets ? (
                    <IconCheck size="0.9rem" stroke={1.5} />
                ) : (
                    <IconX size="0.9rem" stroke={1.5} />
                )}
                <Box ml={7}>{label}</Box>
            </Center>
        </Text>
    );
}

function getStrength(password?: string, requirements: any[] = []) {

    if (!password) return 0;

    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

interface PasswordStrengthProps
    extends Omit<
        React.ComponentPropsWithoutRef<typeof PasswordInput>,
        "error" | "onChange"
    > {
    value?: string;
    onChange(value: string): void;
}

function PasswordStrengthInput({
    value,
    onChange,
    onBlur,
    onFocus,
    ...others
}: PasswordStrengthProps) {
    const intl = useIntl();
    const requirements = [
        {
            re: /[0-9]/,
            label: intl.formatMessage({
                id: "app.formLabel.password.includeNumber",
            }),
        },
        {
            re: /[a-z]/,
            label: intl.formatMessage({
                id: "app.formLabel.password.lowerCaseLetter",
            }),
        },
        {
            re: /[A-Z]/,
            label: intl.formatMessage({
                id: "app.formLabel.password.upperCaseLetter",
            }),
        },
        {
            re: /[$&+,:;=?@#|'<>.^*()%!-]/,
            label: intl.formatMessage({
                id: "app.formLabel.password.specialSymbol",
            }),
        },
    ];

    const strength = getStrength(value, requirements);
    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(value ?? "")}
        />
    ));

    const bars = Array(4)
        .fill(0)
        .map((_, index) => (
            <Progress
                value={
                    value && value.length > 0 && index === 0
                        ? 100
                        : strength >= ((index + 1) / 4) * 100
                        ? 100
                        : 0
                }
                color={
                    strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"
                }
                key={index}
                size={4}
            />
        ));

    return (
        <>
            <PasswordInput
                label={intl.formatMessage({ id: "app.formLabel.password" })}
                placeholder={intl.formatMessage({
                    id: "app.formLabel.yourPassword",
                })}
                value={value}
                onChange={(event) => onChange(event.currentTarget.value)}
                onFocus={(event) => {
                    typeof onFocus === "function" && onFocus(event);
                }}
                onBlur={(event) => {
                    typeof onBlur === "function" && onBlur(event);
                }}
                {...others}
                required
            />

            <Group gap={5} grow mt="xs" mb="md">
                {bars}
            </Group>

            <PasswordRequirement
                label={intl.formatMessage(
                    {
                        id: "app.formLabel.password.stringLength",
                    },
                    { strLength: 6 }
                )}
                meets={value ? value.length > 5 : false}
            />
            {checks}
        </>
    );
}

export default PasswordStrengthInput;
