import { Container, Title, Text, Button, Group } from "@mantine/core";
import classes from "./Warning.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../features/context/AuthContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";

export function EmailNotVerified() {
    const location = useLocation();
    const handleError = useErrorAlertHandler();
    const intl = useIntl();
    const { authService } = useAuth();

    const resendVerificationEmail = () => {
        authService
            ?.resendVerificationEmail(location.state.email)
            .then(() => {
                alert(
                    intl.formatMessage({
                        id: "app.verificationEmail.send.success",
                        defaultMessage: "Verification email sent.",
                    })
                );
            })
            .catch((error) => {
                handleError(error as Error);
            });
    };

    return (
        <Container className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.content}>
                    <Title className={classes.title}>
                        <FormattedMessage
                            id="app.warning.emailNotVerified"
                            defaultMessage="Your email address has not been verified yet."
                        />
                    </Title>
                    <Text
                        c="dimmed"
                        size="lg"
                        ta="center"
                        className={classes.description}
                    >
                        <FormattedMessage
                            id="app.warning.emailNotVerified.message"
                            defaultMessage="Please check your email {subscriberEmail} to activate your account."
                            values={{ subscriberEmail: location.state.email }}
                        />
                    </Text>
                    <Group justify="center">
                        <a href="/login">
                            <Button size="md">
                                <FormattedMessage
                                    id="app.button.backToLoginPage"
                                    defaultMessage="Back to login page"
                                />
                            </Button>
                        </a>
                        <Button size="md" onClick={resendVerificationEmail}>
                            <FormattedMessage
                                id="app.button.resendVerificationEmail"
                                defaultMessage="Resend verification email"
                            />
                        </Button>
                    </Group>
                </div>
            </div>
        </Container>
    );
}
