import { Button, Group, Modal, ScrollArea, Text, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import SuccessBox from "./Box/SuccessBox";
import { MessageBoxProps } from "./Box/Interfaces";

interface BaseModalProps {
    opened: boolean;
    close: () => void;
}

interface TextModalProps extends BaseModalProps {
    title?: string;
    message: string;
    redBtnLabel?: string;
    greenBtnLabel?: string;
    customContent?: React.ReactNode;
}

interface ConfirmModalProps extends TextModalProps {
    launchAction: () => void;
}

interface SuccessModalProps extends BaseModalProps {
    boxProps: MessageBoxProps;
}

export const ConfirmModal = ({
    title,
    message,
    opened,
    redBtnLabel,
    greenBtnLabel,
    customContent,
    close,
    launchAction,
}: ConfirmModalProps) => {
    return (
        <Modal opened={opened} onClose={close} withCloseButton={false} centered>
            {title && <Title order={3}>{title}</Title>}
            <Text size="sm" mb="xs" fw={500}>
                {message}
            </Text>
            {customContent}
            <Group>
                <Button onClick={close} color="red">
                    <FormattedMessage
                        id={redBtnLabel ?? "app.label.cancel"}
                        defaultMessage="Cancel"
                    />
                </Button>
                <Button onClick={launchAction} color="green">
                    <FormattedMessage id={greenBtnLabel ?? "app.label.ok"} defaultMessage="OK" />
                </Button>
            </Group>
        </Modal>
    );
};

export const SuccessModal = ({
    opened,
    close,
    boxProps,
}: SuccessModalProps) => {
    return (
        <Modal opened={opened} onClose={close} withCloseButton={true} centered>
            <SuccessBox {...boxProps} />
        </Modal>
    );
};

export const MessageModal = ({
    opened,
    close,
    title,
    message,
}: TextModalProps) => {
    return (
        <Modal opened={opened} onClose={close} withCloseButton={true} centered>
            <Title order={3}>{title}</Title>
            <ScrollArea h={250}>
                <Text size="sm" mb="xs" fw={500}>
                    {message}
                </Text>
            </ScrollArea>
        </Modal>
    );
};
