import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { Accordion, Checkbox, Grid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { formularRef } from "../../common/Wrapper";
import { addressEditProps } from "../Interfaces";
import { AddressInputs, AdressType } from "../../common/Inputs/Address";
import { useRest } from "../../../features/context/RestContext";
import { useErrorAlertHandler } from "../../../features/hooks/useErrorHandler";
import { AlertContext } from "../../../features/context/AlertContext";
import { isEmptyObject } from "../../../lib/Utilities";
import { ProfileAddress } from "../../../models/Contact";
import classes from "../../../styles/global.module.css";

interface AddressValues {
    contactEmail: string;
    text: string;
    countryISO: string;
}

interface FormValues {
    billingSameAsDelivery: boolean;
    billing: AddressValues;
    delivery: AddressValues;
}

const AddressEditGrid = forwardRef<formularRef, addressEditProps>(
    ({ user, updateAddress, setLoaderVisibleCallback }, ref) => {
        const profileAddress = user?.profileAddress;
        const defaultAddress = {
            billingSameAsDelivery: true,
            billing: null,
            delivery: null,
        };
        const { billingSameAsDelivery, billing, delivery } =
            profileAddress ?? defaultAddress;
        const [isChecked, setChecked] = useState<boolean>(
            billingSameAsDelivery ?? true
        );
        const { setAlert } = useContext(AlertContext);
        const handleError = useErrorAlertHandler();
        const { restApiService } = useRest();
        const intl = useIntl();

        const form = useForm<FormValues>({
            validateInputOnBlur: true,
            initialValues: {
                billingSameAsDelivery: billingSameAsDelivery ?? true,
                billing: {
                    contactEmail: billing?.contactEmail ?? "",
                    text: billing?.text ?? "",
                    countryISO: billing?.countryISO ?? "",
                },
                delivery: {
                    contactEmail: delivery?.contactEmail ?? "",
                    text: delivery?.text ?? "",
                    countryISO: delivery?.countryISO ?? "",
                },
            },
            validate: {
                billing: {
                    text: (value) =>
                        !value &&
                        intl.formatMessage({
                            id: "app.address.fieldRequired",
                        }),
                    countryISO: (value) =>
                        !value &&
                        intl.formatMessage({
                            id: "app.country.fieldRequired",
                        }),
                },
            },
        });

        const handleSubmit = async () => {
            const { errors, hasErrors } = form.validate();
            if (hasErrors) {
                const errorStr = Object.values(errors).join(" | ");
                setAlert({
                    color: "orange",
                    title: intl.formatMessage({ id: "app.warning" }),
                    message: errorStr,
                });
                return;
            }
            try {
                setLoaderVisibleCallback(true);
                let resultAddress = undefined;
                if (isEmptyObject(profileAddress)) {
                    resultAddress = await restApiService?.createProfileAddress(
                        form.values
                    );
                } else if (
                    !form.values.billingSameAsDelivery &&
                    isEmptyObject(profileAddress?.delivery)
                ) {
                    resultAddress = await restApiService?.createProfileAddress(
                        form.values
                    );
                } else {
                    resultAddress = await restApiService?.updateProfileAddress(
                        form.values
                    );
                }
                updateAddress(resultAddress as ProfileAddress);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisibleCallback(false);
            }
        };

        const handleCheckboxChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setChecked(event.currentTarget.checked);
            form.setFieldValue(
                "billingSameAsDelivery",
                event.currentTarget.checked
            );
        };
        useImperativeHandle(ref, () => ({ handleSubmit }));

        return (
            <form>
                <Grid grow className={classes.info}>
                    <Grid.Col span={12}>
                        <AddressInputs
                            form={form}
                            addressType={AdressType.billing}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Checkbox
                            styles={{
                                label: {
                                    color: "var(--mantine-color-vigilitech-9)",
                                },
                            }}
                            checked={isChecked}
                            {...form.getInputProps("billingSameAsDelivery")}
                            onChange={handleCheckboxChange}
                            size="md"
                            label={intl.formatMessage({
                                id: "app.address.deliverySameAsBilling",
                                defaultMessage: "Same as billing address",
                            })}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Accordion>
                            <Accordion.Item
                                value="grid"
                                style={{ border: "none" }}
                            >
                                <div
                                    style={{
                                        display: isChecked ? "none" : "block",
                                        padding: "16px",
                                    }}
                                >
                                    <AddressInputs
                                        form={form}
                                        addressType={AdressType.delivery}
                                    />
                                </div>
                            </Accordion.Item>
                        </Accordion>
                    </Grid.Col>
                </Grid>
            </form>
        );
    }
);

export default AddressEditGrid;
