import classes from "./../ShoppingCart.module.css";
import { useForm } from "@mantine/form";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    Group,
    LoadingOverlay,
    Select,
    Text,
    Textarea,
    Title,
} from "@mantine/core";
import {
    CartItem,
    Order,
    OrderCreationDto,
    OrderDto,
    PaymentMethodEnum,
    PaymentMethodLabelEnum,
    ProductCartItem,
} from "../../../../models/Order";
import { FormattedMessage, useIntl } from "react-intl";
import { useRest } from "../../../../features/context/RestContext";
import { useShoppingCart } from "../../../../features/context/ShoppingCartContext";
import { notifications } from "@mantine/notifications";
import { useEffect, useMemo, useRef, useState } from "react";
import { IconX } from "@tabler/icons-react";
import { FeesRow, ProductRow, TransactionFeeRow } from "./ProductRow";
import { CountryCode, CurrencyCode } from "../../../../lib/Constants";
import { Link } from "react-router-dom";
import { BarLoader } from "../../../common/Loader";
import {
    calcDeliveryPackagesAmount,
    calcEstimatedStripeFees,
    calcPriceWithTaxes,
} from "../../../../lib/Utilities";
import { BaseError } from "../../../../lib/Error";
import { useData } from "../../../../features/context/DataContext";
import { useAuth } from "../../../../features/context/AuthContext";

interface ShoppingCartFormProps {
    productData: ProductCartItem[];
    feeData: ProductCartItem;
    closeCB: () => void;
    onErrors?: (errors: any) => void;
}

interface FormValues {
    comment: string;
    paymentMethod: string;
    termsOfService: boolean;
    productCart: CartItem[];
    productDeliveryFee: CartItem;
}

const ShoppingCartForm = ({
    productData,
    feeData,
    closeCB,
    onErrors = () => {},
}: ShoppingCartFormProps) => {
    const notifAutoClose: number = parseInt(
        import.meta.env.VITE_NOTIFICATIONS_AUTOCLOSE_TIMEOUT
    );
    const intl = useIntl();
    const { restApiService } = useRest();
    const { userCountryISO, setShoppingCart } = useShoppingCart();
    const { user } = useAuth();
    const errorRef = useRef<HTMLDivElement>(null);
    const [blurry, setBlurry] = useState<boolean>(false);
    const [showTransactionFee, setShowTransactionFee] =
        useState<boolean>(false);

    const totalPriceTaxFree = useMemo<number>(() => {
        const totalPriceTaxFree: number =
            productData.reduce(
                (acc, curr) => acc + curr.product.price * curr.amount,
                0
            ) +
            feeData.product.price * calcDeliveryPackagesAmount(productData);

        return totalPriceTaxFree;
    }, [productData, feeData]);

    const totalPrice: number = useMemo<number>(() => {
        const totalPrice =
            userCountryISO === CountryCode.CH
                ? calcPriceWithTaxes(totalPriceTaxFree)
                : totalPriceTaxFree;
        return totalPrice;
    }, [totalPriceTaxFree]);

    const estimatedTransactionFee: number = useMemo<number>(
        () =>
            showTransactionFee
                ? calcEstimatedStripeFees(
                      totalPrice,
                      userCountryISO !== CountryCode.CH
                  )
                : 0,
        [totalPrice, showTransactionFee]
    );

    const formValidation = {
        paymentMethod: (value: string) => {
            if (value === "") {
                return intl.formatMessage({
                    id: "app.order.formError.noPaymentMethod",
                });
            }
        },
        productCart: (value: CartItem[]) => {
            if (value.length === 0) {
                return intl.formatMessage({
                    id: "app.order.formError.noProducts",
                });
            }
        },
        termsOfService: (value: boolean) =>
            value ? null : intl.formatMessage({ id: "app.acceptTerms.error" }),
    };

    const form = useForm<FormValues>({
        validateInputOnBlur: true,
        initialValues: getInitialValues(productData, feeData),
        validate: formValidation,
    });

    async function handleSubmit(values: FormValues) {
        setBlurry(true);
        try {
            const response: OrderCreationDto =
                await (restApiService?.createOrder(values) ??
                    new OrderCreationDto());
            const order: Order = OrderDto.toModel(response.order);
            const data: any = response.data;
            if (order) {
                setShoppingCart((prevData) => ({
                    ...prevData,
                    createdOrder: order,
                    stripeCliSecret: (data && data.stripeCliSecret) ?? undefined,
                }));
            }
            closeCB();
        } catch (error: any) {
            const err: BaseError = error as BaseError;
            notifications.show({
                title: intl.formatMessage({ id: "app.error" }),
                onClose: () => onErrors(err),
                message: err.appErrorCode
                    ? intl.formatMessage(
                          { id: "app.error.code" },
                          { code: err.appErrorCode }
                      )
                    : intl.formatMessage({ id: err.message , defaultMessage: "Error"}),
                color: "red",
                icon: <IconX />,
                autoClose: notifAutoClose,
            });
        } finally {
            setBlurry(false);
        }
    }

    const handleError = (errors: any) => {
        errorRef.current!.innerHTML = "";
        Object.values(errors).forEach((error: any) => {
            errorRef.current!.innerHTML += `<div>${error}</div>`;
        });
    };

    useEffect(() => {
        const applyTRFees =
            (import.meta.env.VITE_APPLY_TRANSACTION_FEES as string) === "true";
        setShowTransactionFee(
            form.values.paymentMethod === PaymentMethodEnum.CRED_CARD &&
                applyTRFees
        );
    }, [form.values.paymentMethod]);

    return (
        <Box className={classes.container}>
            <LoadingOverlay
                visible={blurry}
                loaderProps={{ children: <LoadingContent /> }}
            />
            <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
                {productData.map((item, index) => (
                    <ProductRow
                        key={index}
                        form={form}
                        prodCartItem={item}
                        index={index.toString()}
                    />
                ))}
                <Grid className={`${classes.row} ${classes.mainRow}`}>
                    <Grid.Col span={12}>
                        <FeesRow
                            form={form}
                            prodCartItem={feeData}
                            index="productDeliveryFee"
                        />
                    </Grid.Col>
                    {showTransactionFee && (
                        <Grid.Col span={12}>
                            <TransactionFeeRow fee={estimatedTransactionFee} />
                        </Grid.Col>
                    )}
                </Grid>
                <Grid className={classes.row}>
                    {userCountryISO === CountryCode.CH ? (
                        <>
                            <Grid.Col span={6}>
                                <Text fw={700}>
                                    <FormattedMessage id="app.order.formLabel.totalTaxExcluded" />
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                {intl.formatNumber(
                                    totalPriceTaxFree + estimatedTransactionFee,
                                    {
                                        style: "currency",
                                        currency: user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF,
                                    }
                                )}
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text fw={700}>
                                    <FormattedMessage id="app.order.formLabel.appliedTaxRate" />
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text>8.1%</Text>
                            </Grid.Col>{" "}
                            <Grid.Col span={6}>
                                <Text fw={700}>
                                    <FormattedMessage id="app.order.formLabel.totalTaxIncluded" />
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                {intl.formatNumber(
                                    calcPriceWithTaxes(
                                        totalPriceTaxFree +
                                            estimatedTransactionFee
                                    ),
                                    {
                                        style: "currency",
                                        currency: user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF,
                                    }
                                )}
                            </Grid.Col>
                        </>
                    ) : (
                        <>
                            <Grid.Col span={6}>
                                <Text fw={700}>
                                    <FormattedMessage id="app.order.formLabel.totalTaxExcluded" />
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                {intl.formatNumber(
                                    totalPriceTaxFree + estimatedTransactionFee,
                                    {
                                        style: "currency",
                                        currency: user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF,
                                    }
                                )}
                            </Grid.Col>
                        </>
                    )}
                </Grid>
                <Box>
                    <Textarea
                        className={`${classes.row} ${classes.field}`}
                        label={intl.formatMessage({
                            id: "app.formLabel.comment",
                        })}
                        placeholder={intl.formatMessage({
                            id: "app.order.formLabel.gotSpecialRequests",
                        })}
                        {...form.getInputProps("comment")}
                    />
                    <Select
                        className={`${classes.row} ${classes.field}`}
                        label={intl.formatMessage({
                            id: "app.order.formLabel.paymentMethod",
                        })}
                        placeholder={intl.formatMessage({
                            id: "app.order.formLabel.selectPaymentMethod",
                        })}
                        data={Object.entries(PaymentMethodLabelEnum).map(
                            ([key, value]) => {
                                return {
                                    value: key,
                                    label: intl.formatMessage({
                                        id: value,
                                    }),
                                };
                            }
                        )}
                        allowDeselect={false}
                        {...form.getInputProps("paymentMethod")}
                    />
                    <Checkbox
                        label={
                            <Link
                                to={"#"}
                                onClick={(
                                    event: React.MouseEvent<
                                        HTMLAnchorElement,
                                        MouseEvent
                                    >
                                ) => {
                                    event.preventDefault();
                                    console.log("Terms and conditions");
                                }}
                            >
                                <FormattedMessage
                                    id="app.acceptTerms"
                                    defaultMessage="I accept terms and conditions"
                                />
                            </Link>
                        }
                        mt="sm"
                        {...form.getInputProps("termsOfService", {
                            type: "checkbox",
                        })}
                    />
                </Box>
                <Group justify="right">
                    <Button type="submit" variant="filled" color="teal">
                        <FormattedMessage id="app.order.button" />
                    </Button>
                </Group>
                <Box className={classes.error} ref={errorRef}></Box>
            </form>
        </Box>
    );
};

/**
 * Get initial values for the form
 *
 * @param productData
 * @param feeData
 * @returns
 */
function getInitialValues(
    productData: ProductCartItem[],
    feeData: ProductCartItem
) {
    const cartItems: CartItem[] = productData.map((data) => {
        return {
            reference: data.product.reference,
            internalReference: data.product.internalReference,
            amount: data.amount,
        };
    });

    const deliveryFee: CartItem = {
        reference: feeData.product.reference,
        internalReference: feeData.product.internalReference,
        amount: feeData.amount,
    };

    return {
        comment: "",
        paymentMethod: "",
        termsOfService: false,
        productCart: [...cartItems],
        productDeliveryFee: deliveryFee,
    };
}

function LoadingContent() {
    return (
        <Box>
            <BarLoader visible />
            <Title>
                <FormattedMessage id="app.label.processing" />
            </Title>
            <Text>
                <FormattedMessage id="app.order.notification.status.sending" />
            </Text>
        </Box>
    );
}

export default ShoppingCartForm;
