import { Grid } from "@mantine/core";
import { useAuth } from "../../features/context/AuthContext";
import TeamStatCell from "../../components/Dashboard/TeamStatCell";
import RecordingStatCell from "../../components/Dashboard/RecordingStatCell";
import OrderStatCell from "../../components/Dashboard/OrderStatCell";
import {
    Action,
    Feature,
    useUserFeatureAccessHandler,
} from "../../features/hooks/useUserFeatureAccessHandler";
import UserInfoCell from "../../components/Dashboard/UserInfoCell";
import { useData } from "../../features/context/DataContext";
import PlanDetailsGrid from "./PlanDetailsContent";

const DashboardContent = () => {
    const { user } = useAuth();
    const { assignedLicence } = useData();
    const { isUnassignedUser, isAccessAllowed } = useUserFeatureAccessHandler();

    return (
        <Grid>
            {user && (
                <Grid.Col span={3}>
                    <UserInfoCell user={user} licence={assignedLicence} />
                </Grid.Col>
            )}
            <Grid.Col span={9}>
                <PlanDetailsGrid />
            </Grid.Col>
            {isAccessAllowed(Feature.Recording, Action.View) && (
                <Grid.Col span={{ sm: 12, md: 6 }}>
                    <RecordingStatCell />
                </Grid.Col>
            )}
            {isAccessAllowed(Feature.VirtualLab) && (
                <Grid.Col span={{ sm: 12, md: 6 }}>
                    <TeamStatCell />
                </Grid.Col>
            )}
            {isUnassignedUser() ||
            isAccessAllowed(Feature.Subscription, Action.ViewAdmin) ? (
                <Grid.Col span={{ sm: 12, md: 6 }}>
                    <OrderStatCell />
                </Grid.Col>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default DashboardContent;
