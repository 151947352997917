import globalClasses from "../../styles/global.module.css";
import classes from "./Invitation.module.css";
import {
    Box,
    Button,
    Container,
    LoadingOverlay,
    Paper,
    PinInput,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { matches, useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../features/context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";

interface FormValues {
    pinCode: string;
}

function InviteePage() {
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const { authService } = useAuth();
    const { invitationUuid } = useParams();
    const errorRef = useRef<HTMLDivElement>(null);
    const handleError = useErrorAlertHandler();
    const navigate = useNavigate();

    if (!invitationUuid) return <></>;

    const initialValues = {
        pinCode: "******",
    };
    const form = useForm<FormValues>({
        initialValues,
        validate: {
            pinCode: matches(/^\d{6}$/, "Invalid PIN code"),
        },
    });

    const handleSubmit = async () => {
        setLoaderVisible(true);
        const { pinCode } = form.values;
        try {
            await authService?.verifyInvitee(pinCode, invitationUuid);
            navigate(`/invitation/${invitationUuid}/password`);
        } catch (error) {
            const err = error as Error;
            handleError(err);
        }
        setLoaderVisible(false);
    };

    const handleFormError = (errors: typeof form.errors) => {
        errorRef.current!.innerHTML = errors.pinCode?.toString() || "";
    };

    useEffect(() => {
        errorRef.current!.innerHTML = "";
    }, []);
    
    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <Container size={420} my={40} className={classes.pageContent}>
                <Box>
                    <Title ta="center" className={globalClasses.title}>
                        <FormattedMessage
                            id="app.login.welcome"
                            defaultMessage="Welcome to MARTA"
                        />
                    </Title>
                    <Text c="dimmed" size="lg" ta="center" mt={5}>
                        <FormattedMessage
                            id="app.invitation.secondTitle"
                            defaultMessage="You have been invited to join MyMARTA"
                        />
                    </Text>
                    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                        <form
                            onSubmit={form.onSubmit(
                                handleSubmit,
                                handleFormError
                            )}
                        >
                            <Stack align="center">
                                <Text c="dimmed" size="lg" ta="center" mt={5}>
                                    <FormattedMessage
                                        id="app.invitation.form.enterPIN"
                                        defaultMessage="Please enter the PIN code you received"
                                    />
                                </Text>
                                <PinInput
                                    length={6}
                                    size="xl"
                                    placeholder="*"
                                    type="number"
                                    {...form.getInputProps("pinCode")}
                                />
                                <Box
                                    ref={errorRef}
                                    className={globalClasses.error}
                                ></Box>
                                <Button fullWidth mt="xl" type="submit">
                                    <FormattedMessage
                                        id="app.button.submit"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </Stack>
                        </form>
                    </Paper>
                </Box>
            </Container>
        </>
    );
}

export default InviteePage;
