import classes from "./cell.module.css";
import globalClasses from "../../styles/global.module.css";
import { Box, Card, Text, Title } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { RecordingStat } from "../../models/Stats";
import { useEffect, useState } from "react";
import { StatsSegments } from "../common/Stats/StatsSegments";
import { IStatData } from "../common/Stats/Interfaces";
import { calculatePart } from "../../lib/Utilities";
import { useRest } from "../../features/context/RestContext";
import { UrlParamType } from "../../lib/Enums";
import { BaseError } from "../../lib/Error";
import { BarLoader } from "../common/Loader";
import { useUserFeatureAccessHandler } from "../../features/hooks/useUserFeatureAccessHandler";
import { LicenceRoleEnum } from "../../models/Licence";

const RecordingStatCell = () => {
    const intl = useIntl();
    const { restApiService } = useRest();
    const { isRole } = useUserFeatureAccessHandler();
    const [recordingStats, setRecordingStats] = useState<
        RecordingStat | undefined
    >(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const getContext = (): [string, UrlParamType] => {
        if (isRole(LicenceRoleEnum.OWNER_ROLE))
            return [
                "app.recordings.mySubscriptionRecordings",
                UrlParamType.SUBSCRIPTION,
            ];
        if (isRole(LicenceRoleEnum.ADMIN_ROLE))
            return ["app.recordings.myLabRecordings", UrlParamType.TEAM];
        if (isRole(LicenceRoleEnum.ASSIGNEE_ROLE))
            return ["app.recordings.myRecordings", UrlParamType.ME];

        return ["app.recordings", UrlParamType.ME];
    };

    const getStats = async () => {
        try {
            setLoading(true);
            const data: RecordingStat | undefined =
                await restApiService?.getRecordingStats(getContext()[1]);
            setRecordingStats(data);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setLoading(false);
        }
    };

    const processedRecordingStats: IStatData[] = [
        {
            label: intl.formatMessage({ id: "app.recordings.ongoing" }),
            count: recordingStats?.ongoing ?? 0,
            part: calculatePart(
                recordingStats?.ongoing ?? 0,
                recordingStats?.total ?? 1
            ),
            color: "#ca2e55",
        },
        {
            label: intl.formatMessage({ id: "app.recordings.completed" }),
            count: recordingStats?.completed ?? 0,
            part: calculatePart(
                recordingStats?.completed ?? 0,
                recordingStats?.total ?? 1
            ),
            color: "#2e467b",
        },
    ];

    useEffect(() => {
        getStats();
    }, []);

    return (
        <Card
            className={`${classes.container} ${globalClasses.card}`}
        >
            <Card.Section className={classes.section}>
                <Title className={classes.title}>
                    <FormattedMessage id={getContext()[0]} />
                </Title>
                <Text className={classes.info}>
                    <FormattedMessage id="app.recording.stat.description" />
                </Text>
            </Card.Section>
            <Card.Section className={classes.section}>
                <BarLoader visible={loading} />
                {recordingStats?.total && !loading ? (
                    <StatsSegments data={processedRecordingStats} />
                ) : (
                    <Box>
                        <FormattedMessage id="app.recordings.empty" />
                    </Box>
                )}
            </Card.Section>
        </Card>
    );
};

export default RecordingStatCell;
