import { ActionIcon, Box, Group, Title } from "@mantine/core";
import { IconEdit, IconCheck, IconX, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from "../../styles/form.module.css";

export interface formularRef {
    handleSubmit: () => void;
}

interface ActionsWrapperProps {
    titleId?: string;
    titleDefault?: string;
    formular: React.ReactElement;
    container: React.ReactNode;
    handleFormSubmit?: () => void;
    handleDelete?: () => void;
}

export function ActionsWrapper({
    titleId,
    titleDefault,
    formular,
    container,
    handleFormSubmit,
    handleDelete,
}: ActionsWrapperProps) {
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleSubmitAction = () => {
        setEditMode(false);
        handleFormSubmit?.();
    };

    const handleDeleteAction = () => {
        handleDelete?.();
    };

    return (
        <>
            <Box>
                <Group className={classes.wrapperHeader}>
                    {titleId && (
                        <Title order={3}>
                            <FormattedMessage
                                id={titleId}
                                defaultMessage={titleDefault}
                            />
                        </Title>
                    )}
                    {!editMode ? (
                        <>
                            {handleDelete && (
                                <ActionIcon
                                    color="red"
                                    size="2rem"
                                    onClick={handleDeleteAction}
                                >
                                    <IconTrash size={20} />
                                </ActionIcon>
                            )}
                            {handleFormSubmit && (
                                <ActionIcon
                                    color="black"
                                    size="2rem"
                                    onClick={() => setEditMode(true)}
                                >
                                    <IconEdit size={20} />
                                </ActionIcon>
                            )}
                        </>
                    ) : (
                        <>
                            <ActionIcon
                                color="red"
                                size="2rem"
                                onClick={() => setEditMode(false)}
                            >
                                <IconX size={20} />
                            </ActionIcon>
                            {handleFormSubmit && (
                                <ActionIcon
                                    color="green"
                                    size="2rem"
                                    onClick={handleSubmitAction}
                                >
                                    <IconCheck size={20} />
                                </ActionIcon>
                            )}
                        </>
                    )}
                </Group>
                {editMode ? formular : container}
            </Box>
        </>
    );
}
