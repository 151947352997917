import {
    Alert,
    Button,
    MantineColor,
    Overlay,
    Stack,
    Text,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { createContext, useState } from "react";
import classes from "../../styles/global.module.css";
import { FormattedMessage } from "react-intl";

interface IAlert {
    color: MantineColor | undefined;
    title: string;
    message: string;
    className?: CSSModuleClasses[string];
    variant?: string;
    buttonColor?: MantineColor;
    overlay?: boolean;
}

interface IAlertProps extends IAlert {
    setAlert: React.Dispatch<React.SetStateAction<IAlert>>;
}

export const AlertContext = createContext<IAlertProps>({
    color: undefined,
    title: "",
    message: "",
    setAlert: () => {},
});

const defaultAlert: IAlert = { color: undefined, title: "", message: "" };

export const AlertProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const [alert, setAlert] = useState<IAlert>(defaultAlert);
    const contextValue: IAlertProps = {
        ...alert,
        setAlert: setAlert,
    };

    const reset = () => {
        setAlert(defaultAlert);
    };

    return (
        <AlertContext.Provider value={contextValue}>
            <div
                className={classes.alertContainer}
                style={{ display: !alert.color ? "none" : "block" }}
            >
                { alert.overlay && <Overlay opacity={0.7} color="gray" /> }
                <Alert
                    className={alert.className ?? classes.alertDefault}
                    classNames={{
                        closeButton: classes.closeButton,
                    }}
                    variant={ alert.variant ?? "filled"}
                    icon={<IconAlertCircle size={20} />}
                    title={alert.title}
                    color={alert.color}
                    radius="sm"
                    withCloseButton
                    closeButtonLabel="Close alert"
                    onClose={reset}
                >
                    <Stack>
                        <Text>{alert.message}</Text>
                        <Button
                            className={classes.button}
                            color={alert.buttonColor ?? "gray"}
                            onClick={reset}
                        >
                            <FormattedMessage id="app.label.close" />
                        </Button>
                    </Stack>
                </Alert>
            </div>
            {props.children}
        </AlertContext.Provider>
    );
};
