import {
    Box,
    Button,
    Center,
    Group,
    Loader,
    MultiSelect,
    Title,
} from "@mantine/core";
import { useState } from "react";
import ErrorBox from "../../common/ErrorBox";
import SuccessBox from "../../common/Box/SuccessBox";
import { useForm } from "@mantine/form";
import { FormattedMessage, useIntl } from "react-intl";
import { BaseError } from "../../../lib/Error";
import { MemberRoleFormProps } from "../Interfaces";
import { LAB_EDITABLE_ROLE_MAP } from "../../../models/Team";
import { useRest } from "../../../features/context/RestContext";

export default function MemberRoleForm({ team, member, successCB }: MemberRoleFormProps) {
    const intl = useIntl();
    const { restApiService } = useRest();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<BaseError | null>(null);
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

    const editableMemberRoles = member?.teamRoles.filter((role) => Array.from(LAB_EDITABLE_ROLE_MAP.keys()).includes(role));
    const multiselectData = Array.from(LAB_EDITABLE_ROLE_MAP, ([value, label]) => ({
        value: value,
        label: intl.formatMessage({ id: label }),
    }));

    const form = useForm({
        initialValues: {
            roles: editableMemberRoles,
        },
    });

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const roles = form.values.roles;
            const resp = await restApiService?.updateTeamMemberRoles(
                team.uuid,
                member.uuid,
                roles
            );
            setRequestSuccess(true);
            successCB && successCB();
        } catch (error) {
            const err = error as BaseError;
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error: any) => {};

    return (
        <>
            {loading ? (
                <Center>
                    <Loader color="grey" type="bars" />
                </Center>
            ) : (
                <Box>
                    {error ? (
                        <ErrorBox error={error} />
                    ) : requestSuccess ? (
                        <SuccessBox
                            messageIntl={"app.virtualLab.member.rolesUpdated"}
                            messageInltValues={{ memberEmail: member?.email }}
                        />
                    ) : (
                        <form
                            onSubmit={form.onSubmit(handleSubmit, handleError)}
                        >
                            <Title order={2}>
                                <FormattedMessage
                                    id="app.virtualLab.member.roles"
                                    defaultMessage="Roles of {memberEmail}"
                                    values={{ memberEmail: member?.email }}
                                />
                            </Title>
                            <MultiSelect
                                label={intl.formatMessage({
                                    id: "app.virtualLab.member.roles.select",
                                })}
                                placeholder={intl.formatMessage({
                                    id: "app.label.pickValue",
                                })}
                                data={multiselectData}
                                {...form.getInputProps("roles")}
                            />
                            <Group justify="right">
                                <Button
                                    type="submit"
                                    variant="filled"
                                    color="teal"
                                >
                                    <FormattedMessage
                                        id="app.label.assign"
                                        defaultMessage="Assign"
                                    />
                                </Button>
                            </Group>
                        </form>
                    )}
                </Box>
            )}
        </>
    );
}
