import { Expose, instanceToPlain, plainToClass } from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic } from "./Common";

@staticImplements<DtoStatic>()
export class OrderStatDto {
    @Expose()
    total!: number;
    @Expose()
    new!: number;
    @Expose()
    shipped!: number;
    @Expose()
    needSupport!: number;
    @Expose()
    completed!: number;
    @Expose()
    cancelled!: number;

    static toModel(orderStatDto: OrderStatDto): OrderStat {
        if (orderStatDto === undefined) {
            return new OrderStat();
        }
        const data = instanceToPlain(orderStatDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(OrderStat, data);
    }
}

export class OrderStat {
    @Expose()
    total!: number;
    @Expose()
    new!: number;
    @Expose()
    shipped!: number;
    @Expose()
    needSupport!: number;
    @Expose()
    completed!: number;
    @Expose()
    cancelled!: number;
}

@staticImplements<DtoStatic>()
export class LabStatDto {
    @Expose()
    name!: string;
    @Expose()
    totalMembers!:number;
    @Expose()
    activeMembers!:number;
    @Expose()
    inactiveMembers!:number;

    static toModel(labStatDto: LabStatDto): LabStat {
        if (labStatDto === undefined) {
            return new LabStat();
        }
        const data = instanceToPlain(labStatDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(LabStat, data);
    }
}

@staticImplements<DtoStatic>()
export class TeamStatDto {
    @Expose()
    totalMemberships!: number;
    @Expose()
    activeMemberships!: number;
    @Expose()
    inactiveMemberships!: number;
    @Expose()
    owned!: number;
    @Expose()
    created!: number;

    static toModel(teamStatDto: TeamStatDto): TeamStat {
        if (teamStatDto === undefined) {
            return new TeamStat();
        }
        const data = instanceToPlain(teamStatDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(TeamStat, data);
    }
}

export class LabStat {
    @Expose()
    name!: string;
    @Expose()
    totalMembers!:number;
    @Expose()
    activeMembers!:number;
    @Expose()
    inactiveMembers!:number;
}

export class TeamStat {
    @Expose()
    totalMemberships!: number;
    @Expose()
    activeMemberships!: number;
    @Expose()
    inactiveMemberships!: number;
    @Expose()
    owned!: number;
    @Expose()
    created!: number;   
}

@staticImplements<DtoStatic>()
export class RecordingStatDto {
    @Expose()
    total!: number;
    @Expose()
    ongoing!: number;
    @Expose()
    completed!: number;

    static toModel(teamStatDto: RecordingStatDto): RecordingStat {
        if (teamStatDto === undefined) {
            return new RecordingStat();
        }
        const data = instanceToPlain(teamStatDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(RecordingStat, data);
    }
}

export class RecordingStat {
    @Expose()
    total!: number;
    @Expose()
    ongoing!: number;
    @Expose()
    completed!: number;
}
