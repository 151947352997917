import { createContext, useContext, useEffect, useState } from "react";
import { User } from "../../models/User";
import { AuthApiService } from "../../services/Api";
import IndexedDService, { AUTH_STORE } from "../../services/IndexedDB";
import { AuthenticationError } from "../../lib/Error";
import { isEmptyObject } from "../../lib/Utilities";

interface IAuth {
    user: User | null;
    subscriberEmail?: string;
}

interface IAuthProps extends IAuth {
    authService: AuthApiService | null;
    setCurrentAuth: React.Dispatch<React.SetStateAction<IAuth>>;
    setUserPersist: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    startPing: () => void;
    stopPing: () => void;
}

export const AuthContext = createContext<IAuthProps>({
    user: null,
    subscriberEmail: "",
    authService: null,
    setCurrentAuth: () => {},
    setUserPersist: () => {},
    loading: false,
    startPing: () => {},
    stopPing: () => {},
});

export const useAuth = () => {
    const auth = useContext(AuthContext);

    if (!auth) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return auth;
};

const defaultAuth: IAuth = { user: null };
const dbUtil: IndexedDService = new IndexedDService(AUTH_STORE);
const storageKey: string = "user";

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const [currentAuth, setCurrentAuth] = useState<IAuth>(defaultAuth);
    const [loading, setLoading] = useState<boolean>(true);
    const [persist, setUserPersist] = useState<boolean>(true);
    const [pingInterval, setPingInterval] = useState<NodeJS.Timeout | undefined>(undefined);

    const startPing = async () => {
        if (!isEmptyObject(currentAuth.user) && pingInterval === undefined) {
            const interval: NodeJS.Timeout | undefined = setInterval(() => {
                ping();
            }, 20000);
            setPingInterval(interval);
        }
    };

    const stopPing = () => {
        clearInterval(pingInterval);
        setPingInterval(undefined);
    };

    const contextValue: IAuthProps = {
        ...currentAuth,
        authService: AuthApiService.getInstance(),
        setCurrentAuth: setCurrentAuth,
        setUserPersist: setUserPersist,
        loading,
        startPing: startPing,
        stopPing: stopPing,
    };

    // Function to persist user data to IndexedDB
    const persistUser = async (user: User | null) => {
        await dbUtil.save<User | null>(storageKey, user ?? null);
    };

    const ping = async () => {
        if (currentAuth.user) {
            try {
                await contextValue.authService?.ping();
            } catch (error: any) {
                if (error instanceof AuthenticationError) {
                    window.dispatchEvent(new CustomEvent("sessionExpired"));
                }
            }
        }
    };

    useEffect(() => {
        // Load the user from IndexedDB when the provider mounts
        const loadUser = async () => {
            const storedUser = await dbUtil.get<User>(storageKey, User);
            if (storedUser) {
                setCurrentAuth((prevState) => ({
                    ...prevState,
                    user: storedUser,
                }));
            }
            setLoading(false);
        };

        loadUser();
    }, []);

    // Effect to persist user data whenever it changes
    useEffect(() => {
        persist && persistUser(currentAuth.user);
        startPing();
    }, [currentAuth.user]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
