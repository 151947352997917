import globalClasses from "../../../styles/global.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { addressGridProps } from "../Interfaces";
import { useState } from "react";
import { Grid, Title, Text, Checkbox, Accordion } from "@mantine/core";

export default function AddressReadOnlyGrid({
    profileAddress,
}: addressGridProps) {
    const intl = useIntl();
    const defaultAddress = {
        billingSameAsDelivery: true,
        billing: null,
        delivery: null,
    };
    const { billingSameAsDelivery, billing, delivery } = profileAddress
        ? profileAddress
        : defaultAddress;

    const [isChecked, setChecked] = useState<boolean>(
        billingSameAsDelivery ?? true
    );

    if (!billing) {
        return (
            <>
                <Grid grow className={globalClasses.infoGrid}>
                    <Grid.Col span={12}>
                        <FormattedMessage id="app.message.pleaseEditYourAddress" />
                    </Grid.Col>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Grid grow className={globalClasses.infoGrid}>
                <Grid.Col span={12}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title order={3}>
                                <FormattedMessage
                                    id="app.address.billing"
                                    defaultMessage="Billing address"
                                />
                            </Title>
                        </Grid.Col>
                        {billing?.contactEmail && (
                            <Grid.Col span={9}>
                                <Title order={5}>
                                    <FormattedMessage
                                        id="app.address.contactEmail"
                                        defaultMessage="Contact email"
                                    />
                                </Title>
                                <Text>{billing.contactEmail}</Text>
                            </Grid.Col>
                        )}
                        <Grid.Col span={9}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.address"
                                    defaultMessage="Address"
                                />
                            </Title>
                            <Text>{billing?.text}</Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.country"
                                    defaultMessage="Country"
                                />
                            </Title>
                            <Text>{billing?.countryName}</Text>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Checkbox
                        styles={{
                            label: {
                                color: "var(--mantine-color-vigilitech-9)",
                            },
                        }}
                        checked={isChecked}
                        disabled
                        onChange={(event) =>
                            setChecked(event.currentTarget.checked)
                        }
                        size="md"
                        label={intl.formatMessage({
                            id: "app.address.deliverySameAsBilling",
                            defaultMessage: "Same as billing address",
                        })}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Accordion>
                        <Accordion.Item value="grid" style={{ border: "none" }}>
                            <div
                                style={{
                                    display: isChecked ? "none" : "block",
                                    padding: "16px",
                                }}
                            >
                                <Grid>
                                    <Grid.Col span={12}>
                                        <Title order={3}>
                                            <FormattedMessage
                                                id="app.address.delivery"
                                                defaultMessage="Delivery address"
                                            />
                                        </Title>
                                    </Grid.Col>
                                    {delivery?.contactEmail && (
                                        <Grid.Col span={9}>
                                            <Title order={5}>
                                                <FormattedMessage
                                                    id="app.address.contactEmail"
                                                    defaultMessage="Contact email"
                                                />
                                            </Title>
                                            <Text>{delivery.contactEmail}</Text>
                                        </Grid.Col>
                                    )}
                                    <Grid.Col span={9}>
                                        <Title order={5}>
                                            <FormattedMessage
                                                id="app.address"
                                                defaultMessage="Address"
                                            />
                                        </Title>
                                        <Text>{delivery?.text}</Text>
                                    </Grid.Col>
                                    <Grid.Col span={6}>
                                        <Title order={5}>
                                            <FormattedMessage
                                                id="app.country"
                                                defaultMessage="Country"
                                            />
                                        </Title>
                                        <Text>{delivery?.countryName}</Text>
                                    </Grid.Col>
                                </Grid>
                            </div>
                        </Accordion.Item>
                    </Accordion>
                </Grid.Col>
            </Grid>
        </>
    );
}
