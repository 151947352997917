import {
    Box,
    Button,
    Grid,
    Group,
    TextInput,
    Title,
    Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconX } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import {
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { validateEmail } from "../../../lib/Validator";
import { useRest } from "../../../features/context/RestContext";
import { FormSubmitContext } from "../../../features/context/FormSubmitContext";
import { BaseError } from "../../../lib/Error";
import { BarLoader } from "../../common/Loader";
import { SuccessModal } from "../../common/Modal";
import { useDisclosure } from "@mantine/hooks";
import { UserSubscriptionReport } from "../../../models/Subscription";
import { useData } from "../../../features/context/DataContext";
import useDataUpdateHandler, { UPDATE_LICENCE } from "../../../features/hooks/useDataUpdateHandler";
import { ISubscriptionStats, useSubscriptionStats } from "../../../features/hooks/useSubscriptionStats";

interface FormValues {
    emails: string[];
}

function SubscriptionReport({
    subscribedUsers,
    invitedUsers,
}: UserSubscriptionReport) {
    return (
        <>
            {subscribedUsers.length > 0 && (
                <Text>
                    <FormattedMessage
                        id="app.subscription.registerUser.success"
                        defaultMessage="Users {emails} have been registered."
                        values={{ emails: subscribedUsers }}
                    />
                </Text>
            )}
            {invitedUsers.length > 0 && (
                <Text>
                    <FormattedMessage
                        id="app.subscription.registerUser.invited"
                        defaultMessage="Sent invitation to the following users: {emails}."
                        values={{ emails: invitedUsers }}
                    />
                </Text>
            )}
        </>
    );
}
// TODO: see https://mantine.dev/form/nested/#nested-arrays for refactoring
export default function AddUsersForm() {
    const intl = useIntl();
    const { assignedLicence } = useData();
    const { restApiService } = useRest();
    const { handleFormSubmitted } = useContext(FormSubmitContext);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [successOpened, successHandler] = useDisclosure(false);
    const [report, setReport] = useState<ReactNode>("");

    const subsctriptionStats: ISubscriptionStats = useSubscriptionStats();

    const [maxUsersAvailable, setMaxUsersAvailable] =
        useState<number>(subsctriptionStats.users.left);
    const updateLicence = useDataUpdateHandler(UPDATE_LICENCE);

    const errorRef = useRef<HTMLDivElement>(null);
    const form = useForm<FormValues>({
        initialValues: {
            emails: [""],
        },
        validate: {
            emails: (value) => {
                if (value.some((email) => !email.trim())) {
                    return intl.formatMessage({
                        id: "app.formLabel.fields.error.empty",
                        defaultMessage: "All fields must be completed.",
                    });
                }
                if (value.some((email) => !validateEmail(email))) {
                    return intl.formatMessage({
                        id: "app.formLabel.emails.mustBeValid",
                        defaultMessage: "Emails must be valid",
                    });
                }
                return null;
            },
        },
    });

    const addEmailField = () => {
        // -1 because we already have one field
        if (maxUsersAvailable - 1 === 0) return;
        const newEmails = [...form.values.emails, ""];
        setMaxUsersAvailable(maxUsersAvailable - 1);
        form.setFieldValue("emails", newEmails);
    };

    const removeEmailField = (index: number) => {
        const newEmails = form.values.emails.filter((_, i) => i !== index);
        setMaxUsersAvailable(maxUsersAvailable + 1);
        form.setFieldValue("emails", newEmails);
    };

    const handleEmailChange = (value: string, index: number) => {
        const newEmails = form.values.emails.map((item, i) =>
            i === index ? value : item
        );
        form.setFieldValue("emails", newEmails);
    };

    const handleSubmit = async ({ emails }: FormValues) => {
        let duplicates = emails.filter(
            (item, index) => emails.indexOf(item) !== index
        );
        if (duplicates.length > 0) {
            errorRef.current!.innerHTML = intl.formatMessage(
                {
                    id: "app.formLabel.emails.warning.duplicate",
                    defaultMessage:
                        "Duplicate emails found: {emails}, those have been removed from the request",
                },
                { emails: duplicates.join(", ") }
            );
        }
        const uniqueEmails = emails.filter((item) => {
            if (duplicates.includes(item)) {
                duplicates = duplicates.filter((email) => email !== item);
                return false;
            }
            return true;
        });

        try {
            setLoaderVisible(true);
            const subscriptionReport: UserSubscriptionReport | undefined =
                await restApiService?.addUsersToSubscription(
                    assignedLicence?.subscription?.uuid ?? "",
                    uniqueEmails
                );
            const subscribedUsers = subscriptionReport?.subscribedUsers ?? [];
            const invitedUsers = subscriptionReport?.invitedUsers ?? [];
            updateLicence();
            setReport(
                <SubscriptionReport
                    subscribedUsers={subscribedUsers}
                    invitedUsers={invitedUsers}
                />
            );
            successHandler.open();
            handleFormSubmitted();
        } catch (error: any) {
            handleErrors(error as Error);
        } finally {
            form.setFieldValue("emails", [""]);
            setLoaderVisible(false);
        }
    };

    const handleErrors = (errors: any) => {
        if (errors instanceof BaseError) {
            errorRef.current!.innerHTML = `${intl.formatMessage({
                id: errors.message,
            }, {details: errors.serverMessage})}`;
            return;
        }
        errorRef.current!.innerHTML = errors.emails;
    };

    useEffect(() => {
        errorRef.current!.innerHTML = "";
    }, []);

    return (
        <>
            <SuccessModal
                opened={successOpened}
                close={successHandler.close}
                boxProps={{
                    message: report,
                }}
            />
            <Title order={2}>
                <FormattedMessage
                    id="app.label.addUsers"
                    defaultMessage="Add users"
                />{" "}
                (max: {maxUsersAvailable})
            </Title>
            <BarLoader visible={loaderVisible} />
            {!loaderVisible && (
                <form onSubmit={form.onSubmit(handleSubmit, handleErrors)}>
                    <Grid align="start">
                        {form.values.emails.map((item, index) => (
                            <Grid.Col span={3} key={index}>
                                <Group key={index} gap="xs" align="end">
                                    <TextInput
                                        required
                                        label={`Email ${index + 1}`}
                                        placeholder={intl.formatMessage({
                                            id: "app.formLabel.email.placeholder",
                                            defaultMessage: "Enter email",
                                        })}
                                        {...form.getInputProps(
                                            `emails.[${index}]`
                                        )}
                                        value={item}
                                        onChange={(event) =>
                                            handleEmailChange(
                                                event.currentTarget.value,
                                                index
                                            )
                                        }
                                    />
                                    {form.values.emails.length > 1 && (
                                        <Button
                                            onClick={() =>
                                                removeEmailField(index)
                                            }
                                            color="red"
                                        >
                                            <IconX size={24} />
                                        </Button>
                                    )}
                                </Group>
                            </Grid.Col>
                        ))}
                    </Grid>
                    <Group align="right" mt="md">
                        <Button onClick={addEmailField}>
                            <FormattedMessage
                                id="app.action.addEmail"
                                defaultMessage="Add email"
                            />
                        </Button>
                        <Button type="submit">
                            <FormattedMessage
                                id="app.button.submit"
                                defaultMessage="Submit"
                            />
                        </Button>
                    </Group>
                </form>
            )}
            <Box ref={errorRef} color="red"></Box>
        </>
    );
}
