import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { TeamMember } from "../../../models/Team";
import { useRest } from "../../../features/context/RestContext";
import { FormSubmitProvider } from "../../../features/context/FormSubmitContext";
import { useErrorAlertHandler } from "../../../features/hooks/useErrorHandler";
import { MembersTable } from "./Table";
import { useTeamData } from "../../../features/context/TeamContext";

export function MembersList() {
    const { restApiService } = useRest();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const { currentTeam, membersCtxRefreshTS, setTeamData } = useTeamData();
    const handleError = useErrorAlertHandler();

    const getTeamMembers = async () => {
        if (currentTeam?.uuid) {
            try {
                setLoaderVisible(true);
                const data: TeamMember[] =
                    await (restApiService?.getTeamMembers(currentTeam.uuid) ??
                        []);
                setTeamData((prev) => ({ ...prev, currentMembersList: data }));
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    useEffect(() => {
        getTeamMembers();
    }, [currentTeam, membersCtxRefreshTS]);

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <FormSubmitProvider handleFormSubmitted={getTeamMembers}>
                <MembersTable />
            </FormSubmitProvider>
        </>
    );
}
