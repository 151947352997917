import { Box, Text } from "@mantine/core";
import { BaseError } from "../../lib/Error";
import { FormattedMessage } from "react-intl";

interface ErrorBoxProps {
    error: BaseError;
}

export default function ErrorBox({error}: ErrorBoxProps) {
    return (
        <Box color="red" mb={10}>
        <Text>
            <FormattedMessage id={error.message} />
        </Text>
        {error.serverMessage && (
            <Text>
                <FormattedMessage
                    id="app.error.serverSaid"
                    values={{
                        message: error.serverMessage,
                    }}
                />
            </Text>
        )}
        <Text>
            {" "}
            <FormattedMessage
                id="app.error.tryAgain"
                defaultMessage="Please try again"
            />
        </Text>
    </Box>
    );
}