import { useContext, useEffect, useRef, useState } from "react";
import { useRest } from "../../../features/context/RestContext";
import { Container, Title } from "@mantine/core";
import { Paginated } from "../../../models/Common";
import { Product } from "../../../models/Product";
import PaginationContainer from "../../common/Pagination";
import { ProductCard } from "./ProductCard";
import ProductDetailsModal from "./ProductDetailsModal";
import { useDisclosure } from "@mantine/hooks";
import { BarLoader } from "../../common/Loader";
import { useIntl } from "react-intl";
import { ProductType } from "../../../lib/Constants";
import { AlertContext } from "../../../features/context/AlertContext";
import globalClasses from "../../../styles/global.module.css";
import { useAuth } from "../../../features/context/AuthContext";

export const ProductList = () => {
    const { restApiService } = useRest();
    const { user } = useAuth();
    const { setAlert } = useContext(AlertContext);
    const [activePage, setPage] = useState<number>(1);
    const errorRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<Paginated<Product>>();
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(
        null
    );
    const [opened, { close, open }] = useDisclosure(false);

    const productsPerPage: number = 6;

    const getProductList = async () => {
        try {
            setLoaderVisible(true);
            const data: Paginated<Product> =
                await (restApiService?.getProductListbyType(
                    ProductType.PHYSICAL,
                    activePage,
                    productsPerPage
                ) ?? new Paginated<Product>());
            setResponseData(data);
        } catch (error: any) {
            errorRef.current!.innerHTML = intl.formatMessage({
                id: error.message,
                defaultMessage: error.message,
            });
        } finally {
            setLoaderVisible(false);
        }
    };

    const viewProductDetails = (product: Product) => {
        setSelectedProduct(product);
        open();
    };

    useEffect(() => {
        getProductList();
    }, [activePage]);

    useEffect(() => {
        if (!user?.profile?.transactionsCurrencyCode) {
            setAlert({
                color: "orange",
                title: intl.formatMessage({ id: "app.warning" }),
                message: intl.formatMessage({
                    id: "app.warning.currencyNotSet",
                }),
                variant: "white",
                className: globalClasses.alertSmall,
                buttonColor: "black",
            });
        }
    }, []);

    return (
        <>
            {selectedProduct && (
                <ProductDetailsModal
                    productUuid={selectedProduct.uuid}
                    modalProps={{
                        title: <Title order={1}>{selectedProduct.name}</Title>,
                        opened: opened,
                        onClose: close,
                        centered: true,
                        size: "90%",
                        fullScreen: true,
                    }}
                    closeCB={close}
                />
            )}
            <PaginationContainer
                countLabel={{
                    id: "app.product.total",
                    defaultMessage: "Total: {count} product(s)",
                }}
                itemsCount={responseData?.total ?? 0}
                simpleGridProps={{
                    cols: { xs: 1, md: 3 },
                    spacing: "lg",
                    verticalSpacing: "lg",
                }}
                paginationProps={{
                    total: responseData?.pages ?? 0,
                    value: activePage,
                    onChange: (value) => setPage(value),
                }}
            >
                {responseData?.items.map((product, index) => (
                    <ProductCard
                        key={index}
                        product={product}
                        viewDetailsCB={() => viewProductDetails(product)}
                    />
                ))}
                <Container ref={errorRef}></Container>
                <BarLoader visible={loaderVisible} />
            </PaginationContainer>
        </>
    );
};
