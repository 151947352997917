import { Company } from "../models/Company";
import { Membership } from "../models/Team";
import { User } from "../models/User";

export interface IProfileCalculator {
    user: User | null;
    memberships: Map<string, Membership> | undefined;
    company: Company | undefined;    
}

interface FieldCriteria {
    completionRate: (fieldValue: any) => number;
}

const dataCriteria: Record<string, FieldCriteria> = {
    user: {
        completionRate: (value) => value ? getCompletionRate(value, userCriteria) : 0,
    },
    memberships: {
        completionRate: (value) => value.size > 0 ? 100 : 0
    },
    company: {
        completionRate: (value) => value ? getCompletionRate(value, companyCriteria) : 0,
    },
};

const userProfileCriteria: Record<string, FieldCriteria> = {
    lastName: { completionRate: (value) => (value !== "" ? 100 : 0) },
    firstName: { completionRate: (value) => value !== "" ? 100 : 0},
    company: { completionRate: (value) => value !== "" ? 100 : 0 },
    job: { completionRate: (value) => value !== "" ? 100 : 0 },
    landlinePhone: { completionRate: (value) => value !== "" ? 100 : 0 },
    officePhone: { completionRate: (value) => value !== "" ? 100 : 0 },
    mobilePhone: { completionRate: (value) => value !== "" ? 100 : 0 },
    birthDate: { completionRate: (value) => value !== null ? 100 : 0 },
};

const userCriteria: Record<string, FieldCriteria> = {
    uuid: { completionRate: (value) => value !== "" ? 100 : 0 },
    email: { completionRate: (value) => /\S+@\S+\.\S+/.test(value) ? 100 : 0 },
    isVerified: { completionRate: (value) => value ? 100 : 0 },
    profile: {
        completionRate: (value) => value ? getCompletionRate(value, userProfileCriteria) : 0,
    },
    profileAddress: {
        completionRate: (value) => value ? getCompletionRate(value, profileAddressCriteria) : 0,
    },
};

const addressCriteria: Record<string, FieldCriteria> = {
    street: { completionRate: (value) => value !== "" ? 100 : 0 },
    number: { completionRate: (value) => value !== "" ? 100 : 0 },
    postCode: { completionRate: (value) => value !== "" ? 100 : 0 },
    state: { completionRate: (value) => value !== "" ? 100 : 0 },
    city: { completionRate: (value) => value !== "" ? 100 : 0 },
    country: { completionRate: (value) => value !== "" ? 100 : 0 },
};

const companyCriteria: Record<string, FieldCriteria> = {
    name: { completionRate: (value) => value !== "" ? 100 : 0 },
    identificationNumber: { completionRate: (value) => value !== "" ? 100 : 0 },
    phone: { completionRate: (value) => value !== "" ? 100 : 0 },
};

const profileAddressCriteria: Record<string, FieldCriteria> = {
    billing: {
        completionRate: (value) => value ? getCompletionRate(value, addressCriteria) : 0,
    },
};

function getCompletionRate(
    data: any,
    criteria: Record<string, FieldCriteria>
): number {
    let ratesSum = 0;
    let totalFields = Object.keys(criteria).length;
    if (totalFields === 0) {
        return totalFields;
    }

    for (const key in criteria) {
        ratesSum = ratesSum + criteria[key].completionRate(data[key]);
    }

    return ratesSum / totalFields;
}

export class ProfileCalculator {
    private _data: IProfileCalculator;

    public constructor(data: IProfileCalculator) {
        this._data = data;
    }

    public calculateCompletion(): number {

        return Number(getCompletionRate(this._data, dataCriteria).toFixed(2));
    }
}
