import classes from "./stats.module.css";
import { Progress, Box, Text, Group, SimpleGrid, Title } from "@mantine/core";
import { StatsCardProps } from "./Interfaces";

export function StatsSegments({ data, cardData }: StatsCardProps) {
    if (!data) {
        return null;
    }

    const segments = data.map((segment) => {
        if (!segment.part) {
            return null;
        }
        return (
            <Progress.Section
                value={segment.part}
                color={segment.color}
                key={segment.color}
            >
                {segment.part > 10 && (
                    <Progress.Label>{segment.part}%</Progress.Label>
                )}
            </Progress.Section>
        );
    });

    const descriptions = data.map((stat) => (
        <Box
            key={stat.label}
            style={{ borderBottomColor: stat.color }}
            className={classes.stat}
        >
            <Text tt="uppercase" fz="xs" fw={700}>
                {stat.label}
            </Text>

            <Group justify="space-between" align="flex-end" gap={0}>
                <Text fw={700}>{stat.count}</Text>
                <Text
                    c={stat.color}
                    fw={700}
                    size="sm"
                    className={classes.statCount}
                >
                    {stat.part}%
                </Text>
            </Group>
        </Box>
    ));

    return (
        <Box>
            <Group justify="space-between">
                <Group align="flex-end" gap="xs">
                    <Title fz="xl" fw={700}>
                        {cardData?.title}
                    </Title>
                </Group>
            </Group>
            <Text c="dimmed" fz="sm">
                {cardData?.text}
            </Text>
            <Progress.Root
                size={34}
                classNames={{ label: classes.progressLabel }}
                mt={40}
            >
                {segments}
            </Progress.Root>
            <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
                {descriptions}
            </SimpleGrid>
        </Box>
    );
}
