import { Expose, instanceToPlain, plainToClass } from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic } from "./Common";

@staticImplements<DtoStatic>()
export class CompanyDto {
    @Expose()
    name!: string;
    @Expose()
    chUid!: string;
    @Expose()
    phone!: string;
    @Expose()
    identificationNumber!: string;
    @Expose()
    countryISO!: string;
    @Expose()
    countryName!: string;

    static toModel(companyDto: CompanyDto): Company {
        if (companyDto === undefined) {
            return new Company();
        }
        const data = instanceToPlain(companyDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Company, data);
    }
}

export class Company {
    @Expose()
    name!: string;
    @Expose()
    chUid!: string;
    @Expose()
    phone!: string;
    @Expose()
    identificationNumber!: string;
    @Expose()
    countryISO!: string;
    @Expose()
    countryName!: string;
}
