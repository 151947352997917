import globalClasses from "../../styles/global.module.css";
import {
    Container,
    Title,
    Text,
    Group,
    Button,
    Space,
    Box,
    Paper,
    LoadingOverlay,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import heroClasses from "../../styles/hero.module.css";
import { HeroProps } from "./Interfaces";
import { Features } from "./Components";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { useRest } from "../../features/context/RestContext";
import { useState } from "react";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import { AppErrorCode } from "../../lib/Constants";
import { useData } from "../../features/context/DataContext";
import { Licence } from "../../models/Licence";

export default function HeroSection({ plan }: HeroProps) {
    const { restApiService } = useRest();
    const { setCurrentData } = useData();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const handleError = useErrorAlertHandler();
    const intl = useIntl();
    const navigate = useNavigate();

    const subscribe = async () => {
        try {
            setLoaderVisible(true);
            const data: Licence | null = await (restApiService?.subscribe(
                plan.uuid
            ) ?? null);

            if (data) {
                setCurrentData((prevData) => ({
                    ...prevData,
                    assignedLicence: { ...data },
                }));
            }
        } catch (error: any) {
            if (error.appErrorCode === AppErrorCode.EXISTING_SUBSCRIPTION) {
                openAlertModal();
            } else {
                handleError(error as Error);
            }
        } finally {
            setLoaderVisible(false);
            navigate("/subscription");
        }
    };

    const openConfirmModal = () =>
        modals.openConfirmModal({
            title: (
                <Title order={2}>
                    <FormattedMessage
                        id="app.subcription.confirm"
                        defaultMessage="Confirm subscription"
                    />
                </Title>
            ),
            centered: true,
            children: (
                <Text>
                    <FormattedMessage
                        id="app.subscription.confirm.question"
                        defaultMessage="Do you want to subscribe to this plan?"
                    />
                    <div className={globalClasses.bold}>
                        {plan.name} CHF {plan.price}.- {plan.period}
                    </div>
                </Text>
            ),
            labels: {
                confirm: intl.formatMessage({ id: "app.label.confirm" }),
                cancel: intl.formatMessage({ id: "app.label.cancel" }),
            },
            confirmProps: { color: "green" },
            onConfirm: subscribe,
        });

    const openAlertModal = () => {
        modals.open({
            title: (
                <Title order={1}>
                    <FormattedMessage
                        id="app.subscription.error"
                        defaultMessage="Subscription error!"
                    />
                </Title>
            ),
            children: (
                <>
                    <Text>
                        <FormattedMessage
                            id="app.subscription.error.alreadySubscribed"
                            defaultMessage="You are already subscribed to a plan."
                        />
                    </Text>
                    <Button
                        fullWidth
                        onClick={() => modals.closeAll()}
                        mt="md"
                        variant="filled"
                    >
                        <FormattedMessage
                            id="app.label.close"
                            defaultMessage="Close"
                        />
                    </Button>
                </>
            ),
        });
    };

    return (
        <Container size="md">
            <LoadingOverlay visible={loaderVisible} />
            <Paper withBorder p="md" radius="md">
                <div className={heroClasses.inner}>
                    <Container size="md">
                        <div className={heroClasses.content}>
                            <Title className={heroClasses.title}>
                                {plan.name}
                            </Title>
                            <Text c="dimmed" mt="md">
                                {plan.description}
                            </Text>
                            <Space h="xl" />
                            <Box ml="md">
                                <Features plan={plan} />
                            </Box>
                            <Group mt={30}>
                                <Button
                                    variant="default"
                                    radius="xl"
                                    size="md"
                                    className={heroClasses.control}
                                    component={Link}
                                    to="/subscription"
                                >
                                    <FormattedMessage
                                        id="app.subscription.backToSubscriptionsPage"
                                        defaultMessage="Back to subscriptions page"
                                    />
                                </Button>
                                <Button
                                    radius="xl"
                                    size="md"
                                    className={heroClasses.control}
                                    onClick={openConfirmModal}
                                >
                                    <FormattedMessage
                                        id="app.subscription.subscribe"
                                        defaultMessage="Subscribe"
                                    />
                                </Button>
                            </Group>
                        </div>
                    </Container>
                </div>
            </Paper>
        </Container>
    );
}
