import {
    Paper,
    TextInput,
    PasswordInput,
    Button,
    Title,
    Text,
    Stack,
    Container,
    Checkbox,
    LoadingOverlay,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { useForm, isEmail } from "@mantine/form";
import PasswordStrengthInput from "../../components/common/Inputs/PasswordStrengh";
import { useEffect, useState } from "react";
import { useAuth } from "../../features/context/AuthContext";
import classes from "./Signup.module.css";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";

function SignUp() {
    const intl = useIntl();
    const { authService, setCurrentAuth } = useAuth();
    const handleError = useErrorAlertHandler();

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            email: "",
            password: "",
            passwordConfirm: "",
            termsOfService: false,
        },
        validate: {
            email: isEmail(
                intl.formatMessage({ id: "app.formLabel.email.error" })
            ),
            passwordConfirm: (value, values) =>
                value !== values.password
                    ? intl.formatMessage({ id: "app.formLabel.password.error" })
                    : null,
            termsOfService: (value) =>
                value
                    ? null
                    : intl.formatMessage({ id: "app.acceptTerms.error" }),
        },
    });

    const handleSubmit = async () => {
        const { email, password, passwordConfirm, termsOfService } =
            form.values;
        const payload = {
            email: email.trim(),
            password: password.trim(),
            passwordConfirm: passwordConfirm.trim(),
            termsOfService,
        };
        try {
            setLoaderVisible(true);
            await authService?.signup(payload);
            setCurrentAuth({ user: null, subscriberEmail: email });
            navigate("/signup-success");
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <Container size={520}>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Title
                        style={{
                            fontWeight: 900,
                            paddingTop: 50,
                            textAlign: "center",
                        }}
                    >
                        <FormattedMessage
                            id="app.formLabel.account.create"
                            defaultMessage="Create account"
                        />
                    </Title>

                    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                        <Stack>
                            <TextInput
                                label={intl.formatMessage({
                                    id: "app.formLabel.email",
                                })}
                                error={intl.formatMessage({
                                    id: "app.formLabel.email.error",
                                })}
                                {...form.getInputProps("email")}
                                placeholder="you@mail.com"
                            />
                            <PasswordStrengthInput
                                {...form.getInputProps("password", {
                                    value: form.values.password || "",
                                })}
                            />
                            <PasswordInput
                                label={intl.formatMessage({
                                    id: "app.formLabel.password.confirm",
                                })}
                                {...form.getInputProps("passwordConfirm")}
                            />
                        </Stack>
                        <Checkbox
                            label={
                                <>
                                    <Link to={"/terms"}>
                                        <FormattedMessage
                                            id="app.acceptTerms"
                                            defaultMessage="I accept terms and conditions"
                                        />
                                    </Link>
                                </>
                            }
                            mt="sm"
                            {...form.getInputProps("termsOfService", {
                                type: "checkbox",
                            })}
                        />
                        <Button type="submit" fullWidth mt="xl">
                            <FormattedMessage
                                id="app.signup"
                                defaultMessage="Sign up"
                            />
                        </Button>
                        <Text
                            c="dimmed"
                            size="sm"
                            style={{ textAlign: "center" }}
                            mt={5}
                        >
                            <FormattedMessage
                                id="app.formLabel.account.have"
                                defaultMessage="Already have an account?"
                            />{" "}
                            <Link to="/login">
                                <FormattedMessage
                                    id="app.login"
                                    defaultMessage="Login"
                                />
                            </Link>
                        </Text>
                    </Paper>
                </form>
            </Container>
        </div>
    );
}

export default SignUp;
