import { Expose, plainToClass } from "class-transformer";

export interface Dto {}
export interface Model {}

export interface DtoStatic {
    new (): Dto;
    toModel(dto: Dto): Model;
}

export class PaginatedDto<T extends Dto> {
    @Expose()
    items!: T[];

    @Expose()
    total!: number;

    @Expose()
    pages!: number;

    static toModel<U extends Dto>(
        cls: DtoStatic & { new (): U },
        paginatedDto: PaginatedDto<U>
    ): Paginated<Model> {
        const { items, ...rest } = paginatedDto;
        let convertedItems: Model[] = [];
        if (items) {
            convertedItems = items.map((itemDto) =>
            cls.toModel(itemDto)
        );
        }

        const paginatedDtoOInstance = plainToClass(PaginatedDto, rest);
        paginatedDtoOInstance.items = convertedItems;
        return paginatedDtoOInstance;
    }
}

export class Paginated<T> {
    items!: T[];
    total!: number;
    pages!: number;
}
