import classes from "./cell.module.css";
import globalClasses from "../../styles/global.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Card, RingProgress, Text, Title } from "@mantine/core";
import { StatsSegments } from "../common/Stats/StatsSegments";
import { IRingSectionData, IStatData } from "../common/Stats/Interfaces";
import { calculatePart } from "../../lib/Utilities";
import { useData } from "../../features/context/DataContext";
import { useRest } from "../../features/context/RestContext";
import { useEffect, useState } from "react";
import { OrderStat } from "../../models/Stats";
import { BaseError } from "../../lib/Error";
import { BarLoader } from "../common/Loader";

const OrderStatCell = () => {
    const intl = useIntl();
    const { restApiService } = useRest();
    const { orderStats, setCurrentData } = useData();
    const [loading, setLoading] = useState<boolean>(false);

    const getStats = async () => {
        try {
            setLoading(true);
            const data: OrderStat | undefined =
                await restApiService?.getOrderStats();
            setCurrentData((prevData) => ({
                ...prevData,
                orderStats: data,
            }));
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setLoading(false);
        }
    };

    const calculateOtherStatusesAmount = (orderStats: OrderStat) => {
        let totalStatusesLeft =
            orderStats.total -
            (orderStats.new +
                orderStats.completed +
                orderStats.shipped +
                orderStats.cancelled);

        return totalStatusesLeft;
    };

    // const processedOrderStats: IStatData[] = [
    //     {
    //         label: intl.formatMessage({ id: "app.order.status.new" }),
    //         count: orderStats?.new ?? 0,
    //         part: calculatePart(orderStats?.new ?? 0, orderStats?.total ?? 1),
    //         color: "#2e467b",
    //     },
    //     {
    //         label: intl.formatMessage({ id: "app.order.status.shipped" }),
    //         count: orderStats?.shipped ?? 0,
    //         part: calculatePart(
    //             orderStats?.shipped ?? 0,
    //             orderStats?.total ?? 1
    //         ),
    //         color: "#667dff",
    //     },
    //     {
    //         label: intl.formatMessage({ id: "app.order.status.completed" }),
    //         count: orderStats?.completed ?? 0,
    //         part: calculatePart(
    //             orderStats?.completed ?? 0,
    //             orderStats?.total ?? 1
    //         ),
    //         color: "#1c7c54",
    //     },
    //     {
    //         label: intl.formatMessage({ id: "app.order.status.cancelled" }),
    //         count: 20,
    //         part: calculatePart(
    //             orderStats?.cancelled ?? 0,
    //             orderStats?.total ?? 1
    //         ),
    //         color: "grey",
    //     },
    //     {
    //         label: intl.formatMessage({ id: "app.label.other" }),
    //         count: orderStats?.cancelled ?? 0,
    //         part: calculatePart(
    //             orderStats ? calculateOtherStatusesAmount(orderStats) : 0,
    //             orderStats?.total ?? 1
    //         ),
    //         color: "#000505",
    //     },
    // ];
    const newOrders: number = calculatePart(orderStats?.new ?? 0, orderStats?.total ?? 1);
    const shippedOrders = calculatePart(
        orderStats?.shipped ?? 0,
        orderStats?.total ?? 1
    );
    const completedOrders: number = calculatePart(
        orderStats?.shipped ?? 0,
        orderStats?.total ?? 1
    );
    const cancelledOrders: number = calculatePart(
        orderStats?.cancelled ?? 0,
        orderStats?.total ?? 1
    );
    const otherOrders: number = calculatePart(
        orderStats ? calculateOtherStatusesAmount(orderStats) : 0,
        orderStats?.total ?? 1
    );

    const ringSections: IRingSectionData[] = [
        {
            tooltip: `${intl.formatMessage({ id: "app.order.status.new" })} ${newOrders}%`,
            value: newOrders,
            color: "#2e467b",
        },
        {
            tooltip: `${intl.formatMessage({ id: "app.order.status.shipped" })} ${shippedOrders}%`,
            value: calculatePart(
                orderStats?.shipped ?? 0,
                orderStats?.total ?? 1
            ),
            color: "#667dff",
        },
        {
            tooltip: `${intl.formatMessage({ id: "app.order.status.completed" })} ${completedOrders}%`,
            value: calculatePart(
                orderStats?.completed ?? 0,
                orderStats?.total ?? 1
            ),
            color: "#1c7c54",
        },
        {
            tooltip: `${intl.formatMessage({ id: "app.order.status.cancelled" })} ${cancelledOrders}%`,
            value: calculatePart(
                orderStats?.cancelled ?? 0,
                orderStats?.total ?? 1
            ),
            color: "grey",
        },
        {
            tooltip: `${intl.formatMessage({ id: "app.label.other" })} ${otherOrders}%`,
            value: calculatePart(
                orderStats ? calculateOtherStatusesAmount(orderStats) : 0,
                orderStats?.total ?? 1
            ),
            color: "#000505",
        },
    ];

    useEffect(() => {
        getStats();
    }, []);

    return (
        <Card className={`${classes.container} ${globalClasses.card}`}>
            <Card.Section className={classes.section}>
                <Title className={classes.title}>
                    <FormattedMessage id="app.orders" />
                </Title>
                <Text className={classes.info}>
                    <FormattedMessage id="app.order.stats.description" />
                </Text>
            </Card.Section>
            <Card.Section className={classes.section}>
                <BarLoader visible={loading} />
                {orderStats?.total ? (
                    // <StatsSegments data={processedOrderStats} />
                    <RingProgress
                        size={170}
                        thickness={16}
                        label={
                            <Text
                                size="md"
                                ta="center"
                                px="md"
                                style={{ pointerEvents: "none" }}
                            >
                                <FormattedMessage id="app.orders" />
                            </Text>
                        }
                        sections={ringSections}
                    />
                ) : (
                    <Box>
                        <FormattedMessage id="app.orders.empty" />
                    </Box>
                )}
            </Card.Section>
        </Card>
    );
};

export default OrderStatCell;
