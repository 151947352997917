import "reflect-metadata";
import ReactDOM from "react-dom/client";
import AppRoutes from "./AppRoutes";
import { AuthProvider } from "./features/context/AuthContext";
import { RestProvider } from "./features/context/RestContext";
import { DataProvider } from "./features/context/DataContext";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    <AuthProvider>
        <RestProvider>
            <DataProvider>
                <AppRoutes />
            </DataProvider>
        </RestProvider>
    </AuthProvider>
    // </React.StrictMode>
);
