import { Expose, instanceToPlain, plainToClass } from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";

@staticImplements<DtoStatic>()
export class CountryDto {
    @Expose()
    iso!: string;
    @Expose()
    name!: string;
    @Expose()
    phonecode!: number;
    @Expose()
    nicename!: string;

    static toModel(countryDto: CountryDto): Country {
        if (countryDto === undefined) {
            return new Country();
        }
        const data = instanceToPlain(countryDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Country, data);
    }
}

export class Country implements Model {
    @Expose()
    iso!: string;
    @Expose()
    name!: string;
    @Expose()
    phonecode!: number;
    @Expose()
    nicename!: string;
} 