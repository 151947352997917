import { Group, Indicator, Tooltip } from "@mantine/core";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from "../../styles/navigation.module.css";

interface NavbarLinkProps {
    icon: React.FC<any>;
    to: string;
    label: string;
    disableTooltip?: boolean;
    active?: boolean;
    notify?: boolean;
    onClick?(): void;
}

function NavbarLink({
    icon: Icon,
    to,
    label,
    disableTooltip = true,
    active,
    notify,
    onClick,
}: NavbarLinkProps) {
    const [disableIndicator, setDisableIndicator] = useState<boolean>(!notify);

    useEffect(() => {
        setDisableIndicator(!notify);
    }, [notify]);

    return (
        <div className={classes.link} data-active={active || undefined}>
            <Tooltip
                label={label}
                position="right"
                disabled={disableTooltip}
                transitionProps={{ duration: 0 }}
            >
                <Indicator
                    color="orange"
                    inline
                    processing
                    disabled={disableIndicator}
                    label={"!"}
                    size={16}
                    position="middle-end"
                >
                    <Link onClick={onClick} to={to}>
                        <Group>
                            <Icon size="1.2rem" stroke={1.5} />
                            {disableTooltip && <FormattedMessage id={label} />}
                        </Group>
                    </Link>
                </Indicator>
            </Tooltip>
        </div>
    );
}

export default NavbarLink;
