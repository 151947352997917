import DashboardContent from "../../components/Dashboard/DashboardContent";
import { PageContainer } from "../../components/common/Page";
import { useAppData } from "../../features/context/AppDataContext";

function Dashboard() {
    const { loading } = useAppData();

    return (
        <PageContainer titleId="app.dashboard" defaultTitle="Dashboard">
            {loading ? <div>Preparing data...</div> : <DashboardContent />}
        </PageContainer>
    );
}

export default Dashboard;
