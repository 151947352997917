import {
    Expose,
    Transform,
    Type,
    instanceToPlain,
    plainToClass,
} from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";
import { stringToDate } from "../lib/ConvertionUtils";
import { Subscription, SubscriptionDto } from "./Subscription";
import { User, UserDto } from "./User";
import { LicenceStatusEnum } from "./Licence";

export enum LabRoles {
    ROLE_LAB_OWNER = "ROLE_LAB_OWNER",
    ROLE_LAB_CREATOR = "ROLE_LAB_CREATOR",
    ROLE_LAB_MEMBER = "ROLE_LAB_MEMBER",
    ROLE_LAB_MEMBER_ADMIN = "ROLE_LAB_MEMBER_ADMIN",
}

enum LabRolesLabels {
    ROLE_LAB_OWNER = "app.user.role.virtualLab.owner",
    ROLE_LAB_CREATOR = "app.user.role.virtualLab.creator",
    ROLE_LAB_MEMBER = "app.user.role.virtualLab.member",
    ROLE_LAB_MEMBER_ADMIN = "app.user.role.virtualLab.admin",
}

export const LAB_ROLE_MAP = new Map<string, string>([
    [LabRoles.ROLE_LAB_OWNER, LabRolesLabels.ROLE_LAB_OWNER],
    [LabRoles.ROLE_LAB_CREATOR, LabRolesLabels.ROLE_LAB_CREATOR],
    [LabRoles.ROLE_LAB_MEMBER, LabRolesLabels.ROLE_LAB_MEMBER],
    [LabRoles.ROLE_LAB_MEMBER_ADMIN, LabRolesLabels.ROLE_LAB_MEMBER_ADMIN],
]);

export const LAB_EDITABLE_ROLE_MAP = new Map<string, string>([
    [LabRoles.ROLE_LAB_MEMBER, LabRolesLabels.ROLE_LAB_MEMBER],
    [LabRoles.ROLE_LAB_MEMBER_ADMIN, LabRolesLabels.ROLE_LAB_MEMBER_ADMIN],
]);

export const isAdmin = (roles: string[] | undefined): boolean => {
    
    if (!roles) {
        return false;
    }

    return (
        roles.includes(LabRoles.ROLE_LAB_OWNER) ||
        roles.includes(LabRoles.ROLE_LAB_CREATOR) ||
        roles.includes(LabRoles.ROLE_LAB_MEMBER_ADMIN)
    );
};

@staticImplements<DtoStatic>()
export class TeamDto {
    @Expose()
    uuid!: string;
    @Expose()
    name!: string;
    @Expose()
    @Type(() => UserDto)
    owner!: UserDto;
    @Expose()
    avatarUrl!: string | null;
    @Expose()
    description!: string;
    @Expose()
    createdAt!: string;
    @Expose()
    updatedAt!: string;
    @Expose()
    defaultAnimalType!: string;
    @Expose()
    membersCount!: number;
    @Expose()
    @Type(() => SubscriptionDto)
    subscription!: SubscriptionDto;
    
    static toModel(teamDto: TeamDto): Team {
        if (teamDto === undefined) {
            return new Team();
        }
        const data = instanceToPlain(teamDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Team, data);
    }
}

export class Team implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    name!: string;
    @Type(() => User)
    owner!: User;
    @Expose()
    avatarUrl!: string | null;
    @Expose()
    description!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    createdAt!: Date | null;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    updatedAt!: Date | null;
    @Expose()
    defaultAnimalType!: string;
    @Expose()
    membersCount!: number;
    @Expose()
    @Type(() => Subscription)
    subscription!: Subscription;
}

@staticImplements<DtoStatic>()
export class TeamMemberDto {
    @Expose()
    uuid!: string;
    @Expose()
    email!: string;
    @Expose()
    nickname!: string;
    @Expose()
    firstName!: string;
    @Expose()
    lastName!: string;
    @Expose()
    roles!: string[];
    @Expose()
    teamRoles!: string[];
    @Expose()
    isOwner!: boolean;
    @Expose()
    isCreator!: boolean;
    @Expose()
    active!: boolean;
    @Expose()
    licenceStatus!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    memberShipDate!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    lastMembershipUpdate!: string;

    static toModel(teamMemberDto: TeamMemberDto): TeamMember {
        if (teamMemberDto === undefined) {
            return new TeamMember();
        }
        const data = instanceToPlain(teamMemberDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(TeamMember, data);
    }
}

export class TeamMember implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    email!: string;
    @Expose()
    nickname!: string;
    @Expose()
    firstName!: string;
    @Expose()
    lastName!: string;
    @Expose()
    roles!: string[];
    @Expose()
    teamRoles!: string[];
    @Expose()
    isOwner!: boolean;
    @Expose()
    isCreator!: boolean;
    @Expose({ name: "active" })
    @Type(() => Boolean)
    isActive!: boolean;
    @Expose()
    licenceStatus!: LicenceStatusEnum;
    @Expose()
    memberShipDate!: Date | null;
    @Expose()
    lastMembershipUpdate!: Date | null;
}

@staticImplements<DtoStatic>()
export class MembershipDto {
    @Expose()
    active!: boolean;
    @Expose()
    createdAt!: string;
    @Expose()
    updatedAt!: string;
    @Expose()
    roles!: string[];

    static toModel(membershipDto: MembershipDto): Membership {
        if (membershipDto === undefined) {
            return new Membership();
        }
        const data = instanceToPlain(membershipDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Membership, data);
    }
}

export class Membership implements Model {
    @Expose()
    active!: boolean;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    createdAt!: Date | null;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    updatedAt!: Date | null;
    @Expose()
    roles!: string[];
}
