import { Container, Overlay, Title, Text, Button } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../features/context/AuthContext";
import { useEffect } from "react";
import classes from "./Signup.module.css";

function SignupSuccess() {
    const { subscriberEmail, setCurrentAuth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setCurrentAuth({ user: null, subscriberEmail: "" });
        };
    }, []);

    return (
        <div className={classes.hero}>
            <Overlay
                gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                opacity={1}
                zIndex={0}
            />
            <Container className={classes.container} size="md">
                <Title className={classes.title}>
                    <FormattedMessage
                        id="app.signup.success"
                        defaultMessage="You have successfully signed up."
                    />
                </Title>
                <Text className={classes.description} size="xl" mt="xl">
                    <FormattedMessage
                        id="app.signup.success.message"
                        values={{ subscriberEmail: subscriberEmail }}
                        defaultMessage="Thank you for subscribing. Please check your email {subscriberEmail} to activate your account."
                    />
                </Text>
                <Button
                    variant="filled"
                    size="xl"
                    radius="xl"
                    className={classes.control}
                    onClick={() => navigate("/login")}
                >
                    <FormattedMessage
                        id="app.button.backToLoginPage"
                        defaultMessage="Back to login page."
                    />
                </Button>
            </Container>
        </div>
    );
}

export default SignupSuccess;
