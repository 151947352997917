import { FormattedMessage } from "react-intl";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();

    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        errorMessage = error.error?.message || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === "string") {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = "Unknown error";
    }

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>
                <FormattedMessage id="app.error.unexpected" defaultMessage="" />
            </p>
            <p>
                <i>{errorMessage}</i>
            </p>
        </div>
    );
}
