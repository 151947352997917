import { plainToClass } from "class-transformer";
import { Dto, DtoStatic, PaginatedDto } from "../../models/Common";
import { NO_PARSE } from "../Constants";

/**
 * DTO to Model decorator, must be used on api methods
 *
 * @param dtoClass
 * @returns
 */
export function dtoToModel(dtoClass: DtoStatic) {
    return function (
        target: any,
        memberName: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            let results = await originalMethod.call(this, ...args);
            if (results && results.hasOwnProperty(NO_PARSE) && results[NO_PARSE] === true)
                return results;
            results = Array.isArray(results)
                ? results.map((result: any) => plainToClass(dtoClass, result))
                : plainToClass(dtoClass, results);

            return Array.isArray(results)
                ? results.map((result: any) => dtoClass.toModel(result))
                : dtoClass.toModel(results);
        };
    };
}

export function mappedDtoToModel(dtoClass: DtoStatic) {
    const convertData = ([key, value]: [string, any]) => {
        let dto = plainToClass(dtoClass, {...value});
        return [key, dtoClass.toModel(dto)];
    };
    return function (
        target: any,
        memberName: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            let results = await originalMethod.call(this, ...args);
            let resultsMap = new Map<string, any>(Object.entries(results));
            const newEntries = Array.from(resultsMap, convertData) as [string, any][];
            return new Map<string, any>(newEntries);
        };
    };

}

export function paginatedDtoToPaginatedModel<U extends Dto>(
    cls: DtoStatic & { new (): U }
): MethodDecorator {
    return (
        target: Object,
        propertyKey: string | symbol,
        descriptor: PropertyDescriptor
    ) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            const originalResult: PaginatedDto<U> = await originalMethod.apply(
                this,
                args
            );
            return PaginatedDto.toModel(cls, originalResult);
        };
    };
}

/**
 * Dto payload decorator, must be used on api methods with (uuid, payload) parameters
 *
 * @param dtoClass
 * @returns
 */
export function prepareDtoPayload(dtoClass: DtoStatic) {
    return function (
        target: any,
        memberName: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            const [uuid, payload] = args;
            const dtoPayload = plainToClass(dtoClass, { ...payload });
            args[1] = dtoPayload;

            return await originalMethod.apply(this, args);
        };
    };
}
