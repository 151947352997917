import de from "../locales/de.json";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import jp from "../locales/jp.json";

const LOCALES = {
    ENGLISH: "en",
    JAPANESE: "ja",
    FRENCH: "fr",
    GERMAN: "de",
};

const messagesMap = new Map<string, any>([
    [LOCALES.ENGLISH, en],
    [LOCALES.GERMAN, de],
    [LOCALES.FRENCH, fr],
    [LOCALES.JAPANESE, jp],
]);

export type LocaleData = {
    label: string;
    value: string;
};

const localesData = [
    { label: "app.language.english", value: LOCALES.ENGLISH },
    { label: "app.language.german", value: LOCALES.GERMAN },
    { label: "app.language.french", value: LOCALES.FRENCH },
    { label: "app.language.japanese", value: LOCALES.JAPANESE },
];

type i18nConfig = {
    defaultLocale: string;
    supportedLocales: string[];
};

class LocalesService {
    private readonly defaultLocale: string;
    private readonly supportedLocales: string[];

    constructor(config: i18nConfig) {
        this.defaultLocale = config.defaultLocale;
        this.supportedLocales = config.supportedLocales;
    }

    getSupportedLocales(): string[] {
        return this.supportedLocales;
    }

    getLocalesData(): LocaleData[] {
        return localesData;
    }

    getLocaleLabel(locale: string): string {
        const localeData = localesData.find((data) => data.value === locale);
        return localeData ? localeData.label : "";
    }

    getDefaultLocale(): string {
        return this.defaultLocale;
    }

    getCurrentLocale(): string {
        const currLocale = navigator.language.substring(0, 2).toLowerCase();

        return this.supportedLocales.includes(currLocale)
            ? currLocale
            : this.defaultLocale;
    }

    getMessages(lang: string): any {
        if (this.supportedLocales.includes(lang)) {
            return messagesMap.get(lang);
        }
        return messagesMap.get(this.defaultLocale);
    }
}

export default new LocalesService({
    defaultLocale: LOCALES.ENGLISH,
    supportedLocales: Object.values(LOCALES),
});
