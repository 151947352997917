import { Button, LoadingOverlay, Paper, Space, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import VirtualLabEditGrid from "../../components/VirtualLab/Forms/VirtualLabForm";
import { useRef, useState } from "react";
import { formularRef } from "../../components/common/Wrapper";
import globalClasses from "../../styles/global.module.css";

function NewVirtualLabPage() {
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const formRef = useRef<formularRef>(null);

    const handleSubmitAction = () => {
        formRef.current?.handleSubmit();
    };

    return (
        <>
            <Title className={globalClasses.title}>
                <FormattedMessage
                    id="app.virtualLab.create"
                    defaultMessage="Create new team"
                />
            </Title>
            <Space h="xl" />
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <Paper className={globalClasses.container}>
                <VirtualLabEditGrid
                    editMode={false}
                    ref={formRef}
                    setLoaderVisibleCB={setLoaderVisible}
                />
                <Button
                    variant="filled"
                    color="green"
                    size="md"
                    radius="md"
                    onClick={handleSubmitAction}
                >
                    <FormattedMessage
                        id="app.virtualLab.create"
                        defaultMessage="Create new Virtual Lab"
                    />
                </Button>
            </Paper>
            <Button component={Link} to="/team" variant="default" mt="md">
                <FormattedMessage
                    id="app.button.backToList"
                    defaultMessage="Back to list"
                />
            </Button>
        </>
    );
}

export default NewVirtualLabPage;
