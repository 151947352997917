import { Box, Text, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import gridClasses from "./VirtualLabGrid.module.css";
import { useTeamData } from "../../../features/context/TeamContext";
import { useEffect } from "react";

function VirtualLabReadOnlyGrid() {

    const { currentTeam } = useTeamData();

    useEffect(() => {}, [currentTeam]);
    
    return (
        <>
            <Title className={`${gridClasses.mainTitle} ${gridClasses.title}`}>
                <FormattedMessage
                    id="app.virtualLab"
                    defaultMessage="Virtual Lab"
                />{" "}
                {currentTeam?.name}
                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                    <FormattedMessage
                        id="app.virtualLab.members.total"
                        defaultMessage="Total: {count} member(s)"
                        values={{ count: currentTeam?.membersCount }}
                    />
                </Text>
            </Title>
            <Box className={gridClasses.container}>
                <Box
                    style={{
                        marginBottom: 50,
                    }}
                >
                    <Title
                        className={`${gridClasses.sectionTitle} ${gridClasses.title}`}
                        order={2}
                    >
                        <FormattedMessage
                            id="app.virtualLab.whatWeDo"
                            defaultMessage="What we do"
                        />
                    </Title>
                    <Box
                        className={gridClasses.content}
                        dangerouslySetInnerHTML={{ __html: currentTeam?.description ?? ""}}
                    />
                </Box>
                <Box
                    style={{
                        marginBottom: 50,
                    }}
                >
                    <Title
                        className={`${gridClasses.sectionTitle} ${gridClasses.title}`}
                        order={2}
                    >
                        <FormattedMessage
                            id="app.virtualLab.owner"
                            defaultMessage="Lab Owner"
                        />
                    </Title>
                    <Text className={gridClasses.content} size="lg">
                        {currentTeam?.owner?.nickname} ({currentTeam?.owner?.email})
                    </Text>
                </Box>
                <Box
                    style={{
                        marginBottom: 50,
                    }}
                >
                    <Title
                        className={`${gridClasses.sectionTitle} ${gridClasses.title}`}
                        order={2}
                    >
                        <FormattedMessage
                            id="app.virtualLab.defaultAnimalType"
                            defaultMessage="Default animal type"
                        />
                    </Title>
                    <Text className={gridClasses.content} size="lg">
                        {currentTeam?.defaultAnimalType}
                    </Text>
                </Box>
            </Box>
        </>
    );
}

export default VirtualLabReadOnlyGrid;
