import globalClasses from "../../../styles/global.module.css";
import { Button, Stack, Text } from "@mantine/core";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
    useShoppingCart,
} from "../../../features/context/ShoppingCartContext";
import { FC, PropsWithChildren } from "react";
import { CartElementProps } from "./Interfaces";
import { FormattedMessage } from "react-intl";

const Checkout = ({ closeCB }: CartElementProps) => {
    const stripePublicKey = import.meta.env.VITE_STRIPE_PKEY;
    const stripePromise = loadStripe(stripePublicKey);
    const { stripeCliSecret } = useShoppingCart();

    const options: StripeElementsOptions = {
        clientSecret: stripeCliSecret,
    };

    if (!stripeCliSecret) {
        return (
            <ErrorDisplay>
                <Button variant="filled" color="red" onClick={closeCB}>
                <FormattedMessage
                        id="app.label.close"
                        defaultMessage="Close"
                    />
                </Button>
            </ErrorDisplay>
        );
    }

    return (
        <Stack>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ ...options, onComplete: closeCB }}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </Stack>
    );
};

const ErrorDisplay: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Stack className={globalClasses.container}>
            <Text>
                <FormattedMessage
                    id="app.order.checkout.error"
                    defaultMessage="An error occurred while processing your payment."
                />
            </Text>
            {children}
        </Stack>
    );
};

export default Checkout;
