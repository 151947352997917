import { useData } from "../context/DataContext";

export const useLabCountHandler = () => {
    const { assignedLicence, setCurrentData } = useData();

    const handleLabCount = (labs: number = 0) => {

        if (labs === 0) {
            return;
        }

        if (assignedLicence) {
            assignedLicence.subscription.labsCount += labs;
            setCurrentData((prevData) => ({
                ...prevData,
                assignedLicence: assignedLicence,
            }));
        }
    };

    return handleLabCount;
}
