import { dateToString, stringToDate } from "../lib/ConvertionUtils";
import {
    Type,
    Transform,
    Expose,
    plainToClass,
    instanceToPlain,
} from "class-transformer";
import { DtoStatic, Model } from "./Common";
import { staticImplements } from "../lib/decorators/Common";
import { ProfileAddress } from "./Contact";

export enum Roles {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_USER = "ROLE_USER",
    ROLE_SUPER_USER = "ROLE_SUPER_USER",
    ROLE_SUPPORT = "ROLE_SUPPORT",
    ROLE_GUEST = "ROLE_GUEST",
    ROLE_LAB_ADMIN = "ROLE_LAB_ADMIN",
}

enum RolesLabels {
    ROLE_ADMIN = "app.user.role.admin",
    ROLE_USER = "app.user.role.user",
    ROLE_SUPER_USER = "app.user.role.superUser",
    ROLE_SUPPORT = "app.user.role.support",
    ROLE_GUEST = "app.user.role.guest",
    ROLE_LAB_ADMIN = "app.user.role.virtualLabAdmin",
}

export const ROLE_MAP = new Map<string, string>([
    [Roles.ROLE_ADMIN, RolesLabels.ROLE_ADMIN],
    [Roles.ROLE_LAB_ADMIN, RolesLabels.ROLE_LAB_ADMIN],
    [Roles.ROLE_USER, RolesLabels.ROLE_USER],
    [Roles.ROLE_SUPER_USER, RolesLabels.ROLE_SUPER_USER],
    [Roles.ROLE_SUPPORT, RolesLabels.ROLE_SUPPORT],
    [Roles.ROLE_GUEST, RolesLabels.ROLE_GUEST],
]);

function roleToReadable(roles: string[]): string[] {
    if (!roles) {
        return [];
    }
    return roles.map((role) => ROLE_MAP.get(role) || role);
}

@staticImplements<DtoStatic>()
export class UserProfileDto {
    @Expose()
    lastName!: string;
    @Expose()
    firstName!: string;    
    @Expose()
    avatarUrl!: string | null;
    @Expose()
    job!: string;
    @Expose()
    landlinePhone!: string;
    @Expose()
    officePhone!: string;
    @Expose()
    mobilePhone!: string;
    @Expose()
    nickname!: string;
    @Expose()
    @Transform((data) => dateToString(data.value))
    birthDate!: string | null;
    @Expose()
    transactionsCurrencyCode!: string;

    static toModel(userProfileDto: UserProfileDto): UserProfile {
        if (userProfileDto === undefined) {
            return new UserProfile();
        }
        const data = instanceToPlain(userProfileDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(UserProfile, data);
    }
}

@staticImplements<DtoStatic>()
export class UserDto {
    @Expose()
    uuid!: string;
    @Expose()
    created_at!: string;
    @Expose()
    nickname!: string;
    @Expose()
    isVerified!: boolean;
    @Expose()
    email!: string;
    @Expose()
    roles!: string[];
    @Expose()
    locale!: string;
    @Expose()
    @Type(() => UserProfileDto)
    profile!: UserProfileDto | null;
    @Expose({ name: "rolesLabelized" })
    getRolesLabelized(): string[] {
        return this.roles;
    }

    static toModel(userDto: UserDto): User {
        if (userDto === undefined) {
            return new User();
        }
        const data = instanceToPlain(userDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(User, data);
    }
}

export class UserProfile implements Model {
    @Expose()
    lastName!: string;
    @Expose()
    firstName!: string;
    @Expose()
    avatarUrl!: string | null;
    @Expose()
    job!: string;
    @Expose()
    landlinePhone!: string;
    @Expose()
    officePhone!: string;
    @Expose()
    mobilePhone!: string;
    @Expose()
    nickname!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    birthDate!: Date | null;
    @Expose()
    transactionsCurrencyCode!: string;
}

export class User implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, false))
    created_at!: Date;
    @Expose()
    nickname!: string;
    @Expose()
    isVerified!: boolean;
    @Expose()
    email!: string;
    @Expose()
    roles!: string[];
    @Expose()
    locale!: string;
    @Expose()
    @Transform((data) => roleToReadable(data.value))
    rolesLabelized!: string[];
    @Expose()
    @Type(() => UserProfile)
    profile!: UserProfile | null;
    @Type(() => ProfileAddress)
    profileAddress!: ProfileAddress | null | undefined;

    public hasRole(role: string): boolean {
        return this.roles.includes(role);
    }
}
