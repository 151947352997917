import { Expose, instanceToPlain, plainToClass } from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";

@staticImplements<DtoStatic>()
export class MediaDto {
    @Expose()
    relatedUuid!: string;
    @Expose()
    uuid!: string;
    @Expose()
    url!: string;
    @Expose()
    type!: number;
    @Expose()
    format!: string;
    @Expose()
    width!: string;
    @Expose()
    height!: string;
    @Expose()
    filePath!: string;

    static toModel(mediaDto: MediaDto): Media {
        if (mediaDto === undefined) {
            return new Media();
        }
        const data = instanceToPlain(mediaDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Media, data);
    }
}

export class Media implements Model {
    @Expose()
    relatedUuid!: string;
    @Expose()
    uuid!: string;
    @Expose()
    url!: string;
    @Expose()
    type!: number;
    @Expose()
    format!: string;
    @Expose()
    width!: string;
    @Expose()
    height!: string;
    @Expose()
    filePath!: string;
}