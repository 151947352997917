import { Box, Group, Paper } from "@mantine/core";
import { PageContainer } from "../../components/common/Page";
import { LangSelector } from "../../components/common/Selector";
import { FormattedMessage } from "react-intl";

function Settings() {
    return (
        <PageContainer titleId="app.settings" defaultTitle="Settings">
            <Box>
                <Paper
                    radius="md"
                    withBorder
                    p="lg"
                    bg="var(--mantine-color-body)"
                >
                        <Group>
                            <FormattedMessage
                                id="app.settings.language"
                                defaultMessage="Language"
                            />
                            <LangSelector />
                        </Group>
                </Paper>
            </Box>
        </PageContainer>
    );
}

export default Settings;
