import classes from "./Account.module.css";
import { Badge, LoadingOverlay, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionsWrapper, formularRef } from "../common/Wrapper";
import UserReadOnlyGrid from "./Details/UserGrid";
import AddressReadOnlyGrid from "./Details/AddressGrid";
import { useAuth } from "../../features/context/AuthContext";
import { useRest } from "../../features/context/RestContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import UserEditGrid from "./Forms/UserForm";
import AddressEditGrid from "./Forms/AddressForm";
import { ProfileAddress } from "../../models/Contact";
import { User } from "../../models/User";
import { useData } from "../../features/context/DataContext";
import { Company } from "../../models/Company";
import CompanyEditGrid from "./Forms/CompanyForm";
import CompanyReadOnlyGrid from "./Details/CompanyGrid";
import { isEmptyObject } from "../../lib/Utilities";

export function UserProfileInfo() {
    const formRef = useRef<formularRef>(null);
    const intl = useIntl();
    const { restApiService } = useRest();
    const handleSubmit = () => {
        formRef.current?.handleSubmit();
    };
    const handleError = useErrorAlertHandler();
    const { user, setCurrentAuth } = useAuth();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    if (!user) return <div>No user available</div>;

    const deleteProfile = async (): Promise<void> => {
        try {
            setLoaderVisible(true);
            await restApiService?.deleteProfile();
            user.profile = null;
            user.profileAddress = null;

            setCurrentAuth((prevData) => ({
                ...prevData,
                user,
            }));
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: intl.formatMessage({ id: "app.label.areYouSure" }),
            centered: true,
            children: (
                <Text size="sm">
                    <FormattedMessage id="app.message.profileDeleteWarning" />
                </Text>
            ),
            labels: {
                confirm: intl.formatMessage({ id: "app.label.yesDelete" }),
                cancel: intl.formatMessage({ id: "app.label.doNotDelete" }),
            },
            confirmProps: { color: "red" },
            onConfirm: deleteProfile,
        });

    const updateUserInfo = (user: User): void => {
        setCurrentAuth((prevData) => ({
            ...prevData,
            user: user,
        }));
    };

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <ActionsWrapper
                titleId="app.profile.userInfo"
                titleDefault="User Information"
                formular={
                    <UserEditGrid
                        user={user}
                        ref={formRef}
                        updateUser={updateUserInfo}
                        setLoaderVisibleCallback={setLoaderVisible}
                    />
                }
                container={<UserReadOnlyGrid user={user} />}
                handleFormSubmit={handleSubmit}
                handleDelete={user.profile ? openDeleteModal : undefined}
            />
            {!user.profile && (
                <Tooltip
                    label={intl.formatMessage(
                        {
                            id: "app.label.useEditButton",
                        },
                        { button: <IconEdit size={20} /> }
                    )}
                >
                    <Badge className={classes.animatedBadge} size="xl">
                        <FormattedMessage
                            id="app.profile.notConfiguredMessage"
                            defaultMessage="Your profile is not configured yet"
                        />
                    </Badge>
                </Tooltip>
            )}
        </>
    );
}

export function AddressInfo() {
    const formRef = useRef<formularRef>(null);
    const handleSubmit = () => {
        formRef.current?.handleSubmit();
    };
    const { user, setCurrentAuth } = useAuth();
    const handleError = useErrorAlertHandler();
    const intl = useIntl();
    const { restApiService } = useRest();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    if (!user) return <div>No user available</div>;

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: intl.formatMessage({ id: "app.label.areYouSure" }),
            centered: true,
            children: (
                <Text size="sm">
                    <FormattedMessage id="app.message.addressDeleteWarning" />
                </Text>
            ),
            labels: {
                confirm: intl.formatMessage({ id: "app.label.yesDelete" }),
                cancel: intl.formatMessage({ id: "app.label.doNotDelete" }),
            },
            confirmProps: { color: "red" },
            onConfirm: deleteAddress,
        });

    const deleteAddress = async (): Promise<void> => {
        try {
            setLoaderVisible(true);
            await restApiService?.deleteProfileAddress();
            user.profileAddress = null;

            setCurrentAuth((prevData) => ({
                ...prevData,
                user: user,
            }));
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    const updateAddressData = async (
        profileAddress: ProfileAddress
    ): Promise<void> => {
        user.profileAddress = { ...profileAddress };
        setCurrentAuth((prevData) => ({
            ...prevData,
            user: user,
        }));
    };

    const tooltipLabel = user.profile
        ? intl.formatMessage(
              {
                  id: "app.label.useEditButton",
              },
              { button: <IconEdit size={20} /> }
          )
        : intl.formatMessage({ id: "app.label.editProfileFirst" });

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <ActionsWrapper
                titleId="app.profile.contactInfo"
                titleDefault="Contact information"
                formular={
                    <AddressEditGrid
                        user={user}
                        ref={formRef}
                        updateAddress={updateAddressData}
                        setLoaderVisibleCallback={setLoaderVisible}
                    />
                }
                container={
                    <AddressReadOnlyGrid profileAddress={user.profileAddress} />
                }
                handleFormSubmit={
                    user.profile != null ? handleSubmit : undefined
                }
                handleDelete={
                    user.profileAddress?.billing ? openDeleteModal : undefined
                }
            />
            {!user.profileAddress?.billing && (
                <Tooltip label={tooltipLabel}>
                    <Badge
                        className={classes.animatedBadge}
                        size="xl"
                        color="red"
                    >
                        <FormattedMessage
                            id="app.profile.notConfiguredAddress"
                            defaultMessage="Please edit your address."
                        />
                    </Badge>
                </Tooltip>
            )}
        </>
    );
}

export function CompanyInfo() {

    const intl = useIntl();
    const { company, setCurrentData } = useData();
    const { restApiService } = useRest();
    const handleError = useErrorAlertHandler();
    const formRef = useRef<formularRef>(null);
    const handleSubmit = () => {
        formRef.current?.handleSubmit();
    };
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const updateCompanyData = async (company: Company): Promise<void> => {
        setCurrentData((prevData) => ({
            ...prevData,
            company: company,
        }));
    };

    const openDeleteModal = () => modals.openConfirmModal({
        title: intl.formatMessage({ id: "app.label.areYouSure" }),
        centered: true,
        children: (
            <Text size="sm">
                <FormattedMessage id="app.message.companyDeleteWarning" />
            </Text>
        ),
        labels: {
            confirm: intl.formatMessage({ id: "app.label.yesDelete" }),
            cancel: intl.formatMessage({ id: "app.label.doNotDelete" }),
        },
        confirmProps: { color: "red" },
        onConfirm: deleteCompany,
    });

    const deleteCompany = async (): Promise<void> => {
        try {
            setLoaderVisible(true);
            await restApiService?.deleteCompany();
            setCurrentData((prevData) => ({
                ...prevData,
                company: undefined,
            }));

        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    useEffect(() => {
       
    }, [company]);

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <ActionsWrapper
                titleId="app.company"
                titleDefault="Company"
                formular={
                    <CompanyEditGrid
                        company={company ?? new Company()}
                        ref={formRef}
                        updateCompany={updateCompanyData}
                        setLoaderVisibleCallback={setLoaderVisible}
                    />
                }
                container={<CompanyReadOnlyGrid company={company ?? new Company()} />}
                handleFormSubmit={handleSubmit}
                handleDelete={
                    company && !isEmptyObject(company)
                        ? openDeleteModal
                        : undefined
                }
            />
        </>
    );
}
