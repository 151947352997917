import { useContext, useRef, useState } from "react";
import { Box, Loader, Select, Stack } from "@mantine/core";
import { IntlContext } from "../../features/context/IntlContext";
import localesService, { LocaleData } from "../../services/i18n";
import { useIntl } from "react-intl";
import { useRest } from "../../features/context/RestContext";
import globalClasses from "../../styles/global.module.css";


export function LangSelector() {
    const { locale, setIntlData } = useContext(IntlContext);
    const intl = useIntl();
    const errorRef = useRef<HTMLDivElement>(null);
    const { restApiService } = useRest();
    const data: LocaleData[] = localesService.getLocalesData();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const labelizedData = data.map((localeData) => {
        return {
            ...localeData,
            label: intl.formatMessage({ id: localeData.label }),
        };
    });

    const handleChange = async (value: string | null) => {
        if (value === null) return;
        setLoaderVisible(true);
        try {
            await restApiService?.setUserLocale(value);
        } catch (error) {
            // showError();
            console.error(error);
        } finally {
            setIntlData({ locale: value });
            setLoaderVisible(false);
        }
    };

    return (
        <Stack>
            <Select
                label={intl.formatMessage({ id: "app.language.select" })}
                data={labelizedData}
                defaultValue={locale}
                onChange={(_value) => handleChange(_value)}
                disabled={loaderVisible}
            />
            {loaderVisible && <Loader size={15} color="gray" />}
            <Box ref={errorRef} className={globalClasses.error}></Box>
        </Stack>
    );
}
