import { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Badge, Group, Table, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
    Licence,
    LicenceRoleEnum,
    LicenceStatusEnum,
} from "../../models/Licence";
import { ConfirmModal } from "../../components/common/Modal";
import { useRest } from "../../features/context/RestContext";
import { BaseError } from "../../lib/Error";
import LicenceActions from "./LicenceActions";
import { FormSubmitContext } from "../../features/context/FormSubmitContext";
import { UserCell } from "../common/User";
import { BarLoader } from "../common/Loader";
import { ServerResponseType } from "../../lib/Constants";
import { modals } from "@mantine/modals";
import { IconAlertTriangle } from "@tabler/icons-react";
import { ModalSettings } from "@mantine/modals/lib/context";

interface LicenceTableProps {
    licences: Licence[];
    isManagerTable?: boolean;
}

const LicenceTable = ({ licences, isManagerTable }: LicenceTableProps) => {
    const intl = useIntl();
    const { restApiService } = useRest();
    const { handleFormSubmitted } = useContext(FormSubmitContext);
    const [selectedLicence, setSelectedLicence] = useState<Licence | null>(
        null
    );
    const [selectedRole, setSelectedRole] = useState<LicenceRoleEnum | null>(
        null
    );
    const [confirmPauseModalOpened, confirmPauseHandlers] =
        useDisclosure(false);
    const [confirmResumeModalOpened, confirmResumeHandlers] =
        useDisclosure(false);
    const [confirmDeleteModalOpened, confirmDeleteHandlers] =
        useDisclosure(false);
    const [confirmRoleUpdateModalOpened, confirmRoleUpdateHandlers] =
        useDisclosure(false);

    const getWarnModalPayload = (
        email: string,
        teamName: string
    ): ModalSettings => {
        return {
            title: (
                <Group>
                    <IconAlertTriangle color="orange" size={48} />
                    <Title order={1}>
                        <FormattedMessage
                            id="app.label.warning"
                            defaultMessage="Warning"
                        />
                    </Title>
                </Group>
            ),
            children: (
                <FormattedMessage
                    id="app.licence.warning.alreadyOwnerOfLab"
                    values={{
                        userEmail: email,
                        labName: teamName,
                    }}
                />
            ),
        };
    };

    const checkforResponseWarning = (response: any) => {
        if (!selectedLicence) return;

        if (response && response.type && response.data) {
            if (response.type === ServerResponseType.WARNING) {
                modals.open(
                    getWarnModalPayload(
                        selectedLicence.assignee?.email,
                        response.data.team.name
                    )
                );
            }
        } else {
            handleFormSubmitted();
        }
    };

    const onModalAbort = (handler: any) => {
        setSelectedLicence(null);
        setSelectedRole(null);
        handler.close();
    }

    const openConfirmModal = (licence: Licence, handler: any) => {
        setSelectedLicence(licence);
        handler.open();
    }

    const openUpdateRoleConfirmModal = (
        licence: Licence,
        role: LicenceRoleEnum
    ) => {
        setSelectedLicence(licence);
        setSelectedRole(role);
        confirmRoleUpdateHandlers.open();
    };

    const deleteLicence = async () => {
        if (!selectedLicence) {
            console.error("deleteLicence: selectedLicence is null");
            return;
        }
        try {
            confirmDeleteHandlers.close();

            const response = await restApiService?.deleteLicence(
                selectedLicence.uuid
            );
            checkforResponseWarning(response);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setSelectedLicence(null);
        }
    };

    const updateLicenceRole = async () => {
        if (!selectedLicence || !selectedRole) {
            console.error(
                "updateLicenceRole: selectedLicence or selectedRole is null"
            );
            return;
        }
        try {
            confirmRoleUpdateHandlers.close();
            const response = await restApiService?.updownLicenceRole(
                selectedLicence.uuid,
                selectedRole
            );
            checkforResponseWarning(response);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setSelectedLicence(null);
        }
    };

    const resumeLicence = async () => {
        if (!selectedLicence) {
            console.error("resumeLicence: selectedLicence is null");
            return;
        }
        try {
            confirmResumeHandlers.close();
            const response = await restApiService?.updateLicenceStatus(
                selectedLicence.uuid,
                LicenceStatusEnum.ACTIVE
            );
            checkforResponseWarning(response);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setSelectedLicence(null);
        }
    };

    const pauseLicence = async () => {
        if (!selectedLicence) {
            console.error("pauseLicence: selectedLicence is null");
            return;
        }
        try {
            confirmPauseHandlers.close();

            const response = await restApiService?.updateLicenceStatus(
                selectedLicence.uuid,
                LicenceStatusEnum.PAUSED
            );
            checkforResponseWarning(response);
        } catch (error: any) {
            const err = error as BaseError;
        } finally {
            setSelectedLicence(null);
        }
    };

    const rows = licences.map((licence, index) => (
        <Table.Tr key={index}>
            <Table.Td>
                {isManagerTable ? (
                    licence.assignee ? (
                        <UserCell
                            user={licence.assignee}
                            isAdmin={licence.roles.some(
                                (role) =>
                                    role.value === LicenceRoleEnum.ADMIN_ROLE
                            )}
                        />
                    ) : (
                        <UserCell email={licence.pendingEmail} />
                    )
                ) : (
                    <UserCell user={licence.subscription?.owner} />
                )}
            </Table.Td>
            <Table.Td>{licence.licenceKey}</Table.Td>
            <Table.Td>
                {licence.status ? (
                    <Badge fullWidth color={licence.status.color}>
                        <FormattedMessage id={licence.status.label} />
                    </Badge>
                ) : (
                    <Badge fullWidth color="gray">
                        <FormattedMessage id="app.label.none" />
                    </Badge>
                )}
            </Table.Td>
            <Table.Td>
                <Text>{licence.createdAt?.toLocaleString()}</Text>
            </Table.Td>
            <Table.Td>
                {licence.martaSerial ??
                    intl.formatMessage({ id: "app.label.none" })}
            </Table.Td>
            {isManagerTable && (
                <Table.Td>
                    <Group>
                        <LicenceActions
                            key={index}
                            licence={licence}
                            disableButtons={
                                selectedLicence?.uuid === licence.uuid
                            }
                            pauseActionCB={() => openConfirmModal(licence, confirmPauseHandlers)}
                            updateRoleActionCB={openUpdateRoleConfirmModal}
                            resumeActionCB={() => openConfirmModal(licence, confirmResumeHandlers)}
                            deleteActionCB={() => openConfirmModal(licence, confirmDeleteHandlers)}
                        />
                        <BarLoader
                            visible={selectedLicence?.uuid === licence.uuid}
                            color="blue"
                            size={20}
                        />
                    </Group>
                </Table.Td>
            )}
        </Table.Tr>
    ));

    return (
        <>
            {isManagerTable && selectedLicence !== null ? (
                <>
                    <ConfirmModal
                        title={intl.formatMessage({
                            id: "app.label.areYouSure",
                        })}
                        message={intl.formatMessage(
                            { id: "app.licence.pauseWarningMessage" },
                            { licenceKey: selectedLicence.licenceKey }
                        )}
                        opened={confirmPauseModalOpened}
                        close={() => onModalAbort(confirmPauseHandlers)}
                        launchAction={pauseLicence}
                    />
                    <ConfirmModal
                        title={intl.formatMessage({
                            id: "app.label.areYouSure",
                        })}
                        message={intl.formatMessage(
                            { id: "app.licence.resumeWarningMessage" },
                            { licenceKey: selectedLicence.licenceKey }
                        )}
                        opened={confirmResumeModalOpened}
                        close={() => onModalAbort(confirmResumeHandlers)}
                        launchAction={resumeLicence}
                    />
                    <ConfirmModal
                        title={intl.formatMessage({
                            id: "app.label.areYouSure",
                        })}
                        message={intl.formatMessage(
                            { id: "app.licence.deleteWarningMessage" },
                            {
                                licenceKey: selectedLicence.licenceKey,
                                assigneeEmail:
                                    selectedLicence.assignee?.email ?? "none",
                            }
                        )}
                        opened={confirmDeleteModalOpened}
                        close={() => onModalAbort(confirmDeleteHandlers)}
                        launchAction={deleteLicence}
                    />
                    <ConfirmModal
                        title={intl.formatMessage({
                            id: "app.label.areYouSure",
                        })}
                        message={intl.formatMessage(
                            { id: "app.licence.updateRoleMessage" },
                            {
                                licenceKey: selectedLicence.licenceKey,
                                assigneeEmail:
                                    selectedLicence.assignee?.email ?? "none",
                                role: selectedRole,
                            }
                        )}
                        opened={confirmRoleUpdateModalOpened}
                        close={() => onModalAbort(confirmRoleUpdateHandlers)}
                        launchAction={updateLicenceRole}
                    />
                </>
            ) : (
                <></>
            )}
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>
                                {isManagerTable ? (
                                    <FormattedMessage
                                        id="app.licence.assignee"
                                        defaultMessage="Assignee"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="app.owner"
                                        defaultMessage="Owner"
                                    />
                                )}
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.licence.key"
                                    defaultMessage="Licence key"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.status"
                                    defaultMessage="Status"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.label.date.creation"
                                    defaultMessage="Creation date"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.marta.serial"
                                    defaultMessage="Marta serial"
                                />
                            </Table.Th>
                            {isManagerTable && (
                                <Table.Th>
                                    <FormattedMessage
                                        id="app.action"
                                        defaultMessage="Action"
                                    />
                                </Table.Th>
                            )}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </>
    );
};

export default LicenceTable;
