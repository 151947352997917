import { useEffect, useRef, useState } from "react";
import { Order } from "../../../models/Order";
import { useRest } from "../../../features/context/RestContext";
import { Paginated } from "../../../models/Common";
import { Box, Container } from "@mantine/core";
import PaginationContainer from "../../common/Pagination";
import OrdersTable from "./OrdersTable";
import { useIntl } from "react-intl";
import { BarLoader } from "../../common/Loader";
import globalClasses from "../../../styles/global.module.css";

const OrdersList = () => {
    const [activePage, setPage] = useState<number>(1);
    const errorRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const { restApiService } = useRest();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const ordersPerPage: number = 6;
    const [responseData, setResponseData] = useState<Paginated<Order>>();

    const fetchOrders = async () => {
        try {
            setLoaderVisible(true);
            const data = await (restApiService?.getUserOrderList(
                activePage,
                ordersPerPage
            ) ?? new Paginated<Order>());
            setResponseData(data);
        } catch (error: any) {
            errorRef.current!.innerHTML = intl.formatMessage({ id: error.message, defaultMessage: error.message});
        } finally {
            setLoaderVisible(false);
        }
    };
    useEffect(() => {
        fetchOrders();
    }, [activePage]);

    return (
        <Box className={globalClasses.container}>
            <PaginationContainer
                countLabel={{
                    id: "app.order.total",
                    defaultMessage: "Total: {count} order(s)",
                }}
                itemsCount={responseData?.total ?? 0}
                simpleGridProps={{
                    cols: 1,
                    spacing: "lg",
                    verticalSpacing: "lg",
                }}
                paginationProps={{
                    total: responseData?.pages ?? 0,
                    value: activePage,
                    onChange: (value) => setPage(value),
                }}
            >
                <OrdersTable orders={responseData?.items ?? []} />
                <Container ref={errorRef}></Container>
                <BarLoader visible={loaderVisible} />
            </PaginationContainer>
        </Box>
    );
};

export default OrdersList;
