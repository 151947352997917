import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Group, LoadingOverlay, Space, Text } from "@mantine/core";
import { ItemCard } from "../../components/common/ItemCard/ItemCard";
import { useRest } from "../../features/context/RestContext";
import { useAuth } from "../../features/context/AuthContext";
import { Team } from "../../models/Team";
import { Paginated } from "../../models/Common";
import PaginationContainer from "../../components/common/Pagination";
import { Link } from "react-router-dom";
import { PageContainer } from "../../components/common/Page";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import {
    Action,
    Feature,
    useUserFeatureAccessHandler,
} from "../../features/hooks/useUserFeatureAccessHandler";
import { useData } from "../../features/context/DataContext";
import { ISubscriptionStats, useSubscriptionStats } from "../../features/hooks/useSubscriptionStats";
import LinePlot from "../../components/common/Charts/Line";

function VirtualLabListPage() {
    // TODO: put that is env
    const labsPerPage: number = 6;
    const { restApiService } = useRest();
    const { user } = useAuth();
    const { assignedLicence } = useData();
    const handleError = useErrorAlertHandler();
    const { isAccessAllowed } = useUserFeatureAccessHandler();
    const { labs }: ISubscriptionStats = useSubscriptionStats();

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [activePage, setPage] = useState<number>(1);
    const [responseData, setResponseData] = useState<Paginated<Team>>();
    const [maxAllowedLabs, setMaxAllowedLabs] = useState<number>(labs.left);

    const getVirtualLabList = async () => {
        if (user && assignedLicence?.subscription?.uuid) {
            try {
                setLoaderVisible(true);
                const data =
                    (await restApiService?.getSubscriptionTeamList(
                        assignedLicence.subscription.uuid,
                        activePage,
                        labsPerPage
                    )) ?? new Paginated<Team>();
                setResponseData(data);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    useEffect(() => {
        getVirtualLabList();
    }, [activePage]);

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <PageContainer
                titleId="app.virtualLab.list"
                defaultTitle="Labs list"
            >
                {isAccessAllowed(Feature.VirtualLab, Action.Create) && (
                    <>
                        <Group justify="left">
                            <Button
                                component={Link}
                                to="/team/new"
                                variant="filled"
                                color="green"
                                disabled={maxAllowedLabs <= 0}
                            >
                                <FormattedMessage
                                    id="app.virtualLab.create"
                                    defaultMessage="Create new Virtual Lab"
                                />
                            </Button>
                            <Text>
                                {maxAllowedLabs > 0 ? (
                                    <FormattedMessage
                                        id="app.virtualLab.labs.maximumAllowed"
                                        defaultMessage="You can create {count} labs."
                                        values={{ count: maxAllowedLabs }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="app.virtualLab.labs.maximumReached"
                                        defaultMessage="You have reached the maximum number of labs you can create.."
                                    />
                                )}
                            </Text>
                        </Group>
                        <Space h="lg" />
                    </>
                )}
                <PaginationContainer
                    countLabel={{
                        id: "app.virtualLab.total",
                        defaultMessage: "Total: {count} lab(s)",
                    }}
                    itemsCount={responseData?.total ?? 0}
                    simpleGridProps={{
                        cols: { xs: 1, md: 3 },
                        spacing: "lg",
                        verticalSpacing: "lg",
                    }}
                    paginationProps={{
                        total: responseData?.pages ?? 0,
                        value: activePage,
                        onChange: (value) => setPage(value),
                    }}
                >
                    {responseData?.items.map((team, index) => (
                        <ItemCard
                            key={index}
                            title={team.name}
                            description={team.description}
                            creationDate={team.createdAt}
                            avatarUrl={team.avatarUrl}
                            detailsLink={`/team/${team.uuid}`}
                        />
                    ))}
                </PaginationContainer>
                {/* <Box style={{background: "#FFF", padding: "20px"}}>
                  <LinePlot csvUrl="/data/01HX9YXP43C3BAGEGDT116CG12.csv" />
                </Box> */}
            </PageContainer>
        </>
    );
}

export default VirtualLabListPage;
