import formClasses from "../../../styles/form.module.css";
import globalClasses from "../../../styles/global.module.css";
import { isNotEmpty, useForm } from "@mantine/form";
import { forwardRef, useImperativeHandle } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Grid, Title, Text, TextInput, Box, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { userEditProps } from "../Interfaces";
import { formularRef } from "../../common/Wrapper";
import { useRest } from "../../../features/context/RestContext";
import { UserProfile } from "../../../models/User";
import { isEmptyObject } from "../../../lib/Utilities";
import { useErrorAlertHandler } from "../../../features/hooks/useErrorHandler";
import { modals } from "@mantine/modals";
import { useAppData } from "../../../features/context/AppDataContext";

interface FormValues {
    role: string;
    job: string;
    lastName: string;
    firstName: string;
    birthDate: Date | null;
    officePhone: string;
    landlinePhone: string;
    mobilePhone: string;
    nickname: string;
    transactionsCurrencyCode: string;
}

const UserEditGrid = forwardRef<formularRef, userEditProps>(
    ({ user, updateUser, setLoaderVisibleCallback }, ref) => {
        const intl = useIntl();
        const handleError = useErrorAlertHandler();
        const { restApiService } = useRest();
        const { currencies } = useAppData();
        
        const form = useForm<FormValues>({
            validateInputOnBlur: true,
            initialValues: {
                role: user.rolesLabelized[0],
                job: user.profile?.job ?? "",
                lastName: user.profile?.lastName ?? "",
                firstName: user.profile?.firstName ?? "",
                birthDate: user.profile?.birthDate ?? null,
                officePhone: user.profile?.officePhone ?? "",
                landlinePhone: user.profile?.landlinePhone ?? "",
                mobilePhone: user.profile?.mobilePhone ?? "",
                nickname: user.nickname ?? "",
                transactionsCurrencyCode: user.profile?.transactionsCurrencyCode ?? "",
            },
            validate: {
                lastName: isNotEmpty(
                    intl.formatMessage({ id: "app.error.lastname.required" })
                ),
                firstName: isNotEmpty(
                    intl.formatMessage({ id: "app.error.firstname.required" })
                ),
            },
        });

        const handleSubmit = async () => {
            if (!form.isValid()) {
                modals.open({
                    title: (
                        <Title>
                            <FormattedMessage
                                id="app.error.invalidUserData"
                                defaultMessage="Invalid user data"
                            />
                        </Title>
                    ),
                    children: (
                        <Box>
                            <FormattedMessage
                                id="app.error.invalidUserData.description"
                                defaultMessage="Please check your data and try again."
                            />
                        </Box>
                    ),
                });
                return;
            }

            try {
                setLoaderVisibleCallback(true);
                const updatedProfile = isEmptyObject(user.profile)
                    ? await restApiService?.createProfile(form.values)
                    : await restApiService?.updateProfile(form.values);
                const nickname = updatedProfile?.nickname ?? "";
                user.nickname = nickname;
                user.profile = { ...updatedProfile } as UserProfile | null;
                updateUser(user);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisibleCallback(false);
            }
        };

        useImperativeHandle(ref, () => ({ handleSubmit }));

        return (
            <form>
                <Grid className={globalClasses.infoGrid}>
                    <Grid.Col span={{ sm: 12, md: 9 }}>
                        <Grid grow>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.user.function",
                                        defaultMessage: "Function",
                                    })}
                                    {...form.getInputProps("job")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    withAsterisk
                                    label={intl.formatMessage({
                                        id: "app.user.lastname",
                                        defaultMessage: "Lastname",
                                    })}
                                    {...form.getInputProps("lastName")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    withAsterisk
                                    label={intl.formatMessage({
                                        id: "app.user.firstname",
                                        defaultMessage: "Firstname",
                                    })}
                                    {...form.getInputProps("firstName")}
                                />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Select
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.formLabel.currency",
                                        defaultMessage: "Currency",
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: "app.formLabel.currency.placeholder",
                                    })}
                                    data={currencies?.map((c: string) => {
                                        return {
                                            value: c,
                                            label: c,
                                        };
                                    })}
                                    allowDeselect={false}
                                    maxDropdownHeight={200}
                                    {...form.getInputProps("transactionsCurrencyCode")}
                                    comboboxProps={{
                                        transitionProps: {
                                            transition: "pop",
                                            duration: 200,
                                        },
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <DatePickerInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.user.birthdate",
                                        defaultMessage: "Birthdate",
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: "app.formLabel.date.placeholder",
                                        defaultMessage: "Pick a date",
                                    })}
                                    hideOutsideDates={true}
                                    {...form.getInputProps("birthDate")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.user.nickname",
                                        defaultMessage: "Nickname",
                                    })}
                                    {...form.getInputProps("nickname")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Title order={4}>
                                    <FormattedMessage
                                        id="app.user.phone"
                                        defaultMessage="Phone"
                                    />
                                </Title>
                            </Grid.Col>
                            <Grid.Col span={6}></Grid.Col>
                            <Grid.Col span={3}>
                                <TextInput
                                    label={intl.formatMessage({
                                        id: "app.user.office",
                                        defaultMessage: "Office",
                                    })}
                                    {...form.getInputProps("officePhone")}
                                />
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <TextInput
                                    label={intl.formatMessage({
                                        id: "app.user.landline",
                                        defaultMessage: "Landline",
                                    })}
                                    {...form.getInputProps("landlinePhone")}
                                />
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <TextInput
                                    label={intl.formatMessage({
                                        id: "app.user.mobile",
                                        defaultMessage: "Mobile",
                                    })}
                                    {...form.getInputProps("mobilePhone")}
                                />
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                </Grid>
            </form>
        );
    }
);

export default UserEditGrid;
