import { createContext, useContext } from "react";
import { RestApiService } from "../../services/Api";

interface IRestProps {
    restApiService: RestApiService | null;
}

export const RestContext = createContext<IRestProps>({
    restApiService: null,
});

export const useRest = () => {
    const rest = useContext(RestContext);

    if (!rest) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return rest;
};

export const RestProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const contextValue: IRestProps = {
        restApiService: RestApiService.getInstance(),
    };

    return (
        <RestContext.Provider value={contextValue}>
            {children}
        </RestContext.Provider>
    );
};
