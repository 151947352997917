import classes from "./Shop.module.css";
import globalClasses from "../../styles/global.module.css";
import { ProductList } from "../../components/Shop/Product/ProductList";
import OrdersList from "../../components/Shop/Order/OrdersList";
import {
    Box,
    Button,
    Drawer,
    Group,
    Indicator,
    LoadingOverlay,
    Space,
    Stack,
    Title,
    Tooltip,
    TransitionProps,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { FormattedMessage, useIntl } from "react-intl";
import { IconShoppingCart } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import ShoppingCart from "../../components/Shop/ShoppingCart/ShoppingCart";
import { useShoppingCart } from "../../features/context/ShoppingCartContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageContainer } from "../../components/common/Page";
import { useAuth } from "../../features/context/AuthContext";
import { useRest } from "../../features/context/RestContext";
import { Paginated } from "../../models/Common";
import { ProductType } from "../../lib/Constants";
import { Product } from "../../models/Product";
import { OvalLoader } from "../../components/common/Loader";
import { ProductCartItem } from "../../models/Order";
import { CartStep } from "../../components/Shop/ShoppingCart/Interfaces";

function Shop() {
    const [cartOpened, cartHandler] = useDisclosure();
    const [callingCancelOrder, setCallingCancelOrder] =
        useState<boolean>(false);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(true);
    const [currentCartStep, setCurrentCartStep] = useState<CartStep>(
        CartStep.ORDER
    );

    const { user } = useAuth();
    const intl = useIntl();
    const { restApiService } = useRest();
    const { profileAddress } = user ?? {};
    const { productData, productFeeData, createdOrder, setShoppingCart } =
        useShoppingCart();

    const totalProducts: number = useMemo(
        () => productData.reduce((acc, curr) => acc + curr.amount, 0),
        [productData]
    );
    const canBuy = useMemo(
        () => !!user?.profile && !!profileAddress?.billing,
        [user, profileAddress]
    );

    const handleCheckoutClosure = useCallback(async () => {
        setCallingCancelOrder(true);
        if (createdOrder?.uuid) {
            try {
                const response = await restApiService?.cancelOrder(
                    createdOrder.uuid
                );
            } catch (e) {
                console.error(e);
            } finally {
                setCallingCancelOrder(false);
                cartHandler.close();
            }
        }
    }, [createdOrder, restApiService, cartHandler]);

    const openCheckoutSessionCloseConfirmModal = () =>
        modals.openConfirmModal({
            title: (
                <Title order={2}>
                    <FormattedMessage id="app.order.checkout.cancel.confirm" />
                </Title>
            ),
            centered: true,
            children: (
                <FormattedMessage id="app.order.checkout.cancel.confirm.messsage" />
            ),
            labels: {
                confirm: intl.formatMessage({ id: "app.label.confirm" }),
                cancel: intl.formatMessage({ id: "app.label.cancel" }),
            },
            confirmProps: { color: "red" },
            onConfirm: handleCheckoutClosure,
        });

    const handleCartStepChange = useCallback(
        (currentStep: CartStep) => {
            // Handle closing depending on cart step
            if (currentStep === CartStep.CHECKOUT) {
                openCheckoutSessionCloseConfirmModal();
                return;
            } else if (currentStep === CartStep.CLOSING) {
                setShoppingCart((prevData) => ({
                    ...prevData,
                    stripeCliSecret: "",
                    createdOrder: null,
                    productData: [],
                }));
            }
            cartHandler.close();
        },
        [cartHandler]
    );

    const getProductFeeList = useCallback(async () => {
        try {
            setLoaderVisible(true);
            const data: Paginated<Product> =
                await (restApiService?.getProductListbyType(
                    ProductType.FEE,
                    1,
                    20
                ) ?? new Paginated<Product>());
            const productFeeData: ProductCartItem[] = data.items.map((item) => {
                return {
                    product: item,
                    neededBoxAmount: 0,
                    amount: 0,
                };
            });

            setShoppingCart((prevData) => ({
                ...prevData,
                productFeeData: productFeeData,
            }));
        } catch (error: any) {
            console.error(error);
        } finally {
            setLoaderVisible(false);
        }
    }, [restApiService, setShoppingCart]);

    useEffect(() => {
        if (!productFeeData) getProductFeeList();
    }, [getProductFeeList]);

    useEffect(() => {
        const handleBeforeUnload = (event: Event) => {
            event.preventDefault();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <OvalLoader visible={loaderVisible} />
            <Drawer
                className={classes.drawer}
                position="right"
                opened={cartOpened}
                onClose={() => handleCartStepChange(currentCartStep)}
                closeOnClickOutside={false}
                title={
                    <Title order={1}>
                        <FormattedMessage id="app.order.myCart" />
                    </Title>
                }
                transitionProps={transitionProps}
            >
                <ShoppingCart
                    onStepChange={setCurrentCartStep}
                    onClose={() => {
                        cartHandler.close();
                        setShoppingCart((prevData) => ({
                            ...prevData,
                            stripeCliSecret: "",
                            createdOrder: null,
                            productData: [],
                        }));
                    }}
                />
            </Drawer>
            <LoadingOverlay visible={callingCancelOrder} />
            <PageContainer titleId="app.shop" defaultTitle="Shop">
                <Stack>
                    <Group justify="flex-end">
                        <Indicator
                            color="red"
                            inline
                            processing
                            disabled={totalProducts === 0}
                            label={totalProducts}
                            size={16}
                            position="bottom-end"
                        >
                            <Tooltip
                                arrowOffset={10}
                                arrowSize={4}
                                color="orange"
                                w={200}
                                multiline
                                label={intl.formatMessage(
                                    {
                                        id: "fake",
                                        defaultMessage: "{warning}: {message}",
                                    },
                                    {
                                        warning: (
                                            <FormattedMessage id="app.shop.message.disallowOrders" />
                                        ),
                                        message: (
                                            <>
                                                {!user?.profile && (
                                                    <FormattedMessage
                                                        id="app.warning.profileNotSet"
                                                        defaultMessage="Profile not configured."
                                                    />
                                                )}
                                                {!profileAddress?.billing && (
                                                    <FormattedMessage
                                                        id="app.warning.prodileAddressNotSet"
                                                        defaultMessage="Address not configured."
                                                    />
                                                )}
                                            </>
                                        ),
                                    }
                                )}
                                opened={!canBuy}
                                withArrow
                            >
                                <Button
                                    disabled={!canBuy}
                                    rightSection={
                                        <IconShoppingCart size={16} />
                                    }
                                    onClick={cartHandler.open}
                                >
                                    <FormattedMessage
                                        id="app.order.myCart"
                                        defaultMessage="My cart"
                                    />
                                </Button>
                            </Tooltip>
                        </Indicator>
                    </Group>
                </Stack>
                <Stack>
                    <Box>
                        <ProductList />
                    </Box>
                    <Space h="xl" />
                    <Box>
                        <Title className={globalClasses.title} order={2}>
                            <FormattedMessage
                                id="app.order.history"
                                defaultMessage="Order history"
                            />
                        </Title>
                        <Space h="xs" />
                        <OrdersList />
                    </Box>
                </Stack>
            </PageContainer>
        </>
    );
}

export default Shop;

const transitionProps: Partial<Omit<TransitionProps, "mounted">> | undefined = {
    transition: "rotate-left",
    duration: 150,
    timingFunction: "linear",
};
