import {
    Expose,
    plainToClass,
    instanceToPlain,
    Transform,
    Type,
} from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";
import { stringToDate } from "../lib/ConvertionUtils";
import { User, UserDto } from "./User";
import { Subscription, SubscriptionDto } from "./Subscription";

interface ILicenceStatus {
    value: string;
    label: LicenceStatusLabelEnum;
    color: string;
}

interface ILicenceRole {
    value: string;
    label: LicenceRoleLabelEnum;
}

export enum LicenceRoleEnum {
    ADMIN_ROLE = "ADMIN_ROLE",
    ASSIGNEE_ROLE = "ASSIGNEE_ROLE",
    OWNER_ROLE = "OWNER_ROLE",
}

export enum LicenceRoleLabelEnum {
    ADMIN_ROLE = "app.user.role.admin",
    ASSIGNEE_ROLE = "app.licence.assignee",
    OWNER_ROLE = "app.owner",
}

export enum LicenceStatusLabelEnum {
    ACTIVE = "app.label.active",
    INACTIVE = "app.label.inactive",
    PENDING = "app.label.pending",
    EXPIRED = "app.label.expired",
    PAUSED = "app.label.paused",
}

export enum LicenceStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    PENDING = "PENDING",
    EXPIRED = "EXPIRED",
    PAUSED = "PAUSED",
}

const STATUS_MAP = new Map<string, ILicenceStatus>([
    [
        "ACTIVE",
        {
            value: LicenceStatusEnum.ACTIVE,
            label: LicenceStatusLabelEnum.ACTIVE,
            color: "green",
        },
    ],
    [
        "INACTIVE",
        {
            value: LicenceStatusEnum.INACTIVE,
            label: LicenceStatusLabelEnum.INACTIVE,
            color: "orange",
        },
    ],
    [
        "PENDING",
        {
            value: LicenceStatusEnum.PENDING,
            label: LicenceStatusLabelEnum.PENDING,
            color: "red",
        },
    ],
    [
        "EXPIRED",
        {
            value: LicenceStatusEnum.EXPIRED,
            label: LicenceStatusLabelEnum.EXPIRED,
            color: "red",
        },
    ],
    [
        "PAUSED",
        {
            value: LicenceStatusEnum.PAUSED,
            label: LicenceStatusLabelEnum.PAUSED,
            color: "black",
        },
    ],
]);

const LICENCE_ROLE_MAP = new Map<string, ILicenceRole>([
    [
        "ADMIN_ROLE",
        {
            value: LicenceRoleEnum.ADMIN_ROLE,
            label: LicenceRoleLabelEnum.ADMIN_ROLE,
        },
    ],
    [
        "ASSIGNEE_ROLE",
        {
            value: LicenceRoleEnum.ASSIGNEE_ROLE,
            label: LicenceRoleLabelEnum.ASSIGNEE_ROLE,
        },
    ],
    [
        "OWNER_ROLE",
        {
            value: LicenceRoleEnum.OWNER_ROLE,
            label: LicenceRoleLabelEnum.OWNER_ROLE,
        },
    ],
]);

@staticImplements<DtoStatic>()
export class LicenceDto {
    @Expose()
    uuid!: string;
    @Expose()
    createdAt!: string;
    @Expose()
    licenceKey!: string;
    @Expose()
    status!: string;
    @Expose()
    @Type(() => UserDto)
    assignee!: UserDto;
    @Expose()
    martaSerial!: string;
    @Expose()
    roles!: string[];
    @Expose()
    @Type(() => SubscriptionDto)
    subscription!: SubscriptionDto;
    @Expose()
    pendingEmail!: string;

    static toModel(licenceDto: LicenceDto): Licence {
        if (licenceDto === undefined) {
            return new Licence();
        }
        const data = instanceToPlain(licenceDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Licence, data);
    }
}

export class Licence implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    @Transform((data) => stringToDate(data.value, true))
    createdAt!: Date | null;
    @Expose()
    licenceKey!: string;
    @Expose()
    @Transform((data) => STATUS_MAP.get(data.value) || data.value)
    status!: ILicenceStatus;
    @Expose()
    @Type(() => User)
    assignee!: User;
    @Expose()
    martaSerial!: string;
    @Expose()
    @Transform((data) => {
        return data.value.map(
            (item: string) =>
                LICENCE_ROLE_MAP.get(item) || item
        );
    })
    roles!: ILicenceRole[];
    @Expose()
    @Type(() => Subscription)
    subscription!: Subscription;
    @Expose()
    pendingEmail!: string;
}
