import { Burger, Container, Stack, Text, Title } from "@mantine/core";
import classes from "./Header.module.css";
import { useAuth } from "../../features/context/AuthContext";
import { FormattedMessage } from "react-intl";
import { useData } from "../../features/context/DataContext";

interface AppHeadProps {
    opened: boolean;
    onClick: () => void;
}

function AppHead({ opened, onClick }: AppHeadProps) {
    const { user } = useAuth();
    const { assignedLicence } = useData();
    const planName = assignedLicence?.subscription?.plan.name;

    return (
        <div className={classes.header}>
            <Title order={1}>MYMARTA</Title>
            <Stack gap="xs" >
                <Title order={5}>
                    <FormattedMessage
                        id="app.user.account"
                        values={{ email: user?.email }}
                    />
                </Title>
                {planName && (
                    <Text className={classes.subscription}>
                        <FormattedMessage
                            id="app.subscription.plan.name"
                            defaultMessage="Subscription plan"
                        />
                        : {planName}
                    </Text>
                )}
            </Stack>
            <Burger
                opened={opened}
                onClick={onClick}
                size="sm"
                hiddenFrom="sm"
            />
        </div>
    );
}

export default AppHead;
