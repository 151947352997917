import { AxiosError } from "axios";
import { HTTP } from "../Constants";
import {
  AuthenticationError,
  BadRequestError,
  BaseError,
  EmailError,
  ForbiddenError,
  NetworkError,
  NotFoundError
} from "../Error";

/**
 * HTTP error handle decorator, it must be used on a method that returns an axios response
 * and placed directly above the method when there is no body preprocessing needed.
 *
 * @param target
 * @param memberName
 * @param descriptor
 */
export function httpErrorHandle(
    target: any,
    memberName: string,
    descriptor: PropertyDescriptor
) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
        try {
            const response = await originalMethod.apply(this, args);
            if (!response) return null;

            switch (response.status) {
                case HTTP.OK:
                    return response.data;
                case HTTP.NO_CONTENT:
                case HTTP.CREATED:
                    return null;
                default:
                    throw new Error("Unexpected HTTP status: " + response.status);
            }
        } catch (error: any) {
            if (!error.response) {
                throw new Error("app.error.unexpected");
            }

            if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
                throw new NetworkError("app.error.network", error.message);
            }

            const { status } = error.response;
            const serverMessage = error.response.data?.error?.message;
            const appErrorCode = error.response.data?.error?.appErrorCode;
            const errors = error.response.data?.error?.errors || [];

            switch (status) {
                case HTTP.FORBIDDEN:
                    throw new ForbiddenError("app.error.forbidden", serverMessage);
                case HTTP.BAD_REQUEST:
                    if (errors.email) {
                        throw new EmailError("app.error.invalidEmail", serverMessage, errors);
                    }
                    throw new BadRequestError("app.error.badRequest", serverMessage, errors, appErrorCode);
                case HTTP.UNAUTHORIZED:
                    throw new AuthenticationError("app.error.authentication", serverMessage, appErrorCode);
                case HTTP.NOT_FOUND:
                    throw new NotFoundError("app.error.notFound", serverMessage, appErrorCode);
                default:
                    throw new BaseError("app.error.unexpected", serverMessage, appErrorCode);
            }
        }
    };
}
