import globalClasses from "../../styles/global.module.css";
import { Card, Text, Button, Grid, Box } from "@mantine/core";
import classes from "./Subscription.module.css";
import { useEffect, useState } from "react";
import { useRest } from "../../features/context/RestContext";
import {
    SubscriptionPlan,
    SubscriptionPlanType,
} from "../../models/Subscription";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import { useIntl } from "react-intl";
import { BarLoader } from "../common/Loader";
import { Link } from "react-router-dom";
import { Features } from "./Components";
import { useData } from "../../features/context/DataContext";

interface PricingCardProps {
    plan: SubscriptionPlan;
    buttonLabel?: string;
    ribbonLabel?: string;
    ribbonColor?: string;
}

function PricingPlan({
    plan,
    buttonLabel,
    ribbonLabel,
    ribbonColor,
}: PricingCardProps) {
    const intl = useIntl();

    return (
        <Card shadow="sm" radius="md">
            <Card.Section
                className={`${classes.ribbon} ${classes.textMain}`}
                style={{ backgroundColor: ribbonColor }}
                variant="filled"
            >
                {ribbonLabel}
            </Card.Section>

            <Text
                className={`${classes.title} ${classes.textMain}`}
                size="xl"
                mt="md"
            >
                {plan.name}
            </Text>
            <Box mt="md" mb="xl">
                <Text
                    size="xl"
                    className={`${classes.title} ${classes.textMain}`}
                >
                    {plan.type != SubscriptionPlanType.ENTERPRISE
                        ? `CHF ${plan.price}.- `
                        : intl.formatMessage({ id: "app.contact.us" })}
                </Text>
                <Text size="sm" className={classes.description}>
                    {plan.type != SubscriptionPlanType.ENTERPRISE
                        ? plan.period
                        : ""}
                </Text>
            </Box>
            <Features plan={plan} compact />
            <Link
                to="/subscription/plan"
                className={globalClasses.link}
                state={{ plan: plan }}
            >
                {buttonLabel && (
                    <Button fullWidth mt="xl" variant="filled">
                        {buttonLabel}
                    </Button>
                )}
            </Link>
        </Card>
    );
}

function PricingSection() {
    const { restApiService } = useRest();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [pricingProps, setPricingProps] = useState<PricingCardProps[]>([]);
    const handleError = useErrorAlertHandler();
    const { assignedLicence } = useData();
    
    const intl = useIntl();

    const isUserSubscribedToPlan = (planUuid: string): boolean => {
        return assignedLicence?.subscription?.plan.uuid === planUuid;
    };

    const isUserSubscribed = (): boolean => {
        return assignedLicence?.subscription !== undefined;
    };

    const getSubscriptionPlans = async () => {
        try {
            setLoaderVisible(true);
            const data: SubscriptionPlan[] =
                await (restApiService?.getSubscriptionPlans() ?? []);

            const propsData: PricingCardProps[] = data.map((plan) => ({
                plan,
                buttonLabel: !isUserSubscribedToPlan(plan.uuid)
                    ? intl.formatMessage({
                          id: isUserSubscribed()
                              ? "app.subscription.changePlan"
                              : "app.subscription.getStarted",
                      })
                    : undefined,
                ribbonLabel: isUserSubscribedToPlan(plan.uuid)
                    ? intl.formatMessage({
                          id: "app.subscription.currentPlan",
                      })
                    : "",
                ribbonColor: isUserSubscribedToPlan(plan.uuid)
                    ? "green"
                    : "gray",
            }));
            setPricingProps(propsData);
        } catch (error: any) {
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    useEffect(() => {
        getSubscriptionPlans();
    }, []);

    return (
        <>
            <BarLoader visible={loaderVisible} />
            <Grid>
                {pricingProps.map((plan, index) => (
                    <Grid.Col key={index} span={3}>
                        <PricingPlan {...plan} />
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
}

export default PricingSection;
