import axios from "axios";
import { useEffect, useState } from "react";

declare global {
    interface Window {
        grecaptcha: any;
    }
}

const useReCaptcha = (): { reCaptchaLoaded: boolean; generateReCaptchaToken: (action: string) => Promise<string> } => {
    const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);
    const RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_KEY as string;
    const baseURL = import.meta.env.VITE_API_BASE_URL as string;
    const enabled = import.meta.env.VITE_RECAPTCHA_ENABLED === "true";

    const loadReCaptchaScript = async () => {

        if(!enabled) {
            // simulate recaptcha loaded because of blocked features
            setReCaptchaLoaded(true);
            return;
        }

        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
        script.async = true;
        script.defer = true;
        script.addEventListener("load", () => {
            setReCaptchaLoaded(true);
        });
        document.body.appendChild(script);
    };

    const generateReCaptchaToken = (action: string): Promise<string> => {

        if (!enabled) return Promise.resolve("");

        return new Promise((resolve, reject) => {
            if (!reCaptchaLoaded)
                return reject(new Error("ReCaptcha not loaded"));
            if (typeof window === "undefined" || !window.grecaptcha) {
                setReCaptchaLoaded(false);
                return reject(new Error("ReCaptcha not loaded"));
            }
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(RECAPTCHA_KEY, { action }).then(
                    async (token: string) => {
                        try {
                            const response = await axios.post(`${baseURL}/recaptcha/verify`, { recaptchaToken: token });
                            if (response.status !== 200) {
                                reject(new Error("ReCaptcha verification failed"));
                            }
                        } catch (error: any) {
                            reject(error);
                        }
                        resolve(token);
                    },
                    (error: any) => {
                        console.error(error);
                    }
                );
            });
        });
    };

    useEffect(() => {
        if (typeof window === "undefined" || reCaptchaLoaded) return;
        if (window.grecaptcha) {
            setReCaptchaLoaded(true);
            return;
        }
        loadReCaptchaScript();
    }, [reCaptchaLoaded]);

    return {reCaptchaLoaded, generateReCaptchaToken};
};

export default useReCaptcha;
