import classes from "./stats.module.css";
import { RingProgress, Text, SimpleGrid, Group } from "@mantine/core";
import { IconArrowUpRight, IconArrowDownRight } from "@tabler/icons-react";
import { StatsProps } from "./Interfaces";

const icons = {
    up: IconArrowUpRight,
    down: IconArrowDownRight,
};

export function StatsRing({ data }: StatsProps) {
    if (!data) {
        return null;
    }

    const stats = data.map((stat, index) => {
        return (
            <Group key={index}>
                {stat.part && (
                    <RingProgress
                        size={80}
                        roundCaps
                        thickness={8}
                        sections={[{ value: stat.part, color: stat.color }]}
                    />
                )}
                <div className={classes.sideBarLeft}>
                    <Text
                        size="xs"
                        tt="uppercase"
                        fw={700}
                    >
                        {stat.label}
                    </Text>
                    <Text fw={700} size="xl">
                        {stat.count}
                    </Text>
                </div>
            </Group>
        );
    });

    return <SimpleGrid cols={{ base: 1, sm: 3 }}>{stats}</SimpleGrid>;
}
