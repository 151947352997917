import axios, { AxiosInstance } from "axios";
import { plainToClass } from "class-transformer";
import {
    AuthenticationError,
    EmailNotVerifiedError,
    RegistrationError,
    UserDataError,
} from "../lib/Error";
import { User, UserDto, UserProfile, UserProfileDto } from "../models/User";
import { validatorDto } from "../lib/Validator";
import { ProfileAddress, ProfileAddressDto } from "../models/Contact";
import { httpErrorHandle } from "../lib/decorators/Http";
import {
    dtoToModel,
    mappedDtoToModel,
    paginatedDtoToPaginatedModel,
    prepareDtoPayload,
} from "../lib/decorators/Model";
import {
    Membership,
    MembershipDto,
    Team,
    TeamDto,
    TeamMember,
    TeamMemberDto,
} from "../models/Team";
import { Paginated } from "../models/Common";
import { Product, ProductDto } from "../models/Product";
import { Order, OrderCreationDto, OrderDto } from "../models/Order";
import { Licence, LicenceDto } from "../models/Licence";
import {
    LabStat,
    LabStatDto,
    OrderStat,
    OrderStatDto,
    RecordingStat,
    RecordingStatDto,
    TeamStat,
    TeamStatDto,
} from "../models/Stats";
import {
    SubscriptionPlan,
    SubscriptionPlanDto,
    UserSubscriptionReport,
    UserSubscriptionReportDto,
} from "../models/Subscription";
import { UrlParamType } from "../lib/Enums";
import { Country, CountryDto } from "../models/Country";
import { Company, CompanyDto } from "../models/Company";
import { AppMode } from "../lib/Constants";
import { Media, MediaDto } from "../models/Media";

export const NONE = (status: number) => true;

const getApiConfig = () => {
    const mode: string = import.meta.env.MODE;
    const baseConfig: any = {
        baseURL: import.meta.env.VITE_API_BASE_URL,
        timeout: import.meta.env.VITE_API_TIMEOUT,
        withCredentials: true,
    };
    switch (mode) {
        // In test server .htaccess password is used, therefore the auth is needed
        // TODO: solve this stripe webhook rule in htaccess then you can uncomment the auth object
        case AppMode.TEST:
            return {
                ...baseConfig,
                // auth: {
                //     username: import.meta.env.VITE_TEST_UNAME,
                //     password: import.meta.env.VITE_TEST_PWD,
                // },
            };
        default:
            return baseConfig;
    }
};

export class AuthApiService {
    private static _instance: AuthApiService;
    private _axiosInstance: AxiosInstance;

    public constructor() {
        this._axiosInstance = axios.create(getApiConfig());
    }

    public static getInstance(): AuthApiService {
        if (!AuthApiService._instance) {
            AuthApiService._instance = new AuthApiService();
        }
        return AuthApiService._instance;
    }

    /**
     * User Login
     * @param email
     * @param password
     * @returns
     */
    public async login(email: string, password: string): Promise<User> {
        const response = await this._axiosInstance.post(
            "/login",
            { email, password },
            {
                // don't validate status in axios, it's getting validated below
                validateStatus: NONE,
            }
        );
        if (response.status > 400) {
            throw new AuthenticationError("app.error.invalidCredentials");
        }
        if (response.status === 204) {
            const userEP = response.headers.location;
            if (userEP) {
                return await this.getUserInfo(userEP);
            }
        }
        throw new Error("app.error.unexpected");
    }

    /**
     * Forgot password request
     * @param email
     */
    @httpErrorHandle
    public async forgotPassword(email: string): Promise<void> {
        return await this._axiosInstance.post("/password/forgot", {
            email: email,
        });
    }

    /**
     * Resend verification email
     * @param email
     */
    @httpErrorHandle
    public async resendVerificationEmail(email: string): Promise<void> {
        return await this._axiosInstance.post("/verify/resend", {
            email: email,
        });
    }
    /**
     * Reset password
     * @param token
     * @param payload password and passwordConfirm fields
     * @returns
     */
    @httpErrorHandle
    public async resetPassword(token: string, payload: any): Promise<void> {
        return await this._axiosInstance.post(
            `/password/reset/${token}`,
            payload
        );
    }

    /**
     * Auth ping
     * @returns 
     */
    @httpErrorHandle
    public async ping(): Promise<void> {
        return this._axiosInstance.post("/user/ping");
    }

    /**
     * Get user info
     * @param userEndpoint
     * @returns
     */
    public async getUserInfo(userEndpoint: string): Promise<User> {
        const userResponse = await this._axiosInstance.get(userEndpoint);
        if (userResponse.status === 200) {
            await validatorDto(UserDto, userResponse.data.info);
            const userDto: UserDto = plainToClass(
                UserDto,
                userResponse.data.info,
                {
                    excludeExtraneousValues: true,
                }
            );
            if (userDto) {
                const user: User = UserDto.toModel(userDto);
                if (!user.isVerified) {
                    throw new EmailNotVerifiedError(
                        "app.error.emailNotVerified"
                    );
                }
                return user;
            }
        }
        throw new UserDataError("app.error.userNotFetched");
    }

    /**
     * User Signup
     * @param payload
     * @returns
     */
    @httpErrorHandle
    public async signup(payload: any): Promise<any> {
        const response = await this._axiosInstance.post(
            "/signup",
            payload
        );

        if (response.status === 201) {
            return response;
        }
        throw new RegistrationError("app.error.registrationFailed");
    }

    /**
     * Verify PIN code
     *
     * @param pinCode
     * @param invitationUuid
     * @returns
     */
    @httpErrorHandle
    public async verifyInvitee(
        pinCode: string,
        invitationUuid: string
    ): Promise<any> {
        return await this._axiosInstance.post(
            `/invitation/verify/${invitationUuid}`,
            {
                pinCode: pinCode,
            }
        );
    }

    /**
     * Set password for invitee
     * @param payload
     * @returns
     */
    @httpErrorHandle
    public async setInviteePassword(payload: any): Promise<void> {
        return await this._axiosInstance.post(
            `/invitation/password`,
            payload
        );
    }

    /**
     * Logout
     *
     * @param token
     * @returns
     */
    @httpErrorHandle
    public async logout(): Promise<void> {
        await this._axiosInstance.get("/logout");
    }
}

export class RestApiService {
    private static _instance: RestApiService;
    private _axiosInstance: AxiosInstance;

    /**
     * Init axios instance and the JWT interceptor
     */
    public constructor() {
        const baseUrl = import.meta.env.VITE_API_BASE_URL;

        this._axiosInstance = axios.create(getApiConfig());

        if (this._axiosInstance) {
            this._axiosInstance.interceptors.response.use(
                (response) => {
                    return response;
                },
                async (error) => {
                    if (error.response?.status === 401) {
                        return;
                    } else {
                        return Promise.reject(error);
                    }
                }
            );
        }
    }

    public static getInstance(): RestApiService {
        if (!RestApiService._instance) {
            RestApiService._instance = new RestApiService();
        }
        return RestApiService._instance;
    }

    /**
     * Refresh session JWT token
     * 
     * @returns 
     */
    @httpErrorHandle
    public refreshToken() {
        return this._axiosInstance.get("/token/refresh");
    }

    /**
     * Country list
     *
     * @returns
     */
    @dtoToModel(CountryDto)
    @httpErrorHandle
    public async getCountries(): Promise<Country[]> {
        return await this._axiosInstance.get(`/country/list`);
    }

    /**
     * Set user locale
     * @param locale
     */
    @httpErrorHandle
    public async setUserLocale(locale: string): Promise<any> {
        return await this._axiosInstance.post(`/user/locale`, {
            locale: locale,
        });
    }

    /**
     * Create user profile
     * @param uuid
     * @param payload
     * @returns
     */
    @dtoToModel(UserProfileDto)
    @httpErrorHandle
    @prepareDtoPayload(UserProfileDto)
    public async createProfile(payload: any): Promise<UserProfile> {
        return await this._axiosInstance.post(`/user/profile`, payload);
    }

    /**
     * Update user profile
     * @param uuid
     * @param payload
     * @returns
     */
    @dtoToModel(UserProfileDto)
    @httpErrorHandle
    @prepareDtoPayload(UserProfileDto)
    public async updateProfile(payload: any): Promise<UserProfile> {
        return await this._axiosInstance.put(`/user/profile`, payload);
    }

    /**
     * Delete user profile
     * @param uuid
     * @returns
     */
    @httpErrorHandle
    public async deleteProfile(): Promise<any> {
        return await this._axiosInstance.delete(`/user/profile`);
    }

    /**
     * Get user profile address
     * @param uuid
     * @returns
     */
    @dtoToModel(ProfileAddressDto)
    @httpErrorHandle
    public async getProfileAddress(): Promise<ProfileAddress> {
        return await this._axiosInstance.get(`/user/profile/address`);
    }

    /**
     * Create user profile address
     * @param uuid
     * @param payload
     * @returns
     */
    @dtoToModel(ProfileAddressDto)
    @httpErrorHandle
    @prepareDtoPayload(ProfileAddressDto)
    public async createProfileAddress(payload: any): Promise<ProfileAddress> {
        return await this._axiosInstance.post(
            `/user/profile/address`,
            payload
        );
    }

    /**
     * Update user profile address
     * @param uuid
     * @param payload
     * @returns
     */
    @dtoToModel(ProfileAddressDto)
    @httpErrorHandle
    @prepareDtoPayload(ProfileAddressDto)
    public async updateProfileAddress(payload: any): Promise<ProfileAddress> {
        return await this._axiosInstance.put(
            `/user/profile/address`,
            payload
        );
    }

    /**
     * Delete user profile address
     * @returns
     */
    @httpErrorHandle
    public async deleteProfileAddress(): Promise<any> {
        return await this._axiosInstance.delete(`/user/profile/address`);
    }

    /**
     * Get company details
     * @returns
     */
    @dtoToModel(CompanyDto)
    @httpErrorHandle
    public async getCompany(): Promise<Company> {
        return await this._axiosInstance.get(`/user/company`);
    }

    /**
     * Create company
     *
     * @param payload
     * @returns
     */
    @dtoToModel(CompanyDto)
    @httpErrorHandle
    public async createCompany(payload: any): Promise<Company> {
        return await this._axiosInstance.post(`/user/company`, payload);
    }

    /**
     * Update company
     *
     * @param payload
     * @returns
     */
    @dtoToModel(CompanyDto)
    @httpErrorHandle
    public async updateCompany(payload: any): Promise<Company> {
        return await this._axiosInstance.put(`/user/company`, payload);
    }

    /**
     * Delete company
     *
     * @returns
     */
    @httpErrorHandle
    public async deleteCompany(): Promise<any> {
        return await this._axiosInstance.delete(`/user/company`);
    }

    /**
     * Get teams for user
     * @param page
     * @param itemsPerPage
     * @returns
     */
    @paginatedDtoToPaginatedModel(TeamDto)
    @httpErrorHandle
    public async getUserTeamsList(
        page: number,
        itemsPerPage: number
    ): Promise<Paginated<Team>> {
        return await this._axiosInstance.get(`/user/team/list`, {
            params: {
                itemsPerPage: itemsPerPage,
                page: page,
            },
        });
    }

    /**
     * Get all memberships for user
     * @returns
     */
    @mappedDtoToModel(MembershipDto)
    @httpErrorHandle
    public async getUserMemberships(): Promise<Map<string, Membership>> {
        return await this._axiosInstance.get(`/user/team/membership/list`);
    }

    /**
     * Get team details
     * @param teamUuid
     * @returns
     */
    @dtoToModel(TeamDto)
    @httpErrorHandle
    public async getTeam(teamUuid: string): Promise<Team> {
        return await this._axiosInstance.get(`/team/${teamUuid}`);
    }

    /**
     * Get team members list
     * @param teamUuid
     * @returns
     */
    @dtoToModel(TeamMemberDto)
    @httpErrorHandle
    public async getTeamMembers(teamUuid: string): Promise<TeamMember[]> {
        return await this._axiosInstance.get(
            `/team/${teamUuid}/member/list`
        );
    }

    /**
     * Create team
     * @param payload
     * @returns
     */
    @dtoToModel(TeamDto)
    @httpErrorHandle
    @prepareDtoPayload(TeamDto)
    public async createTeam(payload: any): Promise<Team> {
        return await this._axiosInstance.post(`/team`, payload);
    }

    /**
     * Get membership data in team
     * @param teamUuid
     * @returns
     */
    @dtoToModel(MembershipDto)
    @httpErrorHandle
    @prepareDtoPayload(MembershipDto)
    public async getMembershipData(teamUuid: string): Promise<Membership> {
        return await this._axiosInstance.get(
            `/team/${teamUuid}/member/me`
        );
    }

    /**
     * Update team
     * @param teamUuid
     * @param payload
     * @returns
     */
    @dtoToModel(TeamDto)
    @httpErrorHandle
    @prepareDtoPayload(TeamDto)
    public async updateTeam(teamUuid: string, payload: any): Promise<Team> {
        return await this._axiosInstance.put(`/team/${teamUuid}`, payload);
    }

    /**
     * Delete team
     * @param teamUuid
     * @returns
     */
    @httpErrorHandle
    public async deleteTeam(teamUuid: string): Promise<any> {
        return await this._axiosInstance.delete(`/team/${teamUuid}`);
    }

    /**
     * Add user to team
     * @param teamUuid
     * @param memberUuids
     * @returns
     */
    @dtoToModel(TeamMemberDto)
    @httpErrorHandle
    public async addTeamMembers(
        teamUuid: string,
        memberUuids: string[]
    ): Promise<TeamMember[]> {
        return await this._axiosInstance.put(
            `/team/${teamUuid}/member/add`,
            {
                memberUuids: memberUuids,
            }
        );
    }

    /**
     * Remove user from team
     * @param teamUuid
     * @param memberUuid
     * @returns
     */
    @dtoToModel(TeamMemberDto)
    @httpErrorHandle
    public async removeTeamMember(
        teamUuid: string,
        memberUuid: string
    ): Promise<TeamMember[]> {
        return await this._axiosInstance.put(
            `/team/${teamUuid}/member/remove`,
            { memberUuid: memberUuid }
        );
    }

    /**
     * Activate or deactivate team member
     * @param teamUuid
     * @param memberUuid
     * @returns
     */
    @dtoToModel(TeamMemberDto)
    @httpErrorHandle
    public async toggleTeamMemberStatus(
        teamUuid: string,
        memberUuid: string
    ): Promise<TeamMember[]> {
        return await this._axiosInstance.put(
            `/team/${teamUuid}/member/toggle_active`,
            { memberUuid: memberUuid }
        );
    }

    /**
     * Update member roles
     * @param teamUuid
     * @param memberUuid
     * @param roles
     * @returns
     */
    @httpErrorHandle
    public async updateTeamMemberRoles(
        teamUuid: string,
        memberUuid: string,
        roles: string[]
    ): Promise<TeamMember[]> {
        return await this._axiosInstance.put(
            `/team/${teamUuid}/member/roles`,
            { memberUuid: memberUuid, roles: roles }
        );
    }

    /**
     * Get product details
     * @param uuid
     * @returns
     */
    @dtoToModel(ProductDto)
    @httpErrorHandle
    public async getProduct(uuid: string): Promise<Product> {
        return await this._axiosInstance.get(`/product/${uuid}`);
    }

    /**
     * Get product list by type
     * @param page
     * @param itemsPerPage
     * @returns
     */
    @paginatedDtoToPaginatedModel(ProductDto)
    @httpErrorHandle
    public async getProductListbyType(
        type: string,
        page: number,
        itemsPerPage: number
    ): Promise<Paginated<Product>> {
        return await this._axiosInstance.get(`/product/${type}/list`, {
            params: {
                itemsPerPage: itemsPerPage,
                page: page,
            },
        });
    }

    /**
     * Get orders placed by user
     * @param page
     * @param itemsPerPage
     * @returns
     */
    @paginatedDtoToPaginatedModel(OrderDto)
    @httpErrorHandle
    public async getUserOrderList(
        page: number,
        itemsPerPage: number
    ): Promise<any> {
        return await this._axiosInstance.get(`/user/order/list`, {
            params: {
                itemsPerPage: itemsPerPage,
                page: page,
            },
        });
    }

    /**
     * Get order details
     * @param uuid
     * @returns
     */
    @dtoToModel(OrderDto)
    @httpErrorHandle
    public async getOrder(uuid: string): Promise<Order> {
        return await this._axiosInstance.get(`/order/${uuid}`);
    }

    /**
     * Create new order
     * @param payload
     * @returns
     */
    @httpErrorHandle
    @prepareDtoPayload(OrderDto)
    public async createOrder(payload: any): Promise<OrderCreationDto> {
        return await this._axiosInstance.post(`/order`, payload);
    }

    /**
     * Cancel order (when stripe checkout session is still open)
     * @param payload
     * @returns
     */
    @httpErrorHandle
    public async cancelOrder(orderUuid: any): Promise<any> {
        return await this._axiosInstance.put(`/order/${orderUuid}/cancel`);
    }

    /**
     * Update licence status
     * @param licenceUuid
     * @param status
     * @returns
     */
    @httpErrorHandle
    public async updateLicenceStatus(
        licenceUuid: string,
        status: string
    ): Promise<any> {
        return await this._axiosInstance.put(
            `/licence/${licenceUuid}/status`,
            { status: status }
        );
    }

    /**
     * Delete licence
     * @param licenceUuid
     * @returns
     */
    @dtoToModel(LicenceDto)
    @httpErrorHandle
    public async deleteLicence(licenceUuid: string): Promise<Licence | any> {
        return await this._axiosInstance.delete(`/licence/${licenceUuid}`);
    }

    /**
     * Get order statistics
     * @returns
     */
    @dtoToModel(OrderStatDto)
    @httpErrorHandle
    public async getOrderStats(): Promise<OrderStat> {
        return await this._axiosInstance.get(`/dashboard/stats/orders`);
    }

    /**
     * Get labs statistics
     * @returns
     */
    @dtoToModel(TeamStatDto)
    @httpErrorHandle
    public async getTeamStats(): Promise<TeamStat> {
        return await this._axiosInstance.get(`/dashboard/stats/teams`);
    }

    /**
     * Get team statistics
     * @returns
     */
    @dtoToModel(LabStatDto)
    @httpErrorHandle
    public async getLabStats(): Promise<LabStat> {
        return await this._axiosInstance.get(`/dashboard/stats/lab`);
    }

    /**
     * Get recording statistics
     * @returns
     */
    @dtoToModel(RecordingStatDto)
    @httpErrorHandle
    public async getRecordingStats(
        param: UrlParamType
    ): Promise<RecordingStat> {
        return await this._axiosInstance.get(
            `/dashboard/stats/recordings/${param}`
        );
    }

    /**
     * Get recording statistics
     * @returns
     */
    @dtoToModel(RecordingStatDto)
    @httpErrorHandle
    public async getUserRecordingStats(): Promise<RecordingStat> {
        return await this._axiosInstance.get(
            `/dashboard/stats/recordings/me`
        );
    }

    /**
     * Get subscription plans
     * @returns
     */
    @dtoToModel(SubscriptionPlanDto)
    @httpErrorHandle
    public async getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
        return await this._axiosInstance.get(`/subscription/plan/list`);
    }

    /**
     * Subscribe, the backend will generate a licence automatically for the user
     * who subscribed to the plan
     * @returns
     */
    @dtoToModel(LicenceDto)
    @httpErrorHandle
    public async subscribe(planUuid: string): Promise<Licence> {
        return await this._axiosInstance.post(`/subscription`, {
            planUuid: planUuid,
        });
    }

    /**
     * Get licence list related to subscription
     * @param owned
     * @param page
     * @param itemsPerPage
     * @returns
     */
    @paginatedDtoToPaginatedModel(LicenceDto)
    @httpErrorHandle
    public async getSubscriptionLicencesList(
        subscriptionUuid: string,
        page: number,
        itemsPerPage: number
    ): Promise<Paginated<Licence>> {
        return await this._axiosInstance.get(
            `/subscription/${subscriptionUuid}/licence/list`,
            {
                params: {
                    itemsPerPage: itemsPerPage,
                    page: page,
                },
            }
        );
    }

    /**
     * List of LAU licences who do not own a lab
     *
     * @param subscriptionUuid
     * @returns
     */
    @dtoToModel(LicenceDto)
    @httpErrorHandle
    public async getAvailableLicenceAdminList(
        subscriptionUuid: string,
        teamUuid?: string
    ): Promise<Licence[]> {
        return await this._axiosInstance.get(
            `/subscription/${subscriptionUuid}/licence/admin/available/list`,
            {
                params: {
                    teamUuid: teamUuid,
                },
            }
        );
    }

    /**
     * Get teams related to subscription
     *
     * @param subscriptionUuid
     * @returns
     */
    @paginatedDtoToPaginatedModel(TeamDto)
    @httpErrorHandle
    public async getSubscriptionTeamList(
        subscriptionUuid: string,
        page: number,
        itemsPerPage: number
    ): Promise<Paginated<Team>> {
        return await this._axiosInstance.get(
            `/subscription/${subscriptionUuid}/team/list`,
            {
                params: {
                    itemsPerPage: itemsPerPage,
                    page: page,
                },
            }
        );
    }

    /**
     * Get licence list related to a user
     * @param page
     * @param itemsPerPage
     * @returns
     */
    @paginatedDtoToPaginatedModel(LicenceDto)
    @httpErrorHandle
    public async getLicencesList(
        page: number,
        itemsPerPage: number
    ): Promise<Paginated<Licence>> {
        return await this._axiosInstance.get(`/user/licence/list`, {
            params: {
                itemsPerPage: itemsPerPage,
                page: page,
            },
        });
    }

    /**
     * Add users to subscription
     * @param subscriptionUuid
     * @param emails
     * @returns
     */
    @dtoToModel(UserSubscriptionReportDto)
    @httpErrorHandle
    public async addUsersToSubscription(
        subscriptionUuid: string,
        emails: string[]
    ): Promise<UserSubscriptionReport> {
        return await this._axiosInstance.post(
            `/subscription/${subscriptionUuid}/licence/add`,
            { emails: emails }
        );
    }

    /**
     * Get assigned licence
     * @returns
     */
    @dtoToModel(LicenceDto)
    @httpErrorHandle
    public async getAssignedLicence(): Promise<Licence> {
        return await this._axiosInstance.get(`/user/licence/assigned`);
    }

    /**
     * Get licences filtered by role and subscription
     * @returns
     */
    @dtoToModel(LicenceDto)
    @httpErrorHandle
    public async getLicencesBySubscriptionAndRole(
        subscriptionUuid: string,
        role: string,
        filters?: any
    ): Promise<Licence[]> {
        return await this._axiosInstance.get(
            `/subscription/${subscriptionUuid}/licence/list/${role}`,
            {
                params: filters
                    ? {
                          filters: filters,
                      }
                    : {},
            }
        );
    }

    /**
     * Update licence role
     * @param licenceUuid
     * @param role
     * @returns
     */
    @httpErrorHandle
    public async updownLicenceRole(
        licenceUuid: string,
        role: string
    ): Promise<any> {
        return await this._axiosInstance.put(
            `/licence/${licenceUuid}/role`,
            { role: role }
        );
    }

    /**
     * Get currencies from system
     *
     * @returns currency code array
     */
    @httpErrorHandle
    public async getCurrencies(): Promise<string[]> {
        return this._axiosInstance.get(`/currency/list`);
    }

    /**
     * Get media for product
     *
     * @returns media array
     */
    @dtoToModel(MediaDto)
    @httpErrorHandle
    public async getProductMedia(uuid: string): Promise<Media[]> {
        return this._axiosInstance.get(`/media/product/${uuid}`);
    }
}
