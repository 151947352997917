import classes from "../common.module.css";
import formClasses from "./VirtualLabForm.module.css";
import {
    VirtualLabFormProvider,
    useVirtualLabForm,
} from "./formVirtualLab-context";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
    Text,
    Title,
    TextInput,
    Box,
    Select,
    Space,
    Group,
} from "@mantine/core";
import { formularRef } from "../../common/Wrapper";
import { useRest } from "../../../features/context/RestContext";
import { VirtualLabEditProps } from "../Interfaces";
import { AnimalType } from "../../../lib/Enums";
import { useErrorAlertHandler } from "../../../features/hooks/useErrorHandler";
import TextEditor from "../../common/TextEditor";
import { Licence, LicenceRoleEnum } from "../../../models/Licence";
import { useData } from "../../../features/context/DataContext";
import { useTeamData } from "../../../features/context/TeamContext";
import { BarLoader } from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { useLabCountHandler } from "../../../features/hooks/useLabCountHandler";
import { useUserFeatureAccessHandler } from "../../../features/hooks/useUserFeatureAccessHandler";

const VirtualLabEditGrid = forwardRef<formularRef, VirtualLabEditProps>(
    ({ editMode, setLoaderVisibleCB }, ref) => {
        const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
        const [adminLicences, setAdminLicences] = useState<Licence[]>([]);
        const { assignedLicence } = useData();
        const { currentTeam, setTeamData } = useTeamData();
        const { isRole } = useUserFeatureAccessHandler();

        const intl = useIntl();
        const { restApiService } = useRest();
        const handleError = useErrorAlertHandler();
        const navigate = useNavigate();
        const handleLabCount = useLabCountHandler();

        const form = useVirtualLabForm({
            validateInputOnBlur: true,
            initialValues: {
                name: currentTeam?.name ?? "",
                description: currentTeam?.description ?? "",
                defaultAnimalType: currentTeam?.defaultAnimalType ?? "",
                ownerUuid: currentTeam?.owner?.uuid ?? "",
            },
            validate: {},
        });

        const handleResult = (isUpdateRequest: boolean) => {
            if (!isUpdateRequest) {
                handleLabCount(1);
                navigate(`/team`);
            }
        };

        const handleSubmit = async () => {
            const currentTeamUuid: string = currentTeam?.uuid ?? "";

            try {
                if (editMode && !currentTeamUuid)
                    throw new Error("Team not found");
                setLoaderVisibleCB(true);
                const updatedTeam = editMode
                    ? await restApiService?.updateTeam(
                          currentTeamUuid,
                          form.values
                      )
                    : await restApiService?.createTeam(form.values);
                setTeamData((prev) => ({
                    ...prev,
                    currentTeam: updatedTeam,
                    teamCtxRefreshTS: Date.now(),
                }));
                handleResult(editMode);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisibleCB(false);
            }
        };

        const getAvailableAdminLicences = async () => {
            try {
                const subscriptionUuid = assignedLicence?.subscription.uuid;
                if (!subscriptionUuid)
                    throw new Error("Subscription not loaded");

                setLoaderVisible(true);
                const data: Licence[] =
                    (await restApiService?.getAvailableLicenceAdminList(
                        subscriptionUuid,
                        currentTeam?.uuid
                    )) ?? [];
                setAdminLicences(data);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisible(false);
            }
        };

        useEffect(() => {
            getAvailableAdminLicences();
        }, []);

        useImperativeHandle(ref, () => ({ handleSubmit }));

        return (
            <VirtualLabFormProvider form={form}>
                <BarLoader visible={loaderVisible} />
                {!loaderVisible && (
                    <form>
                        <Title className={classes.title}>
                            <FormattedMessage
                                id="app.virtualLab.name"
                                defaultMessage="Lab name"
                            />
                        </Title>
                        <TextInput
                            style={{
                                width: "50%",
                            }}
                            classNames={{
                                root: formClasses.root,
                            }}
                            placeholder={intl.formatMessage({
                                id: "app.virtualLab.name.placeholder",
                            })}
                            error={intl.formatMessage({
                                id: "app.formLabel.email.error",
                            })}
                            {...form.getInputProps("name")}
                        />
                        {currentTeam?.membersCount && (
                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                <FormattedMessage
                                    id="app.virtualLab.members.total"
                                    defaultMessage="Total: {count} member(s)"
                                    values={{ count: currentTeam.membersCount }}
                                />
                            </Text>
                        )}
                        <Space h="xl" />
                        <Box
                            style={{
                                marginBottom: 50,
                            }}
                        >
                            <Title className={classes.title} order={2}>
                                <FormattedMessage
                                    id="app.virtualLab.whatWeDo"
                                    defaultMessage="What we do"
                                />
                            </Title>
                            <TextEditor />
                            <Space h="xl" />
                            <Title className={classes.title} order={2}>
                                <FormattedMessage
                                    id="app.virtualLab.defaultAnimalType"
                                    defaultMessage="Default animal type"
                                />
                            </Title>
                            <Select
                                className={formClasses.select}
                                placeholder={intl.formatMessage({
                                    id: "app.label.pickValue",
                                })}
                                {...form.getInputProps("defaultAnimalType")}
                                defaultValue={currentTeam?.defaultAnimalType}
                                data={Object.values(AnimalType)}
                            />
                            <Space h="xl" />
                            {isRole(LicenceRoleEnum.OWNER_ROLE) && (
                                    <>
                                        <Group>
                                            <Title
                                                className={classes.title}
                                                order={2}
                                            >
                                                <FormattedMessage
                                                    id="app.virtualLab.owner"
                                                    defaultMessage="Lab Owner"
                                                />
                                            </Title>
                                            <FormattedMessage id="app.virtualLab.owner.add.advice" />
                                        </Group>
                                        {adminLicences.length > 0 ? (
                                            <Select
                                                className={formClasses.select}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "app.label.pickValue",
                                                    }
                                                )}
                                                {...form.getInputProps(
                                                    "ownerUuid"
                                                )}
                                                defaultValue={
                                                    currentTeam?.owner?.uuid
                                                }
                                                data={adminLicences.map(
                                                    (licence) => ({
                                                        value: licence.assignee
                                                            .uuid,
                                                        label: licence.assignee
                                                            .email,
                                                    })
                                                )}
                                            />
                                        ) : (
                                            <>
                                                <Text c="red" size="sm">
                                                    <FormattedMessage
                                                        id="app.virtualLab.warning.noAdminLicence"
                                                        defaultMessage="You cannot choose an owner as there are no admin licences found, please assign admin licences in your subscription page."
                                                    />
                                                </Text>
                                                <Text c="red" size="sm">
                                                    <FormattedMessage
                                                        id="app.virtualLab.warning.youWillBeOwner"
                                                        defaultMessage="You will be automatically set as the Lab owner."
                                                    />
                                                </Text>
                                            </>
                                        )}
                                    </>
                                )}
                        </Box>
                    </form>
                )}
            </VirtualLabFormProvider>
        );
    }
);

export default VirtualLabEditGrid;
