import { Licence } from "../../models/Licence";
import { Membership } from "../../models/Team";
import { useAuth } from "../context/AuthContext";
import { useData } from "../context/DataContext";
import { useRest } from "../context/RestContext";

export const CLEAR_DATA = "clearData";
export const UPDATE_LICENCE = "updateLicence";
export const ENABLE_PERSIST = "enablePersist";

const useDataUpdateHandler = (key: string) => {
    const { restApiService } = useRest();
    const { setCurrentData, setDataPersist } = useData();
    const { setCurrentAuth, setUserPersist } = useAuth();

    const updateLicence = async () => {
        try {
            const data: Licence | undefined =
                await restApiService?.getAssignedLicence();
            setCurrentData((prevData) => ({
                ...prevData,
                assignedLicence: data,
            }));
        } catch (error: any) {
            throw error;
        }
    };

    const clearData = async () => {
        setDataPersist(false);
        setUserPersist(false);
        setCurrentData({
            memberships: new Map<string, Membership>(),
            assignedLicence: undefined,
            orderStats: undefined,
            company: undefined,
        });
        setCurrentAuth({ user: null });
    }

    const enablePersist = () => {
        setDataPersist(true);
        setUserPersist(true);
    }

    // Determine which update function to return based on the key
    const updateFunctions = new Map<string, Function>();
    updateFunctions.set(UPDATE_LICENCE, updateLicence);
    updateFunctions.set(CLEAR_DATA, clearData);
    updateFunctions.set(ENABLE_PERSIST, enablePersist);

    return updateFunctions.get(key) ?? (() => {});
};

export default useDataUpdateHandler;