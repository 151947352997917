export const defaultConfig = {
    ALLOWED_TAGS: ["b", "i", "em", "p", "strong"],
    ALLOWED_ATTR: [],
};

export const config = {
    ALLOWED_TAGS: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "li",
        "a",
        ...defaultConfig.ALLOWED_TAGS,
    ],
    ALLOWED_ATTR: ["href", "target", ...defaultConfig.ALLOWED_ATTR],
};
