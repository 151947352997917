import { Avatar, AvatarProps, Group, Stack, Text } from "@mantine/core";
import { User } from "../../models/User";
import { IconCrown } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";

interface UserAvatarProps {
    user: User;
    avatarProps?: AvatarProps;
}

interface UserCellProps {
    user?: User | undefined;
    email?: string;
    isAdmin?: boolean;
}

export function UserAvatar({ user, avatarProps }: UserAvatarProps) {
    return (
        <Avatar
            {...avatarProps}
            src={user.profile?.avatarUrl}
            alt={
                user.profile
                    ? `${user.profile.firstName} ${user.profile.lastName}`
                    : ""
            }
        >
            {user.profile
                ? user.profile.firstName.charAt(0).toUpperCase() +
                  user.profile.lastName.charAt(0).toUpperCase()
                : ""}
        </Avatar>
    );
}

export const UserCell = ({ user, email, isAdmin }: UserCellProps) => {
    if (user) {
        return (
            <Group>
                <Stack gap="xs" align="center">
                    {isAdmin && (
                        <IconCrown size={14} />
                    )}
                    <UserAvatar user={user} avatarProps={{ size: 40 }} />
                </Stack>
                <Stack gap="xs">
                    <Text>{user.nickname}</Text>
                    {user.profile && (
                        <Text size="xs">
                            {user.profile.lastName} {user.profile.firstName}
                        </Text>
                    )}
                    <Text size="xs">{user.email}</Text>
                </Stack>
            </Group>
        );
    }
    if (email) {
        return (
            <Group>
                <Avatar size={40} alt={email}></Avatar>
                <Stack gap="xs">
                    <Text size="xs">{email}</Text>
                </Stack>
            </Group>
        );
    }

    return <FormattedMessage id="app.label.none" />;
};
