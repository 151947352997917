import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import '@mantine/carousel/styles.css';
import { theme, resolver } from "./styles/theme";
import { IntlProvider } from "./features/context/IntlContext";
import { ModalsProvider } from "@mantine/modals";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import App from "./App";
import ErrorPage from "./pages/error/errorPage";
import SignUp from "./pages/SignUp/SignUp";
import SignUpSuccess from "./pages/SignUp/Success";
import Dashboard from "./pages/Dashboard/Dashboard";
import Account from "./pages/Account/Account";
import Settings from "./pages/Settings/Settings";
import Logout from "./pages/Logout/Logout";
import VirtualLabListPage from "./pages/VirtualLab/VirtualLabList";
import { AlertProvider } from "./features/context/AlertContext";
import { useAuth } from "./features/context/AuthContext";
import { EmailNotVerified } from "./pages/Warning/EmailNotVerifiedPage";
import VirtualLabPage from "./pages/VirtualLab/VirtualLab";
import NewVirtualLabPage from "./pages/VirtualLab/NewVirtualLab";
import Shop from "./pages/Shop/Shop";
import SubscriptionPage from "./pages/Subscription/Subscription";
import PasswordForgot from "./pages/Password/PasswordForgot";
import PasswordReset from "./pages/Password/PasswordReset";
import OrderDetailsPage from "./pages/Orders/Order";
import SubscriptionPlanPage from "./pages/Subscription/SubscriptionPlan";
import { TeamDataProvider } from "./features/context/TeamContext";
import InviteePage from "./pages/Invitation/Invitation";
import InvitationPasswordPage from "./pages/Invitation/Password";
import { useEffect, useState } from "react";
import { ShoppingCartProvider } from "./features/context/ShoppingCartContext";

function AppRoutes() {
    const { user, loading } = useAuth();
    const [canRun, setCanRun] = useState<boolean>(true);

    // TODO: change authenticated condition
    const isAuthenticated = user !== null && user.isVerified === true;

    useEffect(() => {
        if (!("indexedDB" in window)) {
            // IndexedDB is not supported in this browser
            alert(
                "IndexedDB Not Supported, your browser does not support IndexedDB, which is required for this application to function correctly. Please update your browser or use a different one."
            );
            setCanRun(false);
        }
    }, []);

    const routesData = [
        {
            path: "/",
            element: isAuthenticated ? (
                <App />
            ) : (
                <Navigate replace to={"/login"} />
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <Navigate to="/dashboard" replace />,
                },
                {
                    path: "dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "account",
                    element: <Account />,
                },
                {
                    path: "subscription",
                    element: <SubscriptionPage />,
                },
                {
                    path: "subscription/plan",
                    element: <SubscriptionPlanPage />,
                },
                {
                    path: "team",
                    element: <VirtualLabListPage />,
                },
                {
                    path: "team/new",
                    element: <NewVirtualLabPage />,
                },
                {
                    path: "team/:teamUuid",
                    element: (
                        <TeamDataProvider>
                            <VirtualLabPage />
                        </TeamDataProvider>
                    ),
                },
                {
                    path: "order/:orderUuid",
                    element: <OrderDetailsPage />,
                },
                {
                    path: "shop",
                    element: (
                        <ShoppingCartProvider
                            userCountryISO={
                                user?.profileAddress?.billing?.countryISO ?? ""
                            }
                        >
                            <Shop />
                        </ShoppingCartProvider>
                    ),
                },
                {
                    path: "settings",
                    element: <Settings />,
                },
            ],
        },
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "invitation/:invitationUuid",
            element: <InviteePage />,
        },
        {
            path: "invitation/:invitationUuid/password",
            element: <InvitationPasswordPage />,
        },
        {
            path: "signup",
            element: <SignUp />,
        },
        {
            path: "password-forgot",
            element: <PasswordForgot />,
        },
        {
            path: "password-reset/:token",
            element: <PasswordReset />,
        },
        {
            path: "signup-success",
            element: <SignUpSuccess />,
        },
        {
            path: "verify-email",
            element: <EmailNotVerified />,
        },
        {
            path: "logout",
            element: <Logout />,
        },
    ];

    const router = createBrowserRouter(routesData);

    if (!canRun) {
        return <>Please update your browser</>;
    }

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <IntlProvider>
            <MantineProvider theme={theme} cssVariablesResolver={resolver}>
                <ModalsProvider>
                    <Notifications />
                    <AlertProvider>
                        <RouterProvider router={router} />
                    </AlertProvider>
                </ModalsProvider>
            </MantineProvider>
        </IntlProvider>
    );
}

export default AppRoutes;
