import {
    Licence,
    LicenceRoleEnum,
    LicenceStatusEnum,
} from "../../models/Licence";
import { SubscriptionPlanType } from "../../models/Subscription";
import { useAuth } from "../context/AuthContext";
import { useData } from "../context/DataContext";

export enum Feature {
    VirtualLab,
    Subscription,
    Recording,
}

export enum Action {
    Create,
    Edit,
    Delete,
    View,
    ViewAdmin,
    ViewOwner,
}

const LICENCE_ADMIN_ROLES: LicenceRoleEnum[] = [
    LicenceRoleEnum.ADMIN_ROLE,
    LicenceRoleEnum.OWNER_ROLE,
];

export const useUserFeatureAccessHandler = () => {
    const { user } = useAuth();
    const { assignedLicence, memberships } = useData();

    const _getSubscriptionType = (licence: Licence): SubscriptionPlanType => {
        return licence.subscription?.plan.type;
    };

    const _isAdminRole = (licence: Licence): boolean => {
        return licence.roles?.some((role) =>
            LICENCE_ADMIN_ROLES.includes(role.value as LicenceRoleEnum)
        );
    };

    const _allowVirtualLabAction = (
        action: Action,
        assignedLicence: Licence
    ) => {
        const subscriptionType: SubscriptionPlanType =
            _getSubscriptionType(assignedLicence);
        const isAdmin = _isAdminRole(assignedLicence);
        const hasMemberShips = memberships ? memberships.size > 0 : false;

        if (subscriptionType === SubscriptionPlanType.BASIC) return false;

        switch (action) {
            case Action.View:
                return isAdmin || hasMemberShips;
            case Action.Create:
                return (
                    isRole(LicenceRoleEnum.OWNER_ROLE) ||
                    (isRole(LicenceRoleEnum.ADMIN_ROLE) && !hasMemberShips)
                );
            case Action.Edit:
            case Action.Delete:
                return isAdmin;
            default:
                return false;
        }
    };

    const _allowSubscriptionAction = (
        action: Action,
        assignedLicence: Licence
    ) => {
        const subscriptionType: SubscriptionPlanType =
            _getSubscriptionType(assignedLicence);
        const isAdmin = _isAdminRole(assignedLicence);

        switch (action) {
            case Action.ViewOwner:
                return (
                    subscriptionType !== SubscriptionPlanType.BASIC &&
                    isRole(LicenceRoleEnum.OWNER_ROLE)
                );
            case Action.ViewAdmin:
            case Action.Create:
            case Action.Edit:
            case Action.Delete:
                return (
                    subscriptionType !== SubscriptionPlanType.BASIC && isAdmin
                );
            default:
                return false;
        }
    };

    const _allowRecordingAction = (
        action: Action,
        assignedLicence: Licence
    ) => {
        const subscriptionType: SubscriptionPlanType =
            _getSubscriptionType(assignedLicence);
        const isAdmin = _isAdminRole(assignedLicence);

        switch(action) {
            case Action.View:
                return subscriptionType !== SubscriptionPlanType.BASIC;
            case Action.Create:
            case Action.Edit:
            case Action.Delete:
                return isAdmin;
            default:
                return false;
        }
    };

    // Populated functions
    const isRole = (role: LicenceRoleEnum): boolean => {
        
        if (!assignedLicence) return false;

        return (
            assignedLicence.roles?.some((r) => r.value === role)
        );
    };

    const isUnassignedUser = () => {
        return user && !assignedLicence;
    };

    const isAccessAllowed = (feature: Feature, action?: Action) => {
        if (
            !user ||
            !assignedLicence ||
            assignedLicence.status.value !== LicenceStatusEnum.ACTIVE
        ) {
            return false;
        }

        if (action === undefined) {
            return true;
        }

        switch (feature) {
            case Feature.VirtualLab:
                return _allowVirtualLabAction(action, assignedLicence);
            case Feature.Subscription:
                return _allowSubscriptionAction(action, assignedLicence);
            case Feature.Recording:
                return _allowRecordingAction(action, assignedLicence);
            default:
                return false;
        }
    };

    return { isAccessAllowed, isRole, isUnassignedUser };
};
