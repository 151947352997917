import globalClasses from "../../styles/global.module.css";
import { Card, Divider, Space } from "@mantine/core";
import {
    AddressInfo,
    CompanyInfo,
    UserProfileInfo,
} from "../../components/Account/Info";
import UserCredentialsGrid from "../../components/Account/Details/UserCredentialsGrid";
import { ProfileProgressBar } from "../../components/Account/ProfileProgress";
import { useAuth } from "../../features/context/AuthContext";
import { PageContainer } from "../../components/common/Page";
import { useUserFeatureAccessHandler } from "../../features/hooks/useUserFeatureAccessHandler";
import { LicenceRoleEnum } from "../../models/Licence";

function Account() {
    const { user } = useAuth();
    if (!user) return <div>No user available</div>;

    const { isRole, isUnassignedUser } = useUserFeatureAccessHandler();
    
    return (
            <PageContainer
                titleId="app.profile.title"
                defaultTitle="My Profile"
            >
                <Card className={globalClasses.card} withBorder radius="md" padding="md">
                    <ProfileProgressBar />
                    <Divider my="xl" />
                    <UserCredentialsGrid />
                </Card>
                {(isRole(LicenceRoleEnum.OWNER_ROLE) || isUnassignedUser()) && (
                    <>
                        <Space h="xl" />
                        <Card className={globalClasses.card} withBorder radius="md" padding="md">
                            <CompanyInfo />
                        </Card>
                    </>
                )}
                <Space h="xl" />
                <Card className={globalClasses.card} withBorder radius="md" padding="md">
                    <UserProfileInfo />
                </Card>
                <Space h="xl" />
                <Card className={globalClasses.card} withBorder radius="md" padding="md">
                    <AddressInfo />
                </Card>
            </PageContainer>
    );
}

export default Account;
