import classes from "./ProductDetailsModal.module.css";
import carouselClasses from "./Carousel.module.css";
import Autoplay from "embla-carousel-autoplay";
import DOMPurify from "dompurify";
import { config as purifyConfig } from "../../../config/dompurify";
import {
    Center,
    Image,
    Box,
    Modal,
    ModalProps,
    Text,
    Flex,
    ScrollArea,
    Button,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useRef, useEffect, useState } from "react";
import { useRest } from "../../../features/context/RestContext";
import { Product } from "../../../models/Product";
import { CurrencyCode } from "../../../lib/Constants";
import { useAuth } from "../../../features/context/AuthContext";
import { BarLoader } from "../../common/Loader";
import { Media } from "../../../models/Media";
import { IconArrowBackUp } from "@tabler/icons-react";

interface ProductDetailsModalProps {
    productUuid: string;
    modalProps: ModalProps;
    closeCB: () => void;
}

export default function ProductDetailsModal({
    productUuid,
    modalProps,
    closeCB,
}: ProductDetailsModalProps) {
    const [product, setProduct] = useState<Product | null>(null);
    const [media, setMedia] = useState<Media[] | null>(null);
    const { user } = useAuth();
    const { restApiService } = useRest();
    const autoplay = useRef(Autoplay({ delay: 4000 }));

    const getData = async () => {
        try {
            const [product, productMedia] = await Promise.all([
                restApiService?.getProduct(productUuid) ?? null,
                restApiService?.getProductMedia(productUuid) ?? null,
            ]);
            setMedia(productMedia);
            setProduct(product);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setProduct(null);
        getData();
    }, [productUuid]);

    return (
        <>
            <Modal
                className={classes.container}
                removeScrollProps={{ removeScrollBar: true }}
                {...modalProps}
            >
                <Box className={classes.content}>
                    {product ? (
                        <Flex align="start" gap="lg">
                            <Carousel
                                classNames={carouselClasses}
                                withIndicators
                                loop
                                slideGap="md"
                                slideSize="100%"
                                plugins={[autoplay.current]}
                                onMouseEnter={autoplay.current.stop}
                                onMouseLeave={autoplay.current.reset}
                            >
                                <Carousel.Slide>
                                    <Flex justify="center" align="center">
                                        <Image
                                            src={product.imageUrl}
                                            alt={`${product.name} - 1`}
                                        />
                                    </Flex>
                                </Carousel.Slide>
                                {media &&
                                    media.map((m) => {
                                        return (
                                            <Carousel.Slide key={m.uuid}>
                                                <Flex
                                                    justify="center"
                                                    align="center"
                                                >
                                                    <Image
                                                        className={
                                                            classes.image
                                                        }
                                                        src={m.url}
                                                        alt={`${product.name} - 1`}
                                                    />
                                                </Flex>
                                            </Carousel.Slide>
                                        );
                                    })}
                            </Carousel>
                            <Box className={classes.descriptionContainer}>
                                <ScrollArea
                                    className={classes.scrollArea}
                                    h={400}
                                >
                                    <Box
                                        className={classes.description}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                product.description,
                                                purifyConfig
                                            ),
                                        }}
                                    />
                                </ScrollArea>
                                <Text className={classes.price}>
                                    {user?.profile?.transactionsCurrencyCode}{" "}
                                    {product.price}
                                    {user?.profile?.transactionsCurrencyCode ===
                                        CurrencyCode.CHF && ".-"}
                                </Text>
                                <Button onClick={closeCB}>
                                    <IconArrowBackUp stroke={2} />
                                </Button>
                            </Box>
                        </Flex>
                    ) : (
                        <Center h="100%">
                            <BarLoader visible={true} />
                        </Center>
                    )}
                </Box>
            </Modal>
        </>
    );
}
