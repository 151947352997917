import classes from "./itemCard.module.css";
import globalClasses from "../../../styles/global.module.css";
import { Avatar, Text, Button, Paper } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface ItemCardProps {
    title: string;
    description: string;
    avatarUrl?: string | null;
    creationDate?: Date | null;
    detailsLink?: string;
}

export function ItemCard({
    title,
    description,
    avatarUrl,
    creationDate,
    detailsLink: link,
}: ItemCardProps) {

    // Remove potential HTML tags from description
    const regex = /(<([^>]+)>)/gi;
    const cleanDescription = description.replace(regex, "");

    return (
        <Paper radius="md" withBorder p="lg" bg="var(--mantine-color-body)">
            <Avatar
                src={avatarUrl}
                size={120}
                radius={120}
                mx="auto"
            >
                {!avatarUrl && title.charAt(0).toUpperCase()}
            </Avatar>
            <Text ta="center" fz="lg" fw={500} mt="md">
                {title}
            </Text>
            <Text
                className={`${classes.description} ${globalClasses.textSummary}`}
                ta="center"
                c="dimmed"
                fz="sm"
            >
                {cleanDescription}
            </Text>
            <Text ta="center" c="dimmed" fz="sm">
                {creationDate?.toDateString()}
            </Text>
            {link && (
                <Link to={link} className={globalClasses.link}>
                    <Button variant="default" fullWidth mt="md">
                        <FormattedMessage
                            id="app.button.viewDetails"
                            defaultMessage="View details"
                        />
                    </Button>
                </Link>
            )}
        </Paper>
    );
}
