import { useMemo } from "react";
import { useData } from "../context/DataContext";
import { useUserFeatureAccessHandler } from "./useUserFeatureAccessHandler";
import { LicenceRoleEnum } from "../../models/Licence";

interface IStats {
    ratio: number;
    max: number;
    left: number;
    created: number;
}

export interface ISubscriptionStats {
    users: IStats;
    labs: IStats;
}

export const useSubscriptionStats = (): ISubscriptionStats => {
    const { assignedLicence } = useData();
    const { isRole } = useUserFeatureAccessHandler();

    const maxUsers = assignedLicence?.subscription?.plan?.maxUsers;
    const licencesCount = assignedLicence?.subscription?.licencesCount;
    const maxLabs = assignedLicence?.subscription?.plan?.maxVirtualLabs ?? 0;
    const createdLabs = assignedLicence?.subscription?.labsCount ?? 0;

    const usersLeft = useMemo(() => {
        return (
            (assignedLicence?.subscription?.plan?.maxUsers ?? 0) -
            (assignedLicence?.subscription?.licencesCount ?? 0)
        );
    }, [
        assignedLicence?.subscription?.plan?.maxUsers,
        assignedLicence?.subscription?.licencesCount,
    ]);

    const usersRatio = useMemo(() => {
        if (!maxUsers || !licencesCount) return 0;

        return licencesCount / maxUsers;
    }, [maxUsers, licencesCount]);

    const labsLeft = useMemo(() => {
        if (!assignedLicence) return 0;
        
        const maxLabs = assignedLicence.subscription?.plan?.maxVirtualLabs ?? 0;
        const createdLabs = assignedLicence?.subscription?.labsCount ?? 0;

        // Max labs for owner, one lab for admin, 0 for others
        if (isRole(LicenceRoleEnum.OWNER_ROLE)) {
            return maxLabs - createdLabs;
        }
        else if (isRole(LicenceRoleEnum.ADMIN_ROLE)) {
            return maxLabs - createdLabs > 0 ? 1 : 0;
        }
        else {
            return 0;
        };
    }, [
        assignedLicence?.subscription?.plan?.maxVirtualLabs,
        assignedLicence?.subscription?.labsCount,
    ]);

    const labsRatio = useMemo(() => {
        if (!maxLabs || !createdLabs) return 0;

        return createdLabs / maxLabs;
    }, [maxLabs, createdLabs]);

    return {
        users: {
            ratio: usersRatio,
            max: maxUsers ?? 0,
            left: usersLeft,
            created: licencesCount ?? 0,
        },
        labs: {
            ratio: labsRatio,
            max: maxLabs ?? 0,
            left: labsLeft,
            created: createdLabs ?? 0,
        },
    };
};
