import { Button, Group, Space } from "@mantine/core";
import { PageContainer } from "../../components/common/Page";
import { Order } from "../../models/Order";
import { useNavigate, useParams } from "react-router-dom";
import { useRest } from "../../features/context/RestContext";
import { useEffect, useState } from "react";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import { BarLoader } from "../../components/common/Loader";
import OrderDetails from "../../components/Shop/Order/OrderDetails";
import { FormattedMessage } from "react-intl";

function OrderDetailsPage() {
    const { orderUuid } = useParams();
    const { restApiService } = useRest();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const handleError = useErrorAlertHandler();
    const [order, setOrder] = useState<Order | undefined>(undefined);
    const navigate = useNavigate();
    
    const getOrderDetails = async () => {
        if (orderUuid) {
            try {
                setLoaderVisible(true);
                const data: Order | undefined = await restApiService?.getOrder(
                    orderUuid
                );
                setOrder(data);
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    useEffect(() => {
        getOrderDetails();
    }, []);

    return (
        <PageContainer
            titleId="app.order.title"
            defaultTitle="Order {reference}"
            values={{ reference: order?.reference }}
        >
            <Group justify="flex-end">
                <Button color="black" variant="filled" onClick={() => navigate(-1)}>
                    <FormattedMessage
                        id="app.order.button.backToShop"
                        defaultMessage="Back to Shop"
                    />
                </Button>
            </Group>
            <Space h="lg" />
            {order && <OrderDetails order={order} />}
            <BarLoader visible={loaderVisible} />
        </PageContainer>
    );
}

export default OrderDetailsPage;
