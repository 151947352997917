import { PageContainer } from "../../components/common/Page";
import { SubscriptionPlan } from "../../models/Subscription";
import { useLocation } from "react-router-dom";
import HeroSection from "../../components/Subscription/Hero";


export default function SubscriptionPlanPage() {
    const location = useLocation();
    const plan: SubscriptionPlan = location.state.plan;

    return (
        <>
            <PageContainer
                titleId="app.subscription"
                defaultTitle="Subscription"
            >
                <HeroSection plan={plan} />
            </PageContainer>
        </>
    );
}
