import { Container, Loader } from "@mantine/core";
import { LoaderProps } from '@mantine/core';

interface ILoaderProps extends LoaderProps {
    visible: boolean;
}

export const BarLoader = ({ visible, ...loaderProps }: ILoaderProps) => {
    return (
        <>
            {visible && (
                <Container>
                    <Loader {...loaderProps} type="bars" />
                </Container>
            )}
        </>
    );
};

export const OvalLoader = ({ visible, ...loaderProps }: ILoaderProps) => {
    return (
        <>
            {visible && (
                <Container>
                    <Loader {...loaderProps} type="oval" />
                </Container>
            )}
        </>
    );
};