import { Type, Expose, plainToClass, instanceToPlain } from "class-transformer";
import { DtoStatic, Model } from "./Common";
import { staticImplements } from "../lib/decorators/Common";

@staticImplements<DtoStatic>()
export class AddressDto {
    @Expose()
    contactEmail!: string;
    @Expose()
    text!: string;
    @Expose()
    countryISO!: string;
    @Expose()
    countryName!: string;

    static toModel(addressDto: AddressDto): Address {
        if (addressDto === undefined) {
            return new Address();
        }
        const data = instanceToPlain(addressDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Address, data);
    }
}

@staticImplements<DtoStatic>()
export class ProfileAddressDto {
    @Expose()
    billingSameAsDelivery!: boolean;
    @Expose()
    @Type(() => AddressDto)
    billing!: AddressDto;
    @Expose()
    @Type(() => AddressDto)
    delivery!: AddressDto;

    static toModel(profileAddressDto: ProfileAddressDto): ProfileAddress {
        if (profileAddressDto === undefined) {
            return new ProfileAddress();
        }
        const data = instanceToPlain(profileAddressDto, {
            excludeExtraneousValues: true,
        });
        const model = plainToClass(ProfileAddress, data);
        return plainToClass(ProfileAddress, data);
    }
}

export class Address implements Model {
    @Expose()
    contactEmail!: string;
    @Expose()
    text!: string;
    @Expose()
    countryISO!: string;
    @Expose()
    countryName!: string;
}

export class ProfileAddress implements Model {
    @Expose()
    billingSameAsDelivery!: boolean;
    @Expose()
    @Type(() => Address)
    billing!: Address;
    @Type(() => Address)
    delivery!: Address;
}
