import classes from "./Icons.module.css";

export const IconSuccess = ({ isAnimated = false }: IconProps) => {

    const checkmarkClass = `${classes.checkmarkBase} ${isAnimated ? classes.checkmark : ''}`;
    const checkmarkCircleClass = `${classes.checkmarkCircleBase} ${isAnimated ? classes.checkmarkCircle : ''}`;
    const checkmarkCheckClass = `${classes.checkmarkCheckBase} ${isAnimated ? classes.checkmarkCheck : ''}`;

    return (
        <svg
            className={checkmarkClass}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
        >
            <circle
                className={checkmarkCircleClass}
                cx="26"
                cy="26"
                r="25"
                fill="none"
            />
            <path
                className={checkmarkCheckClass}
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
        </svg>
    );
};
