import { useState } from "react";
import { useIntl } from "react-intl";
import { useRest } from "../../../features/context/RestContext";
import { TeamMember } from "../../../models/Team";
import { ActionIcon, Tooltip } from "@mantine/core";
import {
    IconCirclesFilled,
    IconTrash,
    IconUser,
    IconUserPause,
} from "@tabler/icons-react";
import { MemberActionProps } from "../Interfaces";
import { useTeamData } from "../../../features/context/TeamContext";
import { LicenceStatusEnum } from "../../../models/Licence";

export function MemberActions({
    member,
    teamUuid,
    openRoleFormCB,
}: MemberActionProps) {
    const intl = useIntl();
    const { setTeamData } = useTeamData();
    const [tmslVisible, setTmslVisible] = useState<boolean>(false);
    const [rmlVisible, setRmlVisible] = useState<boolean>(false);
    const { restApiService } = useRest();

    const toggleMemberStatus = async (member: TeamMember) => {
        if (teamUuid && member?.uuid) {
            try {
                setTmslVisible(true);
                const data: TeamMember[] =
                    await (restApiService?.toggleTeamMemberStatus(
                        teamUuid,
                        member.uuid
                    ) ?? []);
                    setTeamData((prev) => ({
                        ...prev,
                        membersCtxRefreshTS: Date.now(),
                    }));
            } catch (error: any) {
            } finally {
                setTmslVisible(false);
            }
        }
    };

    const removeMember = async (member: TeamMember) => {
        if (teamUuid && member?.uuid) {
            try {
                setRmlVisible(true);
                const data: TeamMember[] =
                    await (restApiService?.removeTeamMember(
                        teamUuid,
                        member.uuid
                    ) ?? []);
                    setTeamData((prev) => ({
                        ...prev,
                        membersCtxRefreshTS: Date.now(),
                    }));
            } catch (error: any) {
                // TODO: show tiny notification instead of alert
            } finally {
                setRmlVisible(false);
            }
        }
    };

    return (
        <ActionIcon.Group>
            <Tooltip
                label={intl.formatMessage({
                    id: "app.virtualLab.tooltip.editRoles",
                })}
            >
                <ActionIcon
                    color="blue"
                    size="1.6rem"
                    disabled={member.licenceStatus !== LicenceStatusEnum.ACTIVE}
                    onClick={() => openRoleFormCB(member)}
                >
                    <IconCirclesFilled size={17} />
                </ActionIcon>
            </Tooltip>
            {member?.isActive ? (
                <Tooltip
                    label={intl.formatMessage({
                        id: "app.virtualLab.tooltip.deactivateMember",
                    })}
                >
                    <ActionIcon
                        color="black"
                        size="1.6rem"
                        onClick={() => toggleMemberStatus(member)}
                        loading={tmslVisible}
                        loaderProps={{ type: "dots" }}
                    >
                        <IconUserPause size={17} />
                    </ActionIcon>
                </Tooltip>
            ) : (
                <Tooltip
                    label={intl.formatMessage({
                        id: "app.virtualLab.tooltip.activateMember",
                    })}
                >
                    <ActionIcon
                        color="green"
                        size="1.6rem"
                        disabled={member.licenceStatus !== LicenceStatusEnum.ACTIVE}
                        onClick={() => toggleMemberStatus(member)}
                        loading={tmslVisible}
                        loaderProps={{ type: "dots" }}
                    >
                        <IconUser size={17} />
                    </ActionIcon>
                </Tooltip>
            )}
            <Tooltip
                label={intl.formatMessage({
                    id: "app.virtualLab.tooltip.removeMember",
                })}
            >
                <ActionIcon
                    color="red"
                    size="1.6rem"
                    onClick={() => removeMember(member)}
                    loading={rmlVisible}
                    loaderProps={{ type: "dots" }}
                >
                    <IconTrash size={17} />
                </ActionIcon>
            </Tooltip>
        </ActionIcon.Group>
    );
}
