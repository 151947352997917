import { FormattedMessage, useIntl } from "react-intl";
import globalClasses from "../../styles/global.module.css";
import classes from "./Invitation.module.css";
import {
    Button,
    Checkbox,
    Container,
    LoadingOverlay,
    Paper,
    PasswordInput,
    Space,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { useState } from "react";
import PasswordStrengthInput from "../../components/common/Inputs/PasswordStrengh";
import { useForm } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../features/context/AuthContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";

interface FormwValues {
    password: string;
    passwordConfirm: string;
    termsOfService: boolean;
}

function InvitationPasswordPage() {
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const { authService } = useAuth();
    const handleError = useErrorAlertHandler();
    const navigate = useNavigate();

    const intl = useIntl();

    const form = useForm<FormwValues>({
        initialValues: {
            password: "",
            passwordConfirm: "",
            termsOfService: false,
        },
        validate: {
            passwordConfirm: (value, values) =>
                value !== values.password
                    ? intl.formatMessage({ id: "app.formLabel.password.error" })
                    : null,
            termsOfService: (value) =>
                value
                    ? null
                    : intl.formatMessage({ id: "app.acceptTerms.error" }),
        },
    });

    const handleSubmit = async () => {
        const { password, passwordConfirm, termsOfService } = form.values;
        const payload = {
            password: password.trim(),
            passwordConfirm: passwordConfirm.trim(),
            termsOfService,
        };

        try {
            setLoaderVisible(true);
            await authService?.setInviteePassword(payload);
            navigate("/signup-success");
        } catch (error: any) {
            const err = error as Error;
            handleError(err);
        }
        finally {
            setLoaderVisible(false);
        }
    };

    return (
        <>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <Container size={420} my={40} className={classes.pageContent}>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <Title ta="center" className={globalClasses.title}>
                        <FormattedMessage
                            id="app.login.welcome"
                            defaultMessage="Welcome to MARTA"
                        />
                    </Title>
                    <Text c="dimmed" size="lg" ta="center" mt={5}>
                        <FormattedMessage
                            id="app.password.pleaseCreate"
                            defaultMessage="Please create a password"
                        />
                    </Text>
                    <Space h="xl" />
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Stack>
                            <PasswordStrengthInput
                                {...form.getInputProps("password", {
                                    value: form.values.password || "",
                                })}
                            />
                            <PasswordInput
                                label={intl.formatMessage({
                                    id: "app.formLabel.password.confirm",
                                })}
                                {...form.getInputProps("passwordConfirm")}
                            />
                        </Stack>
                        <Checkbox
                            label={
                                <>
                                    <Link to={"/terms"}>
                                        <FormattedMessage
                                            id="app.acceptTerms"
                                            defaultMessage="I accept terms and conditions"
                                        />
                                    </Link>
                                </>
                            }
                            mt="sm"
                            {...form.getInputProps("termsOfService", {
                                type: "checkbox",
                            })}
                        />
                        <Button type="submit" fullWidth mt="xl">
                            <FormattedMessage
                                id="app.signup"
                                defaultMessage="Sign up"
                            />
                        </Button>
                    </form>
                </Paper>
            </Container>
        </>
    );
}

export default InvitationPasswordPage;
