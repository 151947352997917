import formClasses from "../../../styles/form.module.css";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, Select, TextInput, Textarea, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { BarLoader } from "../Loader";
import { useAppData } from "../../../features/context/AppDataContext";
import { Country } from "../../../models/Country";

export enum AdressType {
    billing = "billing",
    delivery = "delivery",
}

interface AddressInputsProps {
    form: UseFormReturnType<any>;
    addressType: AdressType;
}

export function AddressInputs({ form, addressType }: AddressInputsProps) {
    const intl = useIntl();
    const { delivery, billing } = form.values;
    const data = addressType === AdressType.billing ? billing : delivery;
    const [loading, setLoading] = useState<boolean>(false);
    const { countries } = useAppData();

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        form.setFieldValue(`${addressType}.text`, event.target.value);
    };

    const handleContactEmailChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        form.setFieldValue(`${addressType}.contactEmail`, event.target.value);
    };

    useEffect(() => {
        setLoading(countries?.length === 0);
    }, [countries, data]);

    return (
        <Grid>
            <Grid.Col span={12}>
                <Title order={3}>
                    <FormattedMessage
                        id={`app.address.${addressType}`}
                        defaultMessage={`${addressType
                            .charAt(0)
                            .toUpperCase()}${addressType.slice(1)} address`}
                    />
                </Title>
            </Grid.Col>
            <Grid.Col span={{sm: 12, md: 3}}>
                <TextInput
                    className={formClasses.field}
                    label={intl.formatMessage({
                        id: "app.address.contactEmail",
                    })}
                    error={intl.formatMessage({
                        id: "app.formLabel.email.error",
                    })}
                    {...form.getInputProps(`${addressType}.contactEmail`)}
                    onChange={handleContactEmailChange}
                />
            </Grid.Col>
            <Grid.Col span={{sm: 12, md: 9}}></Grid.Col>
            <Grid.Col span={{sm: 12, md: 6}}>
                <Textarea
                    className={formClasses.field}
                    label={intl.formatMessage({
                        id: "app.address",
                    })}
                    withAsterisk
                    placeholder={intl.formatMessage({
                        id: "app.address.placeholder",
                    })}
                    {...form.getInputProps(`${addressType}.text`)}
                    onChange={handleTextChange}
                />
            </Grid.Col>
            <Grid.Col span={{sm: 12, md: 3}}>
                <BarLoader visible={loading} />
                {!loading && (
                    <Select
                        className={formClasses.field}
                        label={intl.formatMessage({
                            id: "app.country",
                        })}
                        withAsterisk
                        placeholder={intl.formatMessage({
                            id: "app.country.placeholder",
                        })}
                        data={countries?.map((c: Country) => {
                            return { value: c.iso, label: c.name };
                        })}
                        allowDeselect={false}
                        maxDropdownHeight={200}
                        {...form.getInputProps(`${addressType}.countryISO`)}
                        comboboxProps={{
                            transitionProps: {
                                transition: "pop",
                                duration: 200,
                            },
                        }}
                    />
                )}
            </Grid.Col>
            <Grid.Col span={{sm: 12, md: 3}}></Grid.Col>
        </Grid>
    );
}
