import { useState, useEffect } from "react";
import IndexedDService from "../../services/IndexedDB";
import { isEmpty } from "class-validator";

interface IUsePersistentData<T> {
    currentData: T;
    setCurrentData: React.Dispatch<React.SetStateAction<T>>;
    dataPersist: boolean;
    setDataPersist: React.Dispatch<React.SetStateAction<boolean>>;
}

const usePersistentData = <T,>(
    defaultData: T,
    storageKeys: string[],
    dataStore: string
): IUsePersistentData<T> => {
    const [currentData, setCurrentData] = useState<T>(defaultData);
    const [dataPersist, setDataPersist] = useState(true);
    const dbUtil = new IndexedDService(dataStore);

    const persistData = async (data: T) => {
        await dbUtil.saveMulti(data ?? {});
    };

    useEffect(() => {
        const loadData = async () => {
            const storedDataObject = await dbUtil.getMulti(storageKeys);

            if (!isEmpty(storedDataObject)) {
                setCurrentData((prevState) => ({
                    ...prevState,
                    ...storedDataObject,
                }));
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        if (dataPersist) {
            persistData(currentData);
        }
    }, [currentData, dataPersist]);

    return {
        currentData,
        setCurrentData,
        dataPersist,
        setDataPersist
    };
};

export default usePersistentData;
