import classes from "../../styles/navigation.module.css";
import { useEffect, useState } from "react";
import { Stack } from "@mantine/core";
import {
    IconCertificate,
    IconGauge,
    IconUser,
    IconUsers,
    IconSettings,
    IconLogout,
    IconBuildingStore,
} from "@tabler/icons-react";
import NavbarLink from "./NavbarLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../features/context/AuthContext";
import { useData } from "../../features/context/DataContext";
import {
    Action,
    Feature,
    useUserFeatureAccessHandler,
} from "../../features/hooks/useUserFeatureAccessHandler";

interface LinkItem {
    icon: any; // replace with the correct type for your icons
    label: string;
    to: string;
    notify?: boolean;
}

interface LinkData {
    [key: string]: LinkItem;
}

export function NavBar() {

    const { user } = useAuth();
    const { orderStats } = useData();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAccessAllowed, isUnassignedUser } = useUserFeatureAccessHandler();

    const linkData: LinkData = {
        dashboard: {
            icon: IconGauge,
            label: "app.dashboard",
            to: "/dashboard",
        },
        account: {
            icon: IconUser,
            label: "app.account",
            to: "/account",
            notify:
                user != null && !user.profile && !user.profileAddress?.billing,
        },
        ...(isAccessAllowed(Feature.Subscription, Action.ViewAdmin) ||
        isUnassignedUser()
            ? {
                  subscription: {
                      icon: IconCertificate,
                      label: "app.subscription",
                      to: "/subscription",
                  },
                  shop: {
                      icon: IconBuildingStore,
                      label: "app.shop",
                      to: "/shop",
                      notify: orderStats && orderStats?.new === 0,
                  },
              }
            : {}),
        ...(isAccessAllowed(Feature.VirtualLab, Action.View)
            ? {
                  team: {
                      icon: IconUsers,
                      label: "app.virtualLab",
                      to: "/team",
                  },
              }
            : {}),
        settings: {
            icon: IconSettings,
            label: "app.settings",
            to: "/settings",
        },
    };

    const links = Object.entries(linkData).map(([key, link], index) => (
        <NavbarLink
            {...link}
            key={index}
            active={`/${key}` === location.pathname}
        />
    ));

    return (
        <div className={classes.navbar}>
            <div className={classes.navbarMain}>
                <Stack className={classes.navbarStack} justify="center" gap={0}>
                    {links}
                </Stack>
            </div>

            <Stack justify="center" gap={0}>
                <NavbarLink
                    icon={IconLogout}
                    label="app.logout"
                    to=""
                    onClick={() => navigate("/logout")}
                />
            </Stack>
        </div>
    );
}
