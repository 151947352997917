import classes from "./Login.module.css";
import {
    Paper,
    TextInput,
    PasswordInput,
    Button,
    Title,
    Text,
    LoadingOverlay,
} from "@mantine/core";
import { useIntl, FormattedMessage } from "react-intl";
import { IntlContext } from "../../features/context/IntlContext";
import { Link } from "react-router-dom";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../features/context/AlertContext";
import { useAuth } from "../../features/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/User";
import { EmailNotVerifiedError } from "../../lib/Error";
import { useRest } from "../../features/context/RestContext";
import { useData } from "../../features/context/DataContext";
import { useErrorAlertHandler } from "../../features/hooks/useErrorHandler";
import useReCaptcha from "../../features/hooks/useReCaptcha";
import { ProfileAddress } from "../../models/Contact";
import { isEmptyObject } from "../../lib/Utilities";
import useDataUpdateHandler, { ENABLE_PERSIST } from "../../features/hooks/useDataUpdateHandler";
import { AppMode } from "../../lib/Constants";


interface FormValues {
    email: string;
    password: string;
}

function Login() {
    const intl = useIntl();
    const handleError = useErrorAlertHandler();
    const { user, authService, setCurrentAuth } = useAuth();
    const { locale, setIntlData } = useContext(IntlContext);
    const { restApiService } = useRest();
    const { setCurrentData } = useData();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const { setAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
    const enablePersist = useDataUpdateHandler(ENABLE_PERSIST);

    const mode: string = import.meta.env.MODE;
    const initialValues =
        mode === AppMode.DEV
            ? {
                  email: "test.start@vigilitech.ch",
                  password: "Evangeli#n/01",
              }
            : {
                  email: "",
                  password: "",
              };
    const form = useForm<FormValues>({
        initialValues: { ...initialValues },
        validate: {
            email: isEmail(
                intl.formatMessage({ id: "app.formLabel.email.error" })
            ),
            password: isNotEmpty(
                intl.formatMessage({ id: "app.error.password.required" })
            ),
        },
    });

    useEffect(() => {
        if (!isEmptyObject(user)) {
            navigate("/dashboard");
        }
        else {
            enablePersist();
        }
    }, []);

    const handleSubmit = async () => {
        await generateReCaptchaToken("login");

        const { email, password } = form.values;
        try {
            setLoaderVisible(true);
            const userData: User | undefined = await authService?.login(
                email.trim(),
                password.trim()
            );

            if (userData) {
                const [newMemberships, profileAddress, newAssignedLicence, company] = await Promise.all([
                    restApiService?.getUserMemberships(),
                    restApiService?.getProfileAddress(),
                    restApiService?.getAssignedLicence(),
                    restApiService?.getCompany()
                ]);
                userData.profileAddress = { ...profileAddress } as ProfileAddress;
                setCurrentAuth({ user: userData });
                if (locale !== userData.locale) {
                    setIntlData((prevData) => ({
                        ...prevData,
                        locale: userData.locale,
                    }));
                }
                setCurrentData((prevData) => ({
                    ...prevData,
                    memberships: newMemberships,
                    assignedLicence: newAssignedLicence,
                    company: company
                }));
                navigate("/dashboard");
            }
        } catch (error: any) {
            if (error instanceof EmailNotVerifiedError) {
                navigate("/verify-email", { state: { email: email } });
                return;
            }
            handleError(error as Error);
        } finally {
            setLoaderVisible(false);
        }
    };

    const handleFormError = (errors: typeof form.errors) => {
        const warningTitle = intl.formatMessage({ id: "app.warning" });
        let message: string = "";
        if (errors.email) {
            message = errors.email.toString();

        } else if (errors.password) {
            message = errors.password.toString()
        }
        setAlert({
            message: message,
            title: warningTitle,
            color: "orange",
            overlay: true,
            variant: "white"
        });
    };

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay
                visible={loaderVisible}
                overlayProps={{ blur: 2 }}
            />
            <form onSubmit={form.onSubmit(handleSubmit, handleFormError)}>
                <Paper className={classes.form} radius={0} p={30}>
                    <Title
                        order={2}
                        className={classes.title}
                        ta="center"
                        mt="md"
                        mb={50}
                    >
                        <FormattedMessage
                            id="app.login.welcome"
                            defaultMessage="Welcome to MARTA"
                        />
                    </Title>

                    <TextInput
                        label={intl.formatMessage({
                            id: "app.formLabel.email",
                        })}
                        placeholder="hello@mail.com"
                        size="md"
                        withAsterisk
                        {...form.getInputProps("email")}
                    />
                    <PasswordInput
                        withAsterisk
                        label={intl.formatMessage({
                            id: "app.formLabel.password",
                        })}
                        placeholder={intl.formatMessage({
                            id: "app.formLabel.yourPassword",
                        })}
                        mt="md"
                        size="md"
                        {...form.getInputProps("password")}
                    />
                    <Button
                        disabled={!reCaptchaLoaded}
                        fullWidth
                        mt="xl"
                        size="md"
                        type="submit"
                    >
                        <FormattedMessage
                            id="app.login"
                            defaultMessage="Login"
                        />
                    </Button>
                    <Text ta="center" mt="md">
                        <FormattedMessage
                            id="app.login.noAccount"
                            defaultMessage="Sign up"
                        />{" "}
                        <Link to="/signup">
                            <FormattedMessage
                                id="app.signup"
                                defaultMessage="Sign up"
                            />
                        </Link>
                    </Text>
                    <Text ta="center" mt="md">
                        <Link to="/password-forgot">
                            <FormattedMessage
                                id="app.login.forgotPassword"
                                defaultMessage="Forgot password?"
                            />{" "}
                        </Link>
                    </Text>
                </Paper>
            </form>
        </div>
    );
}

export default Login;
