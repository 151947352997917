import classes from "./cell.module.css";
import globalClasses from "../../styles/global.module.css";
import { FormattedMessage } from "react-intl";
import {
    ISubscriptionStats,
    useSubscriptionStats,
} from "../../features/hooks/useSubscriptionStats";
import { Card, Grid, Group, RingProgress, Text, Title } from "@mantine/core";

const PlanDetailsGrid = () => {
    return (
        <Grid>
            <Grid.Col span={6}>
                <SubscriptionStatsCell />
            </Grid.Col>
        </Grid>
    );
};

export default PlanDetailsGrid;

const SubscriptionStatsCell = () => {
    const { users, labs }: ISubscriptionStats = useSubscriptionStats();

    return (
        <Card
            className={`${classes.container} ${globalClasses.card}`}
        >
            <Card.Section className={classes.section}>
                <Title className={classes.title}>
                    <FormattedMessage id="app.subscription" />
                </Title>
            </Card.Section>
            <Card.Section className={classes.section}>
                <Group justify="left">
                    <RingProgress
                        size={50}
                        rootColor="#73BB70"
                        thickness={6}
                        sections={[
                            {
                                value: users.ratio * 100,
                                color: "#FF7043",
                            },
                        ]}
                        label={
                            <Text size="sm" ta="center">
                                {users.created}
                            </Text>
                        }
                    />
                    <Text>
                        <FormattedMessage id="app.licence.created" />
                    </Text>
                </Group>
                <Group justify="left">
                    <RingProgress
                        size={50}
                        rootColor="#73BB70"
                        thickness={6}
                        sections={[
                            {
                                value: labs.ratio * 100,
                                color: "#FF7043",
                            },
                        ]}
                        label={
                            <Text size="md" ta="center">
                                {labs.created}
                            </Text>
                        }
                    />
                    <Text>
                        <FormattedMessage id="app.virtualLabs.created" />
                    </Text>
                </Group>
            </Card.Section>
        </Card>
    );
};
