import {
    ActionIcon,
    Checkbox,
    Grid,
    Group,
    Loader,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { FormattedMessage } from "react-intl";
import { IconUserPlus } from "@tabler/icons-react";
import { useRest } from "../../../features/context/RestContext";
import { TeamMember } from "../../../models/Team";
import { useEffect, useState } from "react";
import { isEmptyObject } from "../../../lib/Utilities";
import { NotFoundError } from "../../../lib/Error";
import { Licence, LicenceRoleEnum } from "../../../models/Licence";
import { useData } from "../../../features/context/DataContext";
import { UserCell } from "../../common/User";
import { useTeamData } from "../../../features/context/TeamContext";

interface FormValues {
    userUuids: string[];
}

export function AddMemberForm() {
    const { restApiService } = useRest();
    const { assignedLicence } = useData();
    const { currentTeam, membersCtxRefreshTS, setTeamData } = useTeamData();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [nonAdminLicences, setNonAdminLicences] = useState<Licence[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>(
        "app.error.somethingWentWrong"
    );

    const form = useForm<FormValues>({
        validateInputOnBlur: true,
        initialValues: {
            userUuids: [],
        },
    });

    const handleCheckboxChange = (isChecked: boolean, uuid: string) => {
        const currentUuids = form.values.userUuids;
        const newUuids = isChecked
            ? [...currentUuids, uuid]
            : currentUuids.filter((id) => id !== uuid);

        form.setFieldValue("userUuids", newUuids);
    };

    const getNonAdminLicences = async () => {
        if (assignedLicence?.subscription?.uuid) {
            try {
                setLoaderVisible(true);
                const data: Licence[] =
                    (await restApiService?.getLicencesBySubscriptionAndRole(
                        assignedLicence.subscription.uuid,
                        LicenceRoleEnum.ASSIGNEE_ROLE,
                        { teamUuid: currentTeam?.uuid }
                    )) ?? [];
                setNonAdminLicences(data);
            } catch (error: any) {
                setErrorMessage(error.message);
                setShowError(true);
                setRemoveErrorMessageTimer();
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    const handleSubmit = async () => {
        if (currentTeam?.uuid) {
            try {
                setLoaderVisible(true);
                const membersList: TeamMember[] | undefined =
                    await restApiService?.addTeamMembers(
                        currentTeam.uuid,
                        form.values.userUuids
                    );
                if (membersList && !isEmptyObject(membersList)) {
                    setTeamData((prev) => ({
                        ...prev,
                        currentMembersList: membersList,
                        membersCtxRefreshTS: Date.now(),
                    }));
                } else {
                    setShowError(true);
                    setRemoveErrorMessageTimer();
                }
                form.reset();
            } catch (error: any) {
                if (error instanceof NotFoundError) {
                    setErrorMessage(error.message);
                }
                setShowError(true);
                setRemoveErrorMessageTimer();
            } finally {
                setLoaderVisible(false);
            }
        }
    };

    const setRemoveErrorMessageTimer = () => {
        setTimeout(() => {
            setShowError(false);
        }, 2000);
    };

    const handleError = () => {
        console.log("error");
    };

    useEffect(() => {
        if (currentTeam) {
            getNonAdminLicences();
        }
    }, [currentTeam, membersCtxRefreshTS]);

    const candidateUsersList = nonAdminLicences.map((licence, index) => {
        if (!currentTeam || !licence.assignee || !licence.assignee.uuid) {
            return null;
        }

        return (
            <Grid.Col span={3} key={index}>
                <Group key={index} gap="xs" align="end">
                    <UserCell user={licence.assignee} />
                    <Checkbox
                        mt="sm"
                        checked={form.values.userUuids.includes(
                            licence.assignee.uuid
                        )}
                        onChange={(event) =>
                            handleCheckboxChange(
                                event.currentTarget.checked,
                                licence.assignee.uuid
                            )
                        }
                    />
                </Group>
            </Grid.Col>
        );
    });

    return (
        <>
            <form onSubmit={form.onSubmit(handleSubmit, handleError)}>
                <Stack>
                    <Title order={2}>
                        <FormattedMessage
                            id="app.virtualLab.member.add"
                            defaultMessage="Add member"
                        />
                    </Title>
                    <Grid align="start">{candidateUsersList}</Grid>
                    <ActionIcon
                        color="green"
                        disabled={loaderVisible}
                        size="2.2rem"
                        type="submit"
                    >
                        {loaderVisible ? (
                            <Loader color="white" size={16} />
                        ) : (
                            <IconUserPlus />
                        )}
                    </ActionIcon>
                    {showError && (
                        <Group style={{ justifyContent: "right" }}>
                            <Text style={{ color: "red" }}>
                                <FormattedMessage id={errorMessage} />
                            </Text>
                        </Group>
                    )}
                </Stack>
            </form>
        </>
    );
}
