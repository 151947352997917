import { Box, Container, Space, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import globalClasses from "../../styles/global.module.css";

interface PageContainerProps {
    titleId: string;
    defaultTitle: string;
    values?: any;
    children: React.ReactNode;
}

export function PageContainer({
    titleId: title,
    defaultTitle,
    children,
    values
}: PageContainerProps) {
    return (
        <>
            <Title order={1} className={globalClasses.pageTitle}>
                <FormattedMessage id={title} defaultMessage={defaultTitle} values={values}/>
            </Title>
            <Space h="xl" />
            <Container fluid>{children}</Container>
        </>
    );
}
