export interface CartElementProps {
    closeCB: () => void;
}

export enum CartStep {
    ORDER = 0,
    ORDER_PROCESSED = 1,
    CHECKOUT = 2,
    CLOSING = 3,
}
