import { createFormContext } from "@mantine/form";

interface VirtualLabFormValues {
    name: string;
    description: string;
    defaultAnimalType: string;
    ownerUuid: string;
}

export const [VirtualLabFormProvider, useVirtualLabFormContext, useVirtualLabForm] =
    createFormContext<VirtualLabFormValues>();
