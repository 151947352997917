import formClasses from "../../../styles/form.module.css";
import { useForm } from "@mantine/form";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Select, TextInput } from "@mantine/core";
import { companyEditProps } from "../Interfaces";
import { formularRef } from "../../common/Wrapper";
import { useRest } from "../../../features/context/RestContext";
import { isEmptyObject } from "../../../lib/Utilities";
import { useErrorAlertHandler } from "../../../features/hooks/useErrorHandler";
import { Company } from "../../../models/Company";
import { useAppData } from "../../../features/context/AppDataContext";
import { BarLoader } from "../../common/Loader";
import { Country } from "../../../models/Country";
import classes from "../../../styles/global.module.css";

interface FormValues {
    name: string;
    identificationNumber: string;
    phone: string;
    countryISO: string;
}

const CompanyEditGrid = forwardRef<formularRef, companyEditProps>(
    ({ company, updateCompany, setLoaderVisibleCallback }, ref) => {
        const intl = useIntl();
        const handleError = useErrorAlertHandler();
        const [loading, setLoading] = useState<boolean>(false);
        const { restApiService } = useRest();
        const { countries, currencies } = useAppData();

        const form = useForm<FormValues>({
            validateInputOnBlur: true,
            initialValues: {
                name: company?.name ?? "",
                identificationNumber: company?.identificationNumber ?? "",
                phone: company?.phone ?? "",
                countryISO: company?.countryISO ?? "",
            },
        });

        const handleSubmit = async () => {
            try {
                setLoaderVisibleCallback(true);
                const updatedCompany = isEmptyObject(company)
                    ? await restApiService?.createCompany(form.values)
                    : await restApiService?.updateCompany(form.values);
                updateCompany(updatedCompany ?? new Company());
            } catch (error: any) {
                handleError(error as Error);
            } finally {
                setLoaderVisibleCallback(false);
            }
        };

        useImperativeHandle(ref, () => ({ handleSubmit }));

        useEffect(() => {
            setLoading(countries?.length === 0);
        }, [countries]);

        return (
            <form>
                <Grid className={classes.info}>
                    <Grid.Col span={{ sm: 12, md: 9 }}>
                        <Grid grow>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.formLabel.name",
                                        defaultMessage: "Name",
                                    })}
                                    {...form.getInputProps("name")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.company.identificationNumber",
                                        defaultMessage:
                                            "Company identification number",
                                    })}
                                    {...form.getInputProps(
                                        "identificationNumber"
                                    )}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    className={formClasses.field}
                                    label={intl.formatMessage({
                                        id: "app.formLabel.phone",
                                        defaultMessage: "Phone",
                                    })}
                                    {...form.getInputProps("phone")}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ sm: 12, md: 3 }}>
                                <BarLoader visible={loading} />
                                {!loading && (
                                    <Select
                                        className={formClasses.field}
                                        label={intl.formatMessage({
                                            id: "app.country",
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "app.country.placeholder",
                                        })}
                                        data={countries?.map((c: Country) => {
                                            return {
                                                value: c.iso,
                                                label: c.name,
                                            };
                                        })}
                                        allowDeselect={false}
                                        maxDropdownHeight={200}
                                        {...form.getInputProps("countryISO")}
                                        comboboxProps={{
                                            transitionProps: {
                                                transition: "pop",
                                                duration: 200,
                                            },
                                        }}
                                    />
                                )}
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                </Grid>
            </form>
        );
    }
);

export default CompanyEditGrid;
