import classes from "./../ShoppingCart.module.css";
import elementCls from "./Element.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useShoppingCart } from "../../../../features/context/ShoppingCartContext";
import { useEffect, useState } from "react";
import { ProductCartItem } from "../../../../models/Order";
import { ActionIcon, Grid, Group, Text } from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { calcDeliveryPackagesAmount } from "../../../../lib/Utilities";
import { useData } from "../../../../features/context/DataContext";
import { CurrencyCode } from "../../../../lib/Constants";
import { useAuth } from "../../../../features/context/AuthContext";

interface ProductRowProps {
    prodCartItem: ProductCartItem;
    index: string;
    form: any;
}
interface CartItemAmountActionProps {
    productAmount: number;
    callback?: (amount: number) => void;
}

interface TransactionFeeRowProps {
    fee: number;
}

const CartItemAmountAction = ({
    productAmount,
    callback,
}: CartItemAmountActionProps) => {
    const [amount, setAmount] = useState<number>(productAmount);

    const increaseAmount = () => {
        setAmount((prevAmount) => prevAmount + 1);
        callback && callback(amount + 1);
    };

    const decreaseAmount = () => {
        if (amount === 0) {
            return;
        }
        setAmount((prevAmount) => prevAmount - 1);
        callback && callback(amount - 1);
    };

    return (
        <Group>
            <ActionIcon color="red" size={20} onClick={decreaseAmount}>
                <IconMinus size={20} />
            </ActionIcon>
            <Text>{amount}</Text>
            <ActionIcon color="green" size={20} onClick={increaseAmount}>
                <IconPlus size={20} />
            </ActionIcon>
        </Group>
    );
};

export const ProductRow = ({ prodCartItem, index, form }: ProductRowProps) => {
    const intl = useIntl();
    const { productData, setShoppingCart } = useShoppingCart();
    const [currentItem, setCurrentItem] =
        useState<ProductCartItem>(prodCartItem);
    const { user } = useAuth();
    
    useEffect(() => {
        form.setFieldValue(`productCart.${index}.amount`, currentItem.amount);
        const productCartItem = productData.find(
            (p) => p.product.uuid === currentItem.product.uuid
        );
        productCartItem!.amount = currentItem.amount;
        setShoppingCart((prevData) => ({
            ...prevData,
            productData: [...productData],
        }));
    }, [currentItem.amount]);

    const updateProductAmount = (amount: number) => {
        setCurrentItem((prevItem) => {
            return {
                ...prevItem,
                amount: amount,
            };
        });
    };

    return (
        <Grid className={`${classes.row} ${classes.simpleRow}`}>
            <Grid.Col span={6}>
                <Text fw={700}>{currentItem.product.name}</Text>
            </Grid.Col>
            <Grid.Col span={6}>
                <CartItemAmountAction
                    productAmount={currentItem.amount}
                    callback={updateProductAmount}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                {intl.formatNumber(
                    currentItem.product.price * currentItem.amount,
                    {
                        style: "currency",
                        currency: user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF,
                    }
                )}
            </Grid.Col>
        </Grid>
    );
};

export const FeesRow = ({ prodCartItem, index, form }: ProductRowProps) => {
    const intl = useIntl();
    const [currentItem, setCurrentItem] =
        useState<ProductCartItem>(prodCartItem);
    const { user } = useAuth();

    const { productData, appliedFeeData, setShoppingCart } = useShoppingCart();

    useEffect(() => {
        form.setFieldValue(`${index}.amount`, currentItem.amount);

        appliedFeeData!.amount = calcDeliveryPackagesAmount(productData);
        setCurrentItem(appliedFeeData!);
        setShoppingCart((prevData) => ({
            ...prevData,
            appliedFeeData: appliedFeeData,
        }));
    }, [productData]);

    return (
        <Grid className={`${classes.row} ${classes.simpleRow}`}>
            <Grid.Col span={6}>
                <Text fw={700}>
                    {currentItem.product.name} x{currentItem.amount}
                </Text>
            </Grid.Col>
            <Grid.Col span={6}>
                <Text>
                    {intl.formatNumber(
                        currentItem.product.price * currentItem.amount,
                        {
                            style: "currency",
                            currency: user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF,
                        }
                    )}
                </Text>
            </Grid.Col>
        </Grid>
    );
};

export const TransactionFeeRow = ({ fee }: TransactionFeeRowProps) => {
    const intl = useIntl();
    const { user } = useAuth();
    const currencyCode = user?.profile?.transactionsCurrencyCode ?? CurrencyCode.CHF;

    return (
        <Grid className={`${classes.row} ${classes.simpleRow}`}>
            <Grid.Col span={6}>
                <Text fw={700}>
                    <FormattedMessage
                        id="app.order.transactionFee"
                        defaultMessage="Transaction fee"
                    />
                </Text>
            </Grid.Col>
            <Grid.Col span={6}>
                {intl.formatNumber(fee, {
                    style: "currency",
                    currency: currencyCode,
                })}
                <div className={elementCls.transactionAlert}>
                    <FormattedMessage
                        id="app.order.transactionFee.alert"
                        defaultMessage="A transaction fee of {rate}% + {fixed} {currency} will be applied to all card payments."
                        values={{ rate: 3.25, currency: currencyCode, fixed: 0.3 }}
                    />
                </div>
            </Grid.Col>
            <Grid.Col span={12}></Grid.Col>
        </Grid>
    );
};
