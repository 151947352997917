import React, { useState, createContext } from "react";
import localesService from "../../services/i18n";
import { IntlProvider } from "react-intl";
import { LayoutProps } from "./common";

interface IntlState {
    locale: string;
}

interface IntlStateProps extends IntlState {
    setIntlData: React.Dispatch<React.SetStateAction<IntlState>>;
}

export const IntlContext = createContext<IntlStateProps>({
    locale: localesService.getDefaultLocale(),
    setIntlData: () => {},
});

const IntlProviderWrapper: React.FC<LayoutProps> = ({ children }) => {
    const [intlData, setIntlData] = useState<IntlState>({
        locale: localesService.getCurrentLocale(),
    });

    let currLocale = intlData.locale;
    let currMessages = localesService.getMessages(currLocale);

    return (
        <IntlContext.Provider value={{ ...intlData, setIntlData }}>
            <IntlProvider
                messages={currMessages}
                key={currLocale}
                locale={currLocale}
                defaultLocale={localesService.getDefaultLocale()}
            >
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};

export { IntlProviderWrapper as IntlProvider };
