import { isValidDate } from "./Utilities";

export const stringToDate = (date: string, allowNull: boolean): Date | null => {
    if (allowNull && (date === null || date === undefined)) {
        return null;
    }

    if (!isValidDate(date)) {
        throw new TypeError("Invalid date");
    }

    return date ? new Date(date) : null;
};

export const dateToString = (date: Date | string): string | null => {
    if (!date) {
        return null;
    }

    if (typeof date === "string" && isValidDate(date)) {
        return date;
    }

    const dateObj = date as Date;
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // JS month is 0-based
    const day = dateObj.getDate().toString().padStart(2, "0");
    const hour = dateObj.getHours().toString().padStart(2, "0");
    const minute = dateObj.getMinutes().toString().padStart(2, "0");
    const second = dateObj.getSeconds().toString().padStart(2, "0");

    // Construct the SQL datetime format string
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};
