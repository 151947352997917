import globalClasses from "../../styles/global.module.css";
import { List, ThemeIcon, rem } from "@mantine/core";
import { HeroProps } from "./Interfaces";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import { FeatureType } from "../../models/Subscription";

interface FeaturesProps extends HeroProps {
    compact?: boolean;
}

export function Features({ plan, compact }: FeaturesProps) {
    const intl = useIntl();
    const themeIconProps = compact
        ? { color: "green", size: 24, radius: "xl" }
        : { size: 20, radius: "xl" };
    const iconProps = compact ? { size: 16 } : {style: { width: rem(12), height: rem(12) }, stroke: 1.5};

    return (
        <List
            spacing="sm"
            size="sm"
            center
            icon={
                <ThemeIcon {...themeIconProps}>
                    <IconCheck {...iconProps} />
                </ThemeIcon>
            }
        >
            <List.Item>
                <span className={!compact ? globalClasses.bold : ""}>
                    {plan.maxUsers != -1 ? `${plan.maxUsers}` : "X"}{" "}
                    {intl.formatMessage({ id: "app.users" })}
                </span>
            </List.Item>
            {plan.subscriptionPlanFeatures.map(
                ({ feature, isAvailable }, index) => (
                    <List.Item
                        key={index}
                        {...(!isAvailable
                            ? {
                                  icon: (
                                      <ThemeIcon
                                          {...themeIconProps}
                                          {...(compact ? { color: "red" } : {})}
                                      >
                                          <IconX {...iconProps} />
                                      </ThemeIcon>
                                  ),
                              }
                            : {})}
                    >
                        {feature.type === FeatureType.VIRTUAL_LAB &&
                        plan.maxVirtualLabs != -1
                            ? plan.maxVirtualLabs
                            : ""}{" "}
                        <span className={!compact ? globalClasses.bold : ""}>
                            {feature.name}
                        </span>
                        {compact ? "" : ` - ${feature.description}`}
                    </List.Item>
                )
            )}
        </List>
    );
}
