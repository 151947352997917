import {
    Expose,
    Transform,
    Type,
    instanceToPlain,
    plainToClass,
} from "class-transformer";
import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";
import { stringToDate } from "../lib/ConvertionUtils";
import { User, UserDto } from "./User";

export enum SubscriptionPlanType {
    BASIC = "BAS",
    STANDARD = "STD",
    PREMIUM = "PRE",
    ENTERPRISE = "ENT",
}

export enum FeatureType {
    MONITORING = "MON",
    RECORDING = "REC",
    VIRTUAL_ASSISTANT = "VIRA",
    VIRTUAL_LAB = "VIRL",
}

@staticImplements<DtoStatic>()
export class SubscriptionDto {
    @Expose()
    uuid!: string;
    @Expose()
    plan!: SubscriptionPlanDto;
    @Expose()
    expirationDate!: string;
    @Expose()
    recurring!: boolean;
    @Expose()
    licencesCount!: number;
    @Expose()
    labsCount!: number;
    @Expose()
    @Type(() => UserDto)
    owner!: UserDto;

    static toModel(subscriptionDto: SubscriptionDto): Subscription {
        if (subscriptionDto === undefined) {
            return new Subscription();
        }
        const data = instanceToPlain(subscriptionDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Subscription, data);
    }
}

export class Subscription implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    plan!: SubscriptionPlan;
    @Transform((data) => stringToDate(data.value, true))
    expirationDate!: Date | null;
    @Expose()
    recurring!: boolean;
    @Expose()
    licencesCount!: number;
    @Expose()
    labsCount!: number;
    @Expose()
    @Type(() => User)
    owner!: User;
}

@staticImplements<DtoStatic>()
export class SubscriptionPlanDto {
    @Expose()
    uuid!: string;
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    price!: string;
    @Expose()
    maxUsers!: number;
    @Expose()
    period!: string;
    @Expose()
    maxVirtualLabs!: number;
    @Expose()
    type!: SubscriptionPlanType;
    @Expose()
    @Type(() => SubscriptionPlanFeatureDto)
    subscriptionPlanFeatures!: SubscriptionPlanFeatureDto[];

    static toModel(subscriptionPlanDto: SubscriptionPlanDto): SubscriptionPlan {
        if (subscriptionPlanDto === undefined) {
            return new SubscriptionPlan();
        }
        const data = instanceToPlain(subscriptionPlanDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(SubscriptionPlan, data);
    }
}

export class SubscriptionPlan implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    price!: string;
    @Expose()
    maxUsers!: number;
    @Expose()
    period!: string;
    @Expose()
    maxVirtualLabs!: number;
    @Expose()
    type!: SubscriptionPlanType;
    @Expose()
    @Type(() => SubscriptionPlanFeature)
    subscriptionPlanFeatures!: SubscriptionPlanFeature[];
}

@staticImplements<DtoStatic>()
export class SubscriptionPlanFeatureDto {
    @Expose()
    @Type(() => FeatureDto)
    feature!: FeatureDto;
    @Expose()
    isAvailable!: boolean;

    static toModel(
        subscriptionPlanFeatureDto: SubscriptionPlanFeatureDto
    ): SubscriptionPlanFeature {
        if (subscriptionPlanFeatureDto === undefined) {
            return new SubscriptionPlanFeature();
        }
        const data = instanceToPlain(subscriptionPlanFeatureDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(SubscriptionPlanFeature, data);
    }
}

export class SubscriptionPlanFeature implements Model {
    @Expose()
    @Type(() => Feature)
    feature!: Feature;
    @Expose()
    isAvailable!: boolean;
}

@staticImplements<DtoStatic>()
export class FeatureDto {
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    type!: FeatureType;

    static toModel(featureDto: FeatureDto): Feature {
        if (featureDto === undefined) {
            return new Feature();
        }
        const data = instanceToPlain(featureDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Feature, data);
    }
}

export class Feature implements Model {
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    type!: FeatureType;
}

@staticImplements<DtoStatic>()
export class UserSubscriptionReportDto {
    @Expose()
    subscribedUsers!: string[];
    @Expose()
    invitedUsers!: string[];

    static toModel(
        userSubscriptionReportDto: UserSubscriptionReportDto
    ): UserSubscriptionReport {
        if (userSubscriptionReportDto === undefined) {
            return new UserSubscriptionReport();
        }
        const data = instanceToPlain(userSubscriptionReportDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(UserSubscriptionReport, data);
    }
}

export class UserSubscriptionReport implements Model {
    @Expose()
    subscribedUsers!: string[];
    @Expose()
    invitedUsers!: string[];
}
