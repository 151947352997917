import { Avatar, Badge, Group, List, Modal, Table, Text } from "@mantine/core";
import { LAB_ROLE_MAP, TeamMember, LabRoles } from "../../../models/Team";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext, useEffect, useState } from "react";
import { FormSubmitContext } from "../../../features/context/FormSubmitContext";
import { useAuth } from "../../../features/context/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import MemberRoleForm from "../Forms/MemberRoleForm";
import { MemberActions } from "./Actions";
import { useTeamData } from "../../../features/context/TeamContext";
import {
    Action,
    Feature,
    useUserFeatureAccessHandler,
} from "../../../features/hooks/useUserFeatureAccessHandler";

export function MembersTable() {
    const { handleFormSubmitted } = useContext(FormSubmitContext);
    const { currentTeam, currentMembersList } = useTeamData();
    const [selectedMember, setSelectedMember] = useState<TeamMember>();
    const intl = useIntl();
    const { user } = useAuth();
    const { isAccessAllowed } = useUserFeatureAccessHandler();    
    const [userRolesFormOpened, userRoleHandlers] = useDisclosure(false);
    const canEdit = isAccessAllowed(Feature.VirtualLab, Action.Edit);

    const buildRoleList = (member: TeamMember): JSX.Element[] => {
        const labels = LAB_ROLE_MAP;
        let roles: string[] = [];

        if (member.isOwner) {
            roles.push(LabRoles.ROLE_LAB_OWNER);
        }

        if (member.isCreator) {
            roles.push(LabRoles.ROLE_LAB_CREATOR);
        }

        roles = roles.concat(member.teamRoles);

        return roles.map((role, index) => (
            <List.Item key={index}>
                {intl.formatMessage({ id: labels.get(role) || role })}
            </List.Item>
        ));
    };

    const openUserRolesForm = (member: TeamMember) => {
        setSelectedMember(member);
        userRoleHandlers.open();
    };

    const handleUserFormClose = () => {
        userRoleHandlers.close();
    };

    const rows = currentMembersList
        ? currentMembersList.map((member, index) => (
              <Table.Tr key={index}>
                  <Table.Td>
                      <Group gap="sm">
                          <Avatar size={40} radius={40} />
                          <div>
                              <Text fz="sm" fw={500}>
                                  {member?.firstName} {member?.lastName}
                              </Text>
                              <Text fz="xs" c="dimmed">
                                  {member?.email}
                              </Text>
                          </div>
                      </Group>
                  </Table.Td>
                  <Table.Td>
                      <List>{member && buildRoleList(member)}</List>
                  </Table.Td>
                  <Table.Td>{member?.nickname}</Table.Td>
                  <Table.Td>
                      <Badge
                          fullWidth
                          variant={member?.isActive ? "light" : "dark"}
                      >
                          <FormattedMessage
                              id={
                                  member?.isActive
                                      ? "app.label.active"
                                      : "app.label.inactive"
                              }
                              defaultMessage={
                                  member?.isActive ? "Active" : "Inactive"
                              }
                          />
                      </Badge>
                  </Table.Td>
                  {canEdit && user?.uuid != member.uuid && (
                      <Table.Td>
                          <MemberActions
                              member={member}
                              teamUuid={currentTeam?.uuid ?? ""}
                              openRoleFormCB={openUserRolesForm}
                          />
                      </Table.Td>
                  )}
              </Table.Tr>
          ))
        : [];

    return (
        <>
            {canEdit && (
                <Modal
                    opened={userRolesFormOpened}
                    onClose={handleUserFormClose}
                    centered
                    size="md"
                >
                    {selectedMember && currentTeam && (
                        <MemberRoleForm
                            team={currentTeam}
                            member={selectedMember}
                            successCB={handleFormSubmitted}
                        />
                    )}
                </Modal>
            )}
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.virtualLab.member"
                                    defaultMessage="Member"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.user.role"
                                    defaultMessage="Role"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.user.nickname"
                                    defaultMessage="Nickname"
                                />
                            </Table.Th>
                            <Table.Th>
                                <FormattedMessage
                                    id="app.user.status"
                                    defaultMessage="Status"
                                />
                            </Table.Th>
                            {canEdit && (
                                <Table.Th>
                                    <FormattedMessage
                                        id="app.action"
                                        defaultMessage="Action"
                                    />
                                </Table.Th>
                            )}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </>
    );
}
