import { ProductCartItem } from "../models/Order";

const isTypeArray = (arr: any, type: string): boolean => {
    if (!Array.isArray(arr)) {
        return false;
    }
    return arr.every((item: any) => typeof item === type);
};

export const isStringArray = (arr: any): boolean => {
    return isTypeArray(arr, "string");
};

export const isNumberArray = (arr: any): boolean => {
    return isTypeArray(arr, "number");
};

export const isValidDate = (date: string): boolean => {
    if (typeof date !== "string") {
        return false;
    }

    if (isSQLDatetimeFormat(date)) {
        return true;
    }

    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()) && date.trim().length > 0;
};

export const isSQLDatetimeFormat = (date: string): boolean => {
    // Regular expression pattern for the SQL datetime format
    const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

    return pattern.test(date);
};

/**
 * Test if an object is empty
 *
 * @param obj
 * @returns
 */
export const isEmptyObject = (obj: any): boolean => {
    if (obj == null) return true;
    return Object.values(obj).every((value) => value == null);
};

/**
 * Sleep function
 *
 * @param count
 * @param total
 * @returns
 */
export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Ratio calculation
 *
 * @param count
 * @param total
 * @returns
 */
export const calculatePart = (count: number, total: number): number => {
    const result = (count / total) * 100;
    return Number(result.toFixed(2));
};

/**
 * Calculate amount of needed delivery boxes
 *
 * @param productData array of products
 * @returns
 */
export const calcDeliveryPackagesAmount = (
    productData: ProductCartItem[]
): number => {
    return Math.ceil(
        productData.reduce(
            (acc, curr) => acc + curr.amount * curr.product.neededBoxAmount,
            0
        )
    );
};

/**
 * Calculate estimated transaction fees, default calculation includes taxes
 *
 * @param totalPrice base for fees calculation
 * @param taxFree enable tax free mode
 * @returns
 */
export const calcEstimatedStripeFees = (
    totalPrice: number,
    taxFree: boolean = false
): number => {
    let result: number = (0.3 + (totalPrice * 0.0325)) / (1 - 0.0325);
    result = taxFree ? result : result * 1/(1 + 0.081);
    return parseFloat(result.toFixed(2));
};

/**
 * Calculate price with taxes
 *
 * @param price price tax free
 * @returns
 */
export const calcPriceWithTaxes = (price: number): number => {
    // TODO: Calculate total price with tax included depending on delivery address
    // Simple solution VAT applicable for CH only
    //(get the appliable tax rate from the server)
    const result: number = price * 1.081;
    return parseFloat(result.toFixed(2));
};
