import React, { createContext, useContext } from "react";
import { Membership } from "../../models/Team";
import { OrderStat } from "../../models/Stats";
import { Licence } from "../../models/Licence";
import { Company } from "../../models/Company";
import { DATA_STORE } from "../../services/IndexedDB";
import usePersistentData from "../hooks/usePersistentData";

interface IData {
    memberships?: Map<string, Membership> | undefined;
    assignedLicence?: Licence | undefined;
    orderStats?: OrderStat | undefined;
    company?: Company | undefined;
}

interface IDataProps extends IData {
    setCurrentData: React.Dispatch<React.SetStateAction<IData>>;
    setDataPersist: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataContext = createContext<IDataProps>({
    memberships: new Map<string, Membership>(),
    assignedLicence: undefined,
    orderStats: undefined,
    company: undefined,
    setCurrentData: () => {},
    setDataPersist: () => {},
});

export const useData = () => {
    const data = useContext(DataContext);

    if (!data) {
        throw new Error("useData must be used within a DataProvider");
    }

    return data;
};

const defaultData: IData = {
    memberships: new Map<string, Membership>(),
    assignedLicence: undefined,
    orderStats: undefined,
    company: undefined,
};
const licenceStorageKeys: string[] = Object.keys(defaultData);

export const DataProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const { currentData, setCurrentData, dataPersist, setDataPersist } =
        usePersistentData(defaultData, licenceStorageKeys, DATA_STORE);

    const contextValue: IDataProps = {
        ...currentData,
        setCurrentData: setCurrentData,
        setDataPersist: setDataPersist,
    };

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};
