export class BaseError extends Error {
    public name: string = "";
    public serverMessage: string = "";
    public appErrorCode: number = 0;

    constructor(uiMessage: string, serverMessage?: string, appErrorCode?: number) {
        super(uiMessage);
        this.name = "BaseError";
        this.serverMessage = serverMessage || "";
        this.appErrorCode = appErrorCode || 0;
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BaseError.prototype);
    }
}

export class AuthenticationError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string, appErrorCode?: number) {
        super(uiMessage, serverMessage, appErrorCode);
        this.name = "AuthenticationError";

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AuthenticationError.prototype);
    }
}

export class BadRequestError extends BaseError {
    public errors: any;

    constructor(uiMessage: string, serverMessage?: string, errors?: any, appErrorCode?: number) {
        super(uiMessage, serverMessage, appErrorCode);
        this.name = "BadRequestError";
        this.errors = errors;

        Object.setPrototypeOf(this, BadRequestError.prototype);
    }
}

export class EmailError extends BadRequestError {

    constructor(uiMessage: string, serverMessage?: string, errors?: any) {
        super(uiMessage, serverMessage, errors);
        this.name = "EmailError";

        Object.setPrototypeOf(this, EmailError.prototype);
    }
}

export class EmailNotVerifiedError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string) {
        super(uiMessage, serverMessage);
        this.name = "EmailNotVerifiedError";

        Object.setPrototypeOf(this, EmailNotVerifiedError.prototype);
    }
}

export class ForbiddenError extends BaseError {
    public errors: any;

    constructor(uiMessage: string, serverMessage?: string, errors?: any) {
        super(uiMessage, serverMessage);
        this.name = "ForbiddenError";
        this.errors = errors;

        Object.setPrototypeOf(this, ForbiddenError.prototype);
    }
}

export class RegistrationError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string) {
        super(uiMessage, serverMessage);
        this.name = "RegistrationError";

        Object.setPrototypeOf(this, RegistrationError.prototype);
    }
}

export class UserDataError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string) {
        super(uiMessage, serverMessage);
        this.name = "UserDataError";

        Object.setPrototypeOf(this, UserDataError.prototype);
    }
}

export class NetworkError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string) {
        super(uiMessage, serverMessage);
        this.name = "NetworkError";

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NetworkError.prototype);
    }
}

export class NotFoundError extends BaseError {
    constructor(uiMessage: string, serverMessage?: string, appErrorCode?: number) {
        super(uiMessage, serverMessage, appErrorCode);
        this.name = "NotFoundError";

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NotFoundError.prototype);
    }
}
