import classes from "./ShoppingCart.module.css";
import globalClasses from "../../../styles/global.module.css";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { IconCheck, IconShoppingCartOff } from "@tabler/icons-react";
import { useShoppingCart } from "../../../features/context/ShoppingCartContext";
import ShoppingCartForm from "./Elements/Form";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import Checkout from "./Checkout";
import { CartElementProps, CartStep } from "./Interfaces";
import { IconSuccess } from "../../Icons/Success";
import { useAuth } from "../../../features/context/AuthContext";
import { Order, PaymentMethodEnum } from "../../../models/Order";

interface ShoppingCartProps {
    onStepChange: (step: CartStep) => void;
    onClose: () => void;
}

const ShoppingCart = ({ onStepChange, onClose }: ShoppingCartProps) => {
    const { user } = useAuth();
    const { productData, createdOrder, appliedFeeData } = useShoppingCart();
    const [currentStep, setCurrentStep] = useState<CartStep>(CartStep.ORDER);

    useEffect(() => {
        onStepChange(currentStep);
    }, [currentStep, onStepChange]);

    if (!productData || !appliedFeeData) {
        return null;
    }

    if (!productData.length) {
        return <EmptyCartDisplay />;
    }
    switch (currentStep) {
        case CartStep.ORDER:
            return (
                <ShoppingCartForm
                    productData={productData}
                    feeData={appliedFeeData}
                    closeCB={() => setCurrentStep(CartStep.ORDER_PROCESSED)}
                    onErrors={onClose}
                />
            );
        case CartStep.ORDER_PROCESSED:
            return createdOrder ? (
                <OrderProcessedDisplay
                    order={createdOrder}
                    closeCB={() =>
                        setCurrentStep(
                            createdOrder.paymentMethod ===
                                PaymentMethodEnum.CRED_CARD
                                ? CartStep.CHECKOUT
                                : CartStep.CLOSING
                        )
                    }
                />
            ) : (
                <></>
            );
        case CartStep.CHECKOUT:
            return (
                <Checkout closeCB={() => setCurrentStep(CartStep.CLOSING)} />
            );
        case CartStep.CLOSING:
            return (
                <SuccessDisplay email={user?.email ?? ""}>
                    <Button variant="filled" color="green" onClick={onClose}>
                        <FormattedMessage
                            id="app.label.close"
                            defaultMessage="Close"
                        />
                    </Button>
                </SuccessDisplay>
            );
        default:
            return null;
    }
};

export default ShoppingCart;

function EmptyCartDisplay() {
    return (
        <Stack className={classes.container} align="center">
            <Title c="red" order={3}>
                <FormattedMessage id="app.order.emptyCart" />
            </Title>
            <IconShoppingCartOff
                color="var(--mantine-color-gray-2)"
                size={300}
            />
        </Stack>
    );
}

interface OrderProcessedDisplayProps extends CartElementProps {
    order: Order;
}

const OrderProcessedDisplay = ({
    order,
    closeCB,
}: OrderProcessedDisplayProps) => {
    const notifAutoClose: number = parseInt(
        import.meta.env.VITE_NOTIFICATIONS_AUTOCLOSE_TIMEOUT
    );
    const [timeLeft, setTimeLeft] = useState(notifAutoClose / 1000);

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        } else {
            closeCB();
        }
    }, [timeLeft, closeCB]);

    return (
        <Stack
            className={`${globalClasses.container} ${classes.checkoutSuccess}`}
        >
            <Group>
                <IconCheck />{" "}
                <Title>
                    <FormattedMessage id="app.label.success" />
                </Title>
            </Group>
            <Text>
                <FormattedMessage
                    id="app.order.notification.status.success"
                    values={{ reference: order.reference }}
                />
            </Text>
            <Text>
                <FormattedMessage id="app.order.notification.status.success2" />{" "}
                {timeLeft}
            </Text>
        </Stack>
    );
};

interface SuccessDisplayProps {
    email: string;
}

const SuccessDisplay: FC<PropsWithChildren<SuccessDisplayProps>> = ({
    children,
    email,
}) => {
    return (
        <Stack
            className={`${globalClasses.container} ${classes.checkoutSuccess}`}
        >
            <IconSuccess isAnimated />
            <Text>
                <FormattedMessage
                    id="app.order.checkout.success"
                    defaultMessage="Payment successfully processed."
                />
                <br />
                <FormattedMessage
                    id="app.order.checkout.invoice.mail"
                    defaultMessage="An invoice has been sent to {email}."
                    values={{ email: email }}
                />
            </Text>
            {children}
        </Stack>
    );
};
