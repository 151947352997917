import { Grid, Text, Title } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { userGridProps } from "../Interfaces";
import classes from "../../../styles/global.module.css";

export default function UserReadOnlyGrid({ user }: userGridProps) {
    const intl = useIntl();

    return (
        <Grid className={classes.info}>
            {user.profile ? (
                <Grid.Col span={{ sm: 12, md: 9 }}>
                    <Grid grow>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.function"
                                    defaultMessage="Function"
                                />
                            </Title>
                            <Text>
                                {user.profile.job ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.lastname"
                                    defaultMessage="Lastname"
                                />
                            </Title>
                            <Text>
                                {user.profile.lastName ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.firstname"
                                    defaultMessage="Firstname"
                                />
                            </Title>
                            <Text>
                                {user.profile.firstName ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                                <Title order={5}>
                                    <FormattedMessage
                                        id="app.formLabel.currency"
                                        defaultMessage="Currency"
                                    />
                                </Title>
                                <Text>{user.profile.transactionsCurrencyCode}</Text>
                            </Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.birthdate"
                                    defaultMessage="Birthdate"
                                />
                            </Title>
                            <Text>
                                {user.profile?.birthDate
                                    ? new Date(
                                          user.profile?.birthDate
                                      ).toLocaleDateString()
                                    : intl.formatMessage({
                                          id: "app.field.undefined",
                                      })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={6}></Grid.Col>
                        <Grid.Col span={6}>
                            <Title order={3}>
                                <FormattedMessage
                                    id="app.user.phone"
                                    defaultMessage="Phone"
                                />
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={6}></Grid.Col>
                        <Grid.Col span={3}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.office"
                                    defaultMessage="Office"
                                />
                            </Title>
                            <Text>
                                {user.profile.officePhone ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.landline"
                                    defaultMessage="Landline"
                                />
                            </Title>
                            <Text>
                                {user.profile.landlinePhone ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Title order={5}>
                                <FormattedMessage
                                    id="app.user.mobile"
                                    defaultMessage="Mobile"
                                />
                            </Title>
                            <Text>
                                {user.profile.mobilePhone ??
                                    intl.formatMessage({
                                        id: "app.field.undefined",
                                    })}
                            </Text>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            ) : (
                <>
                    <Grid.Col span={12}>
                        <FormattedMessage
                            id="app.message.pleaseEditYourProfile"
                            defaultMessage="Please edit your profile"
                        />
                    </Grid.Col>
                </>
            )}
        </Grid>
    );
}
