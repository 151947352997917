export class HTTP {
    public static OK: number = 200;
    public static CREATED: number = 201;
    public static ACCEPTED: number = 202;
    public static NO_CONTENT: number = 204;
    public static BAD_REQUEST: number = 400;
    public static UNAUTHORIZED: number = 401;
    public static FORBIDDEN: number = 403;
    public static NOT_FOUND: number = 404;
    public static METHOD_NOT_ALLOWED: number = 405;
    public static CONFLICT: number = 409;
    public static INTERNAL_SERVER_ERROR: number = 500;
    public static SERVICE_UNAVAILABLE: number = 503;
    public static GATEWAY_TIMEOUT: number = 504;
}

export class AppErrorCode {
    public static EXISTING_SUBSCRIPTION: number = 3000;
}

export class ServerResponseType {
    public static SUCCESS: string = "success";
    public static ERROR: string = "error";
    public static WARNING: string = "warning";
}

export const NO_PARSE = "noParse";

export class CountryCode {
    public static CH: string = "CH";
}

export class CurrencyCode {
    public static CHF: string = "CHF";
    public static EUR: string = "EUR";
    public static USD: string = "USD";
}

export class ProductType {
    public static FEE: string = "fee";
    public static PHYSICAL: string = "physical";
    public static DIGITAL: string = "digital";
    public static ALL: string = "all";
}

export class DeliveryReference {
    public static DELIV_CH: string = "DELIV_CH";
    public static DELIV_EU: string = "DELIV_EU";
}

export class AppMode {
    public static DEV: string = "development";
    public static TEST: string = "test";
}
