import { Box, Text, Title } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { IconCircleCheck } from "@tabler/icons-react";
import { MessageBoxProps } from "./Interfaces";

export interface SuccessBoxProps {
    messageIntl?: string;
    messageInltValues?: Record<string, string>;
    message?: string | React.ReactNode;
}

export default function SuccessBox({
    messageIntl,
    messageInltValues,
    message,
}: MessageBoxProps) {
    return (
        <Box color="green" mb={10}>
            <Title>
                <FormattedMessage id="app.label.success" />
            </Title>
            <Text>
                {message ? (
                    <Text>{message}</Text>
                ) : (
                    <FormattedMessage
                        id={messageIntl}
                        values={messageInltValues}
                    />
                )}
            </Text>
            <IconCircleCheck color="green" size={48} />
        </Box>
    );
}
