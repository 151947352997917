import { staticImplements } from "../lib/decorators/Common";
import { DtoStatic, Model } from "./Common";
import { Expose, Transform, instanceToPlain, plainToClass } from "class-transformer";

@staticImplements<DtoStatic>()
export class ProductDto {
    @Expose()
    uuid!: string;
    @Expose()
    reference!: number;
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    price!: string;
    @Expose()
    currency!: string;
    @Expose()
    imageUrl!: string | null;
    @Expose()
    internalReference!: string;
    @Expose()
    neededBoxAmount!: string;

    static toModel(productDto: ProductDto): Product {
        if (productDto === undefined) {
            return new Product();
        }
        const data = instanceToPlain(productDto, {
            excludeExtraneousValues: true,
        });
        return plainToClass(Product, data);
    }
}

export class Product implements Model {
    @Expose()
    uuid!: string;
    @Expose()
    reference!: number;
    @Expose()
    name!: string;
    @Expose()
    description!: string;
    @Expose()
    @Transform((data) => parseFloat(data.value).toFixed(2))
    price!: number;
    @Expose()
    currency!: string;
    @Expose()
    imageUrl!: string;
    @Expose()
    internalReference!: string;
    @Expose()
    @Transform((data) => parseFloat(data.value))
    neededBoxAmount!: number;
}
