import {
    Group,
    Pagination,
    PaginationProps,
    SimpleGrid,
    SimpleGridProps,
} from "@mantine/core";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

type CombinedProps = SimpleGridProps & PaginationProps;

interface ITitleI18n {
    id: string;
    defaultMessage: string;
}

interface PageContainerProps {
    countLabel?: ITitleI18n;
    children: ReactNode;
    itemsCount: number;
    simpleGridProps: SimpleGridProps;
    paginationProps: PaginationProps;
}

const PaginationContainer: React.FC<PageContainerProps> = ({
    countLabel,
    children,
    itemsCount,
    simpleGridProps,
    paginationProps,
}) => {
    return (
        <>
            {countLabel && (
                <div>
                    <FormattedMessage
                        id={countLabel.id}
                        defaultMessage={countLabel.defaultMessage}
                        values={{ count: itemsCount }}
                    />
                </div>
            )}
            <SimpleGrid {...simpleGridProps}>{children}</SimpleGrid>
            {paginationProps.total > 1 && (
                <Pagination.Root {...paginationProps}>
                    <Group gap={5} justify="center">
                        <Pagination.First />
                        <Pagination.Previous />
                        <Pagination.Items />
                        <Pagination.Next />
                        <Pagination.Last />
                    </Group>
                </Pagination.Root>
            )}
        </>
    );
};

export default PaginationContainer;
