export enum AnimalType {
    Mouse = "mouse",
    Cat = "cat",
    GuineaPig = "guinea_pig",
    Other = "other",
}

export enum UrlParamType {
    ME = "me",
    SUBSCRIPTION = "subscription",
    TEAM = "team",
}
