import React, { createContext, useContext, useEffect, useState } from "react";
import { Country } from "../../models/Country";
import usePersistentData from "../hooks/usePersistentData";
import { APP_DATA_STORE } from "../../services/IndexedDB";
import { useRest } from "./RestContext";

interface IData {
    countries: Country[];
    currencies: string[];
}

interface IDataProps extends IData {
    setCurrentAppData: React.Dispatch<React.SetStateAction<IData>>;
    setDataPersist: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean,
}

export const AppDataContext = createContext<IDataProps>({
    countries: [],
    currencies: [],
    setCurrentAppData: () => {},
    setDataPersist: () => {},
    loading: false,
});

export const useAppData = () => {
    const data = useContext(AppDataContext);

    if (!data) {
        throw new Error("useAppData must be used within a AppDataProvider");
    }

    return data;
};

const defaultData: IData = { countries: [], currencies: [] };
const licenceStorageKeys: string[] = Object.keys(defaultData);

export const AppDataProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const { currentData, setCurrentData, dataPersist, setDataPersist } =
        usePersistentData(defaultData, licenceStorageKeys, APP_DATA_STORE);    
    
    const { restApiService } = useRest();

    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const [countries, currencies] = await Promise.all([
                restApiService?.getCountries(),
                restApiService?.getCurrencies()
            ]);

            if (!countries || !currencies) throw new Error("No data");

            setCurrentData((prevData) => ({
                ...prevData,
                countries: countries,
                currencies: currencies
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        
        fetchData();
        const interval = setInterval(fetchData, 3600000);

        return () => clearInterval(interval);
    }, []);
    
    const contextValue: IDataProps = {
        ...currentData,
        setCurrentAppData: setCurrentData,
        setDataPersist: setDataPersist,
        loading
    };

    return (
        <AppDataContext.Provider value={contextValue}>
            {children}
        </AppDataContext.Provider>
    );
};
